import Typography from "@mui/material/Typography/Typography"
import AsFeaturedInGrid from "./AsFeaturedInGrid"
import { colors } from "../Providers/ThemeRegistry/theme"
import { Button, Fade, Stack, alpha } from "@mui/material"
import Box from "@/components/Box"
import Link from "next/link"
import { ShaderGradientCanvas, ShaderGradient } from "shadergradient"
import * as reactSpring from "@react-spring/three"
import * as drei from "@react-three/drei"
import * as fiber from "@react-three/fiber"
import MaxWidthContainer from "./MaxWidthContainer"

const Background = () => (
  <ShaderGradientCanvas importedFiber={{ ...fiber, ...drei, ...reactSpring }}>
    <ShaderGradient
      control="query"
      urlString="https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=1.2&cAzimuthAngle=200&cDistance=3.7&cPolarAngle=30&cameraZoom=1&color1=%23ffffff&color2=%232332db&color3=%23e0e9ff&destination=onCanvas&embedMode=off&envPreset=lobby&format=gif&fov=40&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=2.4&positionX=0&positionY=0&positionZ=0&range=disabled&rangeEnd=40&rangeStart=0&reflection=0.1&rotationX=-50&rotationY=190&rotationZ=-290&shader=defaults&toggleAxis=false&type=waterPlane&uAmplitude=0&uDensity=1.9&uFrequency=5.5&uSpeed=0.2&uStrength=0.5&uTime=0&wireframe=false&zoomOut=false"
    />
  </ShaderGradientCanvas>
)

const AboveTheFold = () => {
  return (
    <MaxWidthContainer
      backgroundSx={{
        position: "relative",
      }}
      sx={{
        gap: "160px",
        paddingTop: "64px",
        paddingLeft: "16px",
        paddingRight: "16px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: -1,
          top: { xs: "10%", sm: "25%" },
          bottom: 0,
          left: 0,
          right: 0,
          animation: "fade-in 2.5s linear",
          "@keyframes fade-in": {
            "0%": {
              opacity: 0,
            },
            "50%": {
              opacity: 0,
            },
            "100%": {
              opacity: 1,
            },
          },
        }}
      >
        <Background />
        <Box
          sx={{
            background: "linear-gradient(180deg, #FFF 60%, rgba(255, 255, 255, 0.00) 100%);",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: "60%",
            right: 0,
          }}
        />
        <Box
          sx={{
            background: "linear-gradient(180deg, rgba(66, 0, 255, 0.00) 0%, rgba(0,0,0,0.4) 100%);",
            position: "absolute",
            top: { xs: "60%", sm: "60%" },
            left: 0,
            bottom: 0,
            right: 0,
          }}
        />
      </Box>
      <Stack justifyContent="center" alignItems="center" sx={{ p: 0, mt: "72px", maxWidth: "864px" }}>
        <Fade in timeout={2500} style={{ transitionDelay: "0ms" }}>
          <Typography variant="h1" textAlign="center">
            A New Era of Financial Inclusivity Onchain
          </Typography>
        </Fade>
        <Fade in timeout={1600} style={{ transitionDelay: "400ms" }}>
          <Typography
            variant="h6"
            textAlign="center"
            color={colors.base.greyMid2}
            sx={{
              fontSize: { xs: "16px", sm: "20px" },
              margin: { xs: "12px 0 32px", sm: "24px 0 32px" },
            }}
          >
            The Ondo Foundation’s mission is to usher in a new era of financial inclusivity and market efficiency
            through onchain institutional-grade financial products and services.
          </Typography>
        </Fade>
        <Fade in timeout={1600} style={{ transitionDelay: "800ms" }}>
          <Stack justifyContent="center" flexDirection="row" gap="16px" alignItems="center">
            <Button
              size="medium"
              target="_blank"
              variant="contained"
              href="https://blog.ondo.foundation"
              sx={{ whiteSpace: "nowrap" }}
            >
              Read the Blog
            </Button>

            <Button
              size="medium"
              variant="outlined"
              LinkComponent={Link}
              href="/about"
              sx={{ background: "none", whiteSpace: "nowrap", borderColor: alpha(colors.base.black, 0.3) }}
            >
              About Foundation
            </Button>
          </Stack>
        </Fade>
      </Stack>
      <Fade in timeout={1500} style={{ transitionDelay: "1250ms" }}>
        <Stack justifyContent="center" alignItems="center" p={0} width="100%" flexWrap="wrap" marginBottom="200px">
          <Typography
            variant="h6"
            sx={{ mb: { xs: 1, sm: 2 }, fontSize: { xs: "16px", sm: "20px" }, fontWeight: 500 }}
            color={colors.base.white}
          >
            As featured in:
          </Typography>
          <AsFeaturedInGrid />
        </Stack>
      </Fade>
    </MaxWidthContainer>
  )
}
export default AboveTheFold
