import { colors } from "@/components/Providers/ThemeRegistry/theme"
import { Discord, Forum, Telegram, Twitter } from "@/components/svgs/Svgs"
import { useMediaQuery, useTheme } from "@mui/material"
import Box from "@/components/Box"
import Grid from "@mui/material/Grid"
import { Size } from "@/components/svgs/types"

const SocialsGrid = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))
  const gridItemProps = {
    width: "auto" as Size,
    height: isMobile ? 40 : 64,
  }
  return (
    <Grid container>
      <SocialCard bgColor={colors.base.black} name="𝕏" href="https://twitter.com/ondofoundation">
        <Twitter color="white" {...gridItemProps} />
      </SocialCard>
      <SocialCard bgColor="#14AE5C" name="Forum" href="https://forum.fluxfinance.com/">
        <Forum color="white" {...gridItemProps} />
      </SocialCard>
      <SocialCard bgColor="#5865F2" name="Discord" href="https://discord.ondo.foundation">
        <Discord color="white" {...gridItemProps} />
      </SocialCard>
      <SocialCard bgColor="#229ED9" name="Telegram" href="https://t.me/ondocommunity">
        <Telegram color="white" {...gridItemProps} />
      </SocialCard>
    </Grid>
  )
}

const SocialCard: React.FC<{
  bgColor: string
  name: string
  href: string
  children: React.ReactNode
}> = ({ bgColor, name, href, children }) => (
  <Grid
    component="a"
    href={href}
    target="_blank"
    item
    xs={6}
    lg={3}
    sx={{ cursor: "pointer", backgroundColor: colors.base.white }}
  >
    <Box
      position="relative"
      bgcolor={bgColor}
      display="flex"
      justifyContent="center"
      minHeight={200}
      paddingTop="56.25%"
      sx={{
        transition: "all 0.1s ease 0s",
        "&:hover": {
          opacity: 0.8,
        },
      }}
    >
      <Box position="absolute" left="50%" top="50%" style={{ transform: "translate(-50%, -50%)" }}>
        {children}
      </Box>
      <Box position="absolute" top={0} left={0} padding="16px" color={colors.base.white}>
        {name}
      </Box>
    </Box>
  </Grid>
)
export default SocialsGrid
