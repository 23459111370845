import { SvgProps } from "./types"

export const Curve = ({ width = 175, height = 45 }: SvgProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 175 45" fill="none">
    <g clipPath="url(#clip0_208_73250)">
      <path d="M19.1801 27.3184L15.7501 28.0811L15.3301 26.4863L18.9701 25.793L19.1801 27.3184Z" fill="#0000FF" />
      <path d="M15.7504 28.0811L12.7404 29.0518L12.1104 27.3877L15.3304 26.4863L15.7504 28.0811Z" fill="#0000FF" />
      <path d="M18.9702 25.794L15.3302 26.4874L14.9102 24.6152L18.7602 24.0605L18.9702 25.794Z" fill="#0028FF" />
      <path d="M15.3298 26.4873L12.1098 27.3887L11.5498 25.4473L14.9098 24.6152L15.3298 26.4873Z" fill="#0018FF" />
      <path d="M19.39 28.4971L16.17 29.3291L15.75 28.0811L19.18 27.3184L19.39 28.4971Z" fill="#0000F1" />
      <path d="M16.1702 29.3301L13.4402 30.4395L12.7402 29.0528L15.7502 28.082L16.1702 29.3301Z" fill="#0000DA" />
      <path d="M18.7599 24.0596L14.9099 24.6143L14.6299 22.5342L18.6199 22.0488L18.7599 24.0596Z" fill="#0058FF" />
      <path d="M14.9102 24.6153L11.5502 25.4473L10.9902 23.2979L14.6302 22.5352L14.9102 24.6153Z" fill="#0048FF" />
      <path d="M22.9597 26.834L19.1797 27.3194L18.9697 25.794L22.9597 25.4473V26.834Z" fill="#0014FF" />
      <path d="M19.6699 29.3994L16.6599 30.3008L16.1699 29.3301L19.3899 28.498L19.6699 29.3994Z" fill="#0000DA" />
      <path d="M12.7402 29.0528L10.2902 30.3008L9.4502 28.6367L12.1102 27.3887L12.7402 29.0528Z" fill="#0000E8" />
      <path d="M16.6604 30.3008L14.1404 31.4102L13.4404 30.4395L16.1704 29.3301L16.6604 30.3008Z" fill="#0000C4" />
      <path d="M22.9598 25.4473L18.9698 25.794L18.7598 24.0606L22.8898 23.7832L22.9598 25.4473Z" fill="#003CFF" />
      <path d="M12.1097 27.3887L9.44969 28.6368L8.67969 26.626L11.5497 25.4473L12.1097 27.3887Z" fill="#0008FF" />
      <path d="M22.9597 27.9434L19.3897 28.4981L19.1797 27.3193L22.9597 26.834V27.9434Z" fill="#0000FF" />
      <path d="M13.44 30.4395L11.2 31.6876L10.29 30.3008L12.74 29.0527L13.44 30.4395Z" fill="#0000C8" />
      <path d="M18.6196 22.0499L14.6296 22.5352L14.3496 20.2471L18.4796 19.9004L18.6196 22.0499Z" fill="#0090FF" />
      <path d="M14.6303 22.5342L10.9903 23.2969L10.5703 20.9395L14.3503 20.2461L14.6303 22.5342Z" fill="#0084FF" />
      <path d="M22.8901 23.7823L18.7601 24.0596L18.6201 22.0488L22.8901 21.9102V23.7823Z" fill="#0068FF" />
      <path d="M11.5505 25.4463L8.68047 26.6251L7.98047 24.3369L10.9905 23.2969L11.5505 25.4463Z" fill="#003CFF" />
      <path d="M22.9596 28.7754L19.6696 29.3994L19.3896 28.4981L22.9596 27.9434V28.7754Z" fill="#0000F6" />
      <path d="M19.8802 29.9531L17.1502 30.9239L16.6602 30.2998L19.6702 29.3984L19.8802 29.9531Z" fill="#0000D1" />
      <path d="M14.1402 31.4102L12.1802 32.7276L11.2002 31.6875L13.4402 30.4395L14.1402 31.4102Z" fill="#0000AD" />
      <path d="M17.1496 30.9248L14.9096 32.0342L14.1396 31.4102L16.6596 30.3008L17.1496 30.9248Z" fill="#0000BB" />
      <path d="M22.8905 21.9112L18.6205 22.0499L18.4805 19.9004H22.8905V21.9112Z" fill="#009CFF" />
      <path d="M10.9899 23.2969L7.97992 24.337L7.41992 21.9102L10.5699 20.9395L10.9899 23.2969Z" fill="#0078FF" />
      <path d="M23.0299 29.2608L19.8799 29.9541L19.6699 29.3994L22.9599 28.7754L23.0299 29.2608Z" fill="#0000ED" />
      <path d="M18.4796 19.9004L14.3496 20.2471L14.1396 17.8897L18.4096 17.6816L18.4796 19.9004Z" fill="#00CCFF" />
      <path d="M26.88 26.5567L22.96 26.834V25.4473L27.02 25.3086L26.88 26.5567Z" fill="#002CFF" />
      <path d="M14.35 20.2461L10.57 20.9395L10.29 18.4434L14.14 17.8887L14.35 20.2461Z" fill="#00C4FF" />
      <path d="M26.67 27.5967L22.96 27.9434V26.834L26.88 26.5566L26.67 27.5967Z" fill="#0014FF" />
      <path d="M27.0196 25.2393L22.9596 25.378L22.8896 23.7832H27.1596L27.0196 25.2393Z" fill="#0050FF" />
      <path d="M10.2896 30.3008L8.39961 31.7569L7.34961 30.0235L9.44961 28.6367L10.2896 30.3008Z" fill="#0000DA" />
      <path d="M9.45043 28.6358L7.35043 30.0225L6.44043 28.0117L8.68043 26.625L9.45043 28.6358Z" fill="#0000FF" />
      <path d="M14.9097 32.0342L13.1597 33.3516L12.1797 32.7276L14.1397 31.4102L14.9097 32.0342Z" fill="#0000A4" />
      <path d="M11.2004 31.6875L9.59039 33.1436L8.40039 31.7569L10.2904 30.3008L11.2004 31.6875Z" fill="#0000B6" />
      <path d="M20.1604 30.1611L17.6404 31.1319L17.1504 30.9238L19.8804 29.9531L20.1604 30.1611Z" fill="#0000D6" />
      <path d="M26.53 28.3584L22.96 28.7744V27.9424L26.67 27.5957L26.53 28.3584Z" fill="#0004FF" />
      <path d="M27.1596 23.7823H22.8896V21.9102L27.2996 22.0488L27.1596 23.7823Z" fill="#0078FF" />
      <path d="M17.6402 31.1318L15.6102 32.2412L14.9102 32.0332L17.1502 30.9238L17.6402 31.1318Z" fill="#0000BF" />
      <path d="M22.8902 19.9004H18.4802L18.4102 17.6816L22.8902 17.8203V19.9004Z" fill="#00D8FF" />
      <path d="M8.67961 26.626L6.36961 28.0128L5.59961 25.7246L7.97961 24.3379L8.67961 26.626Z" fill="#0034FF" />
      <path d="M23.0299 29.3984L20.1599 30.1612L19.8799 29.9531L23.0299 29.2598V29.3984Z" fill="#0000F1" />
      <path d="M12.1798 32.7276L10.7798 34.1836L9.58984 33.1436L11.1998 31.6875L12.1798 32.7276Z" fill="#00009F" />
      <path d="M10.57 20.9395L7.42 21.9102L7 19.3447L10.29 18.4434L10.57 20.9395Z" fill="#00BCFF" />
      <path d="M26.32 28.7754L23.03 29.2608L22.96 28.7754L26.53 28.3594L26.32 28.7754Z" fill="#0000FF" />
      <path d="M18.41 17.6807L14.14 17.8887L14 15.4619L18.34 15.3926L18.41 17.6807Z" fill="#23FFD4" />
      <path d="M27.2996 22.0499L22.8896 21.9112V19.9004L27.4396 20.1777L27.2996 22.0499Z" fill="#00ACFF" />
      <path d="M14.1401 17.8897L10.2901 18.4444L10.0801 15.8789L14.0001 15.4629L14.1401 17.8897Z" fill="#1CFFDB" />
      <path d="M15.6102 32.2412L14.1402 33.5586L13.1602 33.3506L14.9102 32.0332L15.6102 32.2412Z" fill="#0000AD" />
      <path d="M7.98039 24.337L5.60039 25.7237L4.90039 23.2276L7.42039 21.9102L7.98039 24.337Z" fill="#0070FF" />
      <path d="M20.3696 30.0234L18.1296 30.9248L17.6396 31.1328L20.1596 30.1621L20.3696 30.0234Z" fill="#0000E8" />
      <path d="M13.1603 33.3506L11.9703 34.8067L10.7803 34.1826L12.1803 32.7266L13.1603 33.3506Z" fill="#000096" />
      <path d="M22.8898 17.8194L18.4098 17.6807L18.3398 15.3926L22.8898 15.6006V17.8194Z" fill="#26FFD1" />
      <path d="M26.1803 28.9141L23.0303 29.3994V29.2608L26.3203 28.7754L26.1803 28.9141Z" fill="#0000FF" />
      <path d="M30.7299 26.5567H26.8799L27.0199 25.3086L31.0099 25.3779L30.7299 26.5567Z" fill="#0044FF" />
      <path d="M30.3799 27.458L26.6699 27.5967L26.8799 26.5566H30.7299L30.3799 27.458Z" fill="#002CFF" />
      <path d="M18.1304 30.9238L16.3804 32.1026L15.6104 32.2412L17.6404 31.1318L18.1304 30.9238Z" fill="#0000D1" />
      <path d="M27.4396 20.1778L22.8896 19.9004V17.8203L27.5096 18.167L27.4396 20.1778Z" fill="#00E0FB" />
      <path d="M23.0302 29.2598L20.3702 30.0225L20.1602 30.1612L23.0302 29.3984V29.2598Z" fill="#0000FF" />
      <path d="M31.0095 25.378L27.0195 25.2393L27.1595 23.7832L31.3595 23.9912L31.0095 25.378Z" fill="#0064FF" />
      <path d="M10.2897 18.4444L6.99973 19.3458L6.71973 16.711L10.0797 15.8789L10.2897 18.4444Z" fill="#19FFDE" />
      <path d="M30.0303 28.1504L26.5303 28.3584L26.6703 27.5957L30.3803 27.457L30.0303 28.1504Z" fill="#001CFF" />
      <path d="M8.39988 31.7569L7.13988 33.421L5.87988 31.6182L7.34988 30.0234L8.39988 31.7569Z" fill="#0000D1" />
      <path d="M9.58965 33.1426L8.46965 34.8067L7.13965 33.42L8.39965 31.7559L9.58965 33.1426Z" fill="#0000A8" />
      <path d="M18.3397 15.3926L13.9997 15.462L13.9297 13.0352H18.2697L18.3397 15.3926Z" fill="#5AFF9D" />
      <path d="M31.3602 23.9903L27.1602 23.7823L27.3002 22.0488L31.5702 22.3955L31.3602 23.9903Z" fill="#008CFF" />
      <path d="M7.34977 30.0225L5.87977 31.6173L4.75977 29.6065L6.36977 28.0117L7.34977 30.0225Z" fill="#0000FF" />
      <path d="M7.41984 21.9112L4.89984 23.2286L4.33984 20.5938L6.99984 19.3457L7.41984 21.9112Z" fill="#00B8FF" />
      <path d="M13.9998 15.462L10.0798 15.9473L10.0098 13.3818L13.9298 13.0352L13.9998 15.462Z" fill="#5AFF9D" />
      <path d="M29.6803 28.4971L26.3203 28.7744L26.5303 28.3584L30.0303 28.1504L29.6803 28.4971Z" fill="#0018FF" />
      <path d="M16.3796 32.1035L15.1196 33.3516L14.1396 33.5596L15.6096 32.2422L16.3796 32.1035Z" fill="#0000BF" />
      <path d="M14.1397 33.5596L13.1597 35.0157L11.9697 34.8076L13.1597 33.3516L14.1397 33.5596Z" fill="#00009B" />
      <path d="M10.7797 34.1826L9.86973 35.7774L8.46973 34.8067L9.58973 33.1426L10.7797 34.1826Z" fill="#000092" />
      <path d="M25.9703 28.7051L23.0303 29.2598V29.3985L26.1803 28.9131L25.9703 28.7051Z" fill="#000CFF" />
      <path d="M6.37027 28.0127L4.76027 29.6075L3.78027 27.25L5.60027 25.7246L6.37027 28.0127Z" fill="#002CFF" />
      <path d="M22.8895 15.6006L18.3395 15.3926L18.2695 13.0352L22.9595 13.4512L22.8895 15.6006Z" fill="#5DFF9A" />
      <path d="M27.5096 18.167L22.8896 17.8204V15.6016L27.5796 16.1563L27.5096 18.167Z" fill="#2CFFCA" />
      <path d="M31.5698 22.3965L27.2998 22.0498L27.4398 20.1777L31.7798 20.6631L31.5698 22.3965Z" fill="#00B8FF" />
      <path d="M20.6499 29.4688L18.5499 30.3701L18.1299 30.9248L20.3699 30.0234L20.6499 29.4688Z" fill="#0000FF" />
      <path d="M23.1001 28.7051L20.6501 29.4678L20.3701 30.0225L23.0301 29.2598L23.1001 28.7051Z" fill="#000CFF" />
      <path d="M29.3297 28.5674L26.1797 28.9141L26.3197 28.7754L29.6797 28.498L29.3297 28.5674Z" fill="#0018FF" />
      <path d="M18.5499 30.3691L17.0099 31.4785L16.3799 32.1026L18.1299 30.9238L18.5499 30.3691Z" fill="#0000F1" />
      <path d="M10.0801 15.879L6.72008 16.711L6.58008 14.0762L10.0101 13.3828L10.0801 15.879Z" fill="#56FFA0" />
      <path d="M11.9701 34.8076L11.2701 36.4024L9.87012 35.7784L10.7801 34.1836L11.9701 34.8076Z" fill="#000089" />
      <path d="M5.59977 25.7247L3.77977 27.3194L3.00977 24.7539L4.89977 23.2285L5.59977 25.7247Z" fill="#006CFF" />
      <path d="M7.00023 19.3458L4.34023 20.5938L3.99023 17.8897L6.72023 16.7109L7.00023 19.3458Z" fill="#16FFE1" />
      <path d="M31.7804 20.6622L27.4404 20.1768L27.5104 18.166L31.9904 18.8594L31.7804 20.6622Z" fill="#06ECF1" />
      <path d="M18.2697 13.0352H13.9297V10.6777L18.3397 10.8164L18.2697 13.0352Z" fill="#94FF63" />
      <path d="M25.8303 28.1504L23.1003 28.7051L23.0303 29.2598L25.9703 28.7051L25.8303 28.1504Z" fill="#0024FF" />
      <path d="M15.1202 33.3516L14.3502 34.8076L13.1602 35.0157L14.1402 33.5596L15.1202 33.3516Z" fill="#0000AD" />
      <path d="M33.8799 27.5274L30.3799 27.458L30.7299 26.5566L34.3699 26.6953L33.8799 27.5274Z" fill="#0044FF" />
      <path d="M34.3705 26.6944L30.7305 26.5557L31.0105 25.377L34.7905 25.6543L34.3705 26.6944Z" fill="#005CFF" />
      <path d="M27.5796 16.1553L22.8896 15.6006L22.9596 13.4512L27.5796 14.1445V16.1553Z" fill="#5DFF9A" />
      <path d="M13.9298 13.0352L10.0098 13.3819V10.8857L13.9298 10.6777V13.0352Z" fill="#94FF63" />
      <path d="M29.0497 28.3594L25.9697 28.7061L26.1797 28.9141L29.3297 28.5674L29.0497 28.3594Z" fill="#0028FF" />
      <path d="M17.0101 31.4785L16.0301 32.7959L15.1201 33.3506L16.3801 32.1026L17.0101 31.4785Z" fill="#0000DF" />
      <path d="M33.3903 28.0811L30.0303 28.1504L30.3803 27.457L33.8803 27.5264L33.3903 28.0811Z" fill="#0038FF" />
      <path d="M34.7898 25.6543L31.0098 25.377L31.3598 23.9902L35.1398 24.4063L34.7898 25.6543Z" fill="#0078FF" />
      <path d="M22.9595 13.4512L18.2695 13.0352L18.3395 10.8164L22.9595 11.3018V13.4512Z" fill="#90FF66" />
      <path d="M13.1595 35.0147L12.6695 36.5401L11.2695 36.4014L11.9695 34.8066L13.1595 35.0147Z" fill="#00008D" />
      <path d="M20.8598 28.5664L18.9698 29.4678L18.5498 30.3692L20.6498 29.4678L20.8598 28.5664Z" fill="#001CFF" />
      <path d="M32.8997 28.3594L29.6797 28.4981L30.0297 28.1514L33.3897 28.082L32.8997 28.3594Z" fill="#0034FF" />
      <path d="M23.1004 27.873L20.8604 28.5664L20.6504 29.4678L23.1004 28.7051V27.873Z" fill="#0030FF" />
      <path d="M35.1404 24.4073L31.3604 23.9912L31.5704 22.3965L35.4904 22.9512L35.1404 24.4073Z" fill="#00A0FF" />
      <path d="M31.9898 18.8604L27.5098 18.167L27.5798 16.1562L32.0598 16.9883L31.9898 18.8604Z" fill="#33FFC4" />
      <path d="M4.89988 23.2286L3.00988 24.754L2.37988 22.1192L4.33988 20.5938L4.89988 23.2286Z" fill="#00B0FF" />
      <path d="M7.13973 33.42L6.36973 35.0841L4.96973 33.42L5.87973 31.6172L7.13973 33.42Z" fill="#0000C8" />
      <path d="M8.47012 34.8067L7.84012 36.4708L6.37012 35.084L7.14012 33.4199L8.47012 34.8067Z" fill="#0000A4" />
      <path d="M18.9698 29.4688L17.6398 30.5088L17.0098 31.4795L18.5498 30.3701L18.9698 29.4688Z" fill="#0008FF" />
      <path d="M5.87996 31.6182L4.96996 33.421L3.70996 31.3409L4.75996 29.6074L5.87996 31.6182Z" fill="#0000FF" />
      <path d="M10.0101 13.3829L6.58008 14.0762L6.65008 11.5108L10.0101 10.8867V13.3829Z" fill="#94FF63" />
      <path d="M32.4101 28.4287L29.3301 28.5674L29.6801 28.498L32.9001 28.3594L32.4101 28.4287Z" fill="#0034FF" />
      <path d="M28.7001 27.8047L25.8301 28.1514L25.9701 28.7061L29.0501 28.3594L28.7001 27.8047Z" fill="#003CFF" />
      <path d="M6.71961 16.711L3.98961 17.8897L3.84961 15.1856L6.57961 14.0762L6.71961 16.711Z" fill="#56FFA0" />
      <path d="M9.86984 35.7774L9.37984 37.4415L7.83984 36.4707L8.46984 34.8066L9.86984 35.7774Z" fill="#000089" />
      <path d="M25.6896 27.3184L23.0996 27.8731V28.7051L25.8296 28.1504L25.6896 27.3184Z" fill="#0044FF" />
      <path d="M35.4903 22.9502L31.5703 22.3955L31.7803 20.6621L35.8403 21.4248L35.4903 22.9502Z" fill="#00C8FF" />
      <path d="M27.58 14.1456L22.96 13.4522V11.3027L27.65 12.1348L27.58 14.1456Z" fill="#90FF66" />
      <path d="M18.3397 10.8174L13.9297 10.6788L14.0697 8.39062L18.4097 8.66797L18.3397 10.8174Z" fill="#CAFF2C" />
      <path d="M16.0296 32.7969L15.4696 34.1143L14.3496 34.8077L15.1196 33.3516L16.0296 32.7969Z" fill="#0000CD" />
      <path d="M4.76016 29.6065L3.71016 31.3399L2.66016 29.0518L3.78016 27.3184L4.76016 29.6065Z" fill="#0028FF" />
      <path d="M13.9298 10.6788L10.0098 10.8868L10.1498 8.5293L14.0698 8.39062L13.9298 10.6788Z" fill="#CEFF29" />
      <path d="M22.9598 11.3028L18.3398 10.8174V8.66797L22.9598 9.29201V11.3028Z" fill="#C7FF30" />
      <path d="M14.3499 34.8066L13.9999 36.2627L12.6699 36.5401L13.1599 35.0147L14.3499 34.8066Z" fill="#00009F" />
      <path d="M32.0601 16.9874L27.5801 16.1553V14.1445L32.1301 15.0459L32.0601 16.9874Z" fill="#60FF97" />
      <path d="M31.9898 28.1504L29.0498 28.3584L29.3298 28.5664L32.4098 28.4277L31.9898 28.1504Z" fill="#0040FF" />
      <path d="M17.6403 30.5078L16.8003 31.7559L16.0303 32.7959L17.0103 31.4785L17.6403 30.5078Z" fill="#0000FF" />
      <path d="M11.2699 36.4014L10.9899 37.9961L9.37988 37.4414L9.86988 35.7773L11.2699 36.4014Z" fill="#000080" />
      <path d="M4.33996 20.5928L2.37996 22.1183L1.95996 19.3448L3.98996 17.8887L4.33996 20.5928Z" fill="#16FFE1" />
      <path d="M35.8403 21.4249L31.7803 20.6621L31.9903 18.8594L36.0503 19.6914L35.8403 21.4249Z" fill="#0FF8E7" />
      <path d="M23.1004 26.6953L21.0004 27.3193L20.8604 28.5674L23.1004 27.874V26.6953Z" fill="#0058FF" />
      <path d="M20.9997 27.3184L19.3197 28.1504L18.9697 29.4678L20.8597 28.5664L20.9997 27.3184Z" fill="#0048FF" />
      <path d="M28.4204 27.041L25.6904 27.3184L25.8304 28.1504L28.7004 27.8037L28.4204 27.041Z" fill="#0058FF" />
      <path d="M3.78 27.2501L2.66 29.0528L1.75 26.5567L3.01 24.7539L3.78 27.2501Z" fill="#0068FF" />
      <path d="M36.8899 27.7354L33.8799 27.5274L34.3699 26.6953L37.4499 27.042L36.8899 27.7354Z" fill="#0060FF" />
      <path d="M36.3296 28.2207L33.3896 28.082L33.8796 27.5273L36.8896 27.7354L36.3296 28.2207Z" fill="#0054FF" />
      <path d="M37.4501 27.041L34.3701 26.6944L34.7901 25.6543L38.0101 26.0703L37.4501 27.041Z" fill="#0074FF" />
      <path d="M25.5496 26.209L23.0996 26.6943V27.8731L25.6896 27.3184L25.5496 26.209Z" fill="#006CFF" />
      <path d="M19.3196 28.1504L18.1996 29.1905L17.6396 30.5079L18.9696 29.4678L19.3196 28.1504Z" fill="#0038FF" />
      <path d="M31.5702 27.666L28.7002 27.8047L29.0502 28.3594L31.9902 28.1514L31.5702 27.666Z" fill="#0054FF" />
      <path d="M27.65 12.1339L22.96 11.3018V9.29102L27.58 10.2617L27.65 12.1339Z" fill="#C1FF36" />
      <path d="M12.6702 36.541L12.5302 38.0664L10.9902 37.9971L11.2702 36.4023L12.6702 36.541Z" fill="#000084" />
      <path d="M6.57961 14.0753L3.84961 15.1846L3.91961 12.4805L6.64961 11.5098L6.57961 14.0753Z" fill="#97FF60" />
      <path d="M35.7704 28.4287L32.9004 28.3594L33.3904 28.082L36.3304 28.2207L35.7704 28.4287Z" fill="#004CFF" />
      <path d="M10.0104 10.8868L6.65039 11.5108L6.86039 9.01466L10.1504 8.5293L10.0104 10.8868Z" fill="#D1FF26" />
      <path d="M38.01 26.0703L34.79 25.6543L35.14 24.4062L38.5 24.9609L38.01 26.0703Z" fill="#0090FF" />
      <path d="M36.0502 19.6924L31.9902 18.8604L32.0602 16.9883L36.1902 17.959L36.0502 19.6924Z" fill="#36FFC1" />
      <path d="M32.1301 15.0469L27.5801 14.1456L27.6501 12.1348L32.1301 13.2442V15.0469Z" fill="#8DFF6A" />
      <path d="M18.3403 8.66802L14.0703 8.39067L14.2103 6.31055L18.4803 6.65723L18.3403 8.66802Z" fill="#FFEA00" />
      <path d="M16.7997 31.7559L16.4497 33.0733L15.4697 34.1133L15.9597 32.7959L16.7997 31.7559Z" fill="#0000FF" />
      <path d="M35.2102 28.4277H32.4102H32.9002L35.7702 28.4971L35.2102 28.4277Z" fill="#0050FF" />
      <path d="M15.47 34.1133L15.33 35.5694L14 36.2627L14.35 34.8067L15.47 34.1133Z" fill="#0000C4" />
      <path d="M38.4996 24.962L35.1396 24.4073L35.4896 22.9512L38.9196 23.6445L38.4996 24.962Z" fill="#00B0FF" />
      <path d="M22.9598 9.29107L18.3398 8.66704L18.4798 6.65625L22.9598 7.41896V9.29107Z" fill="#FBF100" />
      <path d="M28.2098 25.9316L25.5498 26.209L25.6898 27.3184L28.4898 27.041L28.2098 25.9316Z" fill="#0080FF" />
      <path d="M14.0704 8.39067L10.1504 8.52935L10.4304 6.31055H14.2104L14.0704 8.39067Z" fill="#FFE200" />
      <path d="M3.99031 17.8897L1.96031 19.3458L1.82031 16.5723L3.85031 15.1855L3.99031 17.8897Z" fill="#53FFA4" />
      <path d="M3.01012 24.754L1.75012 26.5567L1.12012 23.8526L2.38012 22.1191L3.01012 24.754Z" fill="#00B0FF" />
      <path d="M18.1998 29.1914L17.5698 30.3701L16.7998 31.7569L17.6398 30.5088L18.1998 29.1914Z" fill="#002CFF" />
      <path d="M31.1499 26.9023L28.4199 27.041L28.6999 27.8037L31.5699 27.6651L31.1499 26.9023Z" fill="#0070FF" />
      <path d="M7.84016 36.4707L7.70016 38.1348L6.16016 36.7481L6.37016 35.084L7.84016 36.4707Z" fill="#00009F" />
      <path d="M6.36977 35.084L6.15977 36.7481L4.75977 35.084L4.96977 33.4199L6.36977 35.084Z" fill="#0000C8" />
      <path d="M23.17 25.2383L21.21 25.793L21 27.3184L23.1 26.6944L23.17 25.2383Z" fill="#008CFF" />
      <path d="M34.6502 28.1504H31.9902L32.4102 28.4277H35.2102L34.6502 28.1504Z" fill="#005CFF" />
      <path d="M21.2103 25.793L19.6703 26.5557L19.3203 28.1504L21.0003 27.3184L21.2103 25.793Z" fill="#007CFF" />
      <path d="M38.9202 23.6446L35.4902 22.9512L35.8402 21.4258L39.2702 22.2578L38.9202 23.6446Z" fill="#00D8FF" />
      <path d="M9.3802 37.4414L9.3102 39.0362L7.7002 38.1348L7.8402 36.4707L9.3802 37.4414Z" fill="#000089" />
      <path d="M14.0003 36.2637L14.0703 37.7891L12.5303 38.0664L12.6703 36.541L14.0003 36.2637Z" fill="#00009B" />
      <path d="M36.1896 17.959L32.0596 16.9883L32.1296 15.0469L36.2596 16.2256L36.1896 17.959Z" fill="#63FF94" />
      <path d="M4.96969 33.42L4.75969 35.0841L3.42969 33.0733L3.70969 31.3398L4.96969 33.42Z" fill="#0000FA" />
      <path d="M25.4796 24.8223L23.1696 25.2383L23.0996 26.6944L25.5496 26.209L25.4796 24.8223Z" fill="#009CFF" />
      <path d="M27.58 10.1934L22.96 9.29203V7.41992L27.58 8.45998V10.1934Z" fill="#F1FC06" />
      <path d="M32.1301 13.2442L27.6501 12.1348L27.5801 10.1934L32.0601 11.4414L32.1301 13.2442Z" fill="#BEFF39" />
      <path d="M19.6702 26.5566L18.6202 27.5274L18.2002 29.1915L19.3202 28.1514L19.6702 26.5566Z" fill="#0070FF" />
      <path d="M10.9896 37.9961V39.5215L9.30957 39.0362L9.37957 37.4414L10.9896 37.9961Z" fill="#000080" />
      <path d="M34.0903 27.666H31.5703L31.9903 28.1514H34.6503L34.0903 27.666Z" fill="#006CFF" />
      <path d="M6.64992 11.5098L3.91992 12.4805L4.12992 9.9844L6.85992 9.01367L6.64992 11.5098Z" fill="#D4FF23" />
      <path d="M39.2698 22.2569L35.8398 21.4248L36.0498 19.6914L39.4798 20.7315L39.2698 22.2569Z" fill="#19FFDE" />
      <path d="M10.1504 8.52935L6.86035 9.01471L7.14035 6.79591L10.4304 6.31055L10.1504 8.52935Z" fill="#FFDB00" />
      <path d="M3.70957 31.3409L3.42957 33.0743L2.30957 30.7862L2.65957 29.0527L3.70957 31.3409Z" fill="#0024FF" />
      <path d="M30.87 25.8633L28.21 25.9326L28.42 27.042L31.15 26.9033L30.87 25.8633Z" fill="#0094FF" />
      <path d="M28.0005 24.6152L25.4805 24.8232L25.5505 26.21L28.2105 25.9326L28.0005 24.6152Z" fill="#00ACFF" />
      <path d="M18.48 6.6563L14.21 6.30961L14.49 4.4375L18.62 4.92286L18.48 6.6563Z" fill="#FFB200" />
      <path d="M2.37988 22.1192L1.11988 23.8526L0.629883 21.0791L1.95988 19.3457L2.37988 22.1192Z" fill="#13FCE4" />
      <path d="M16.4501 33.0742V34.461L15.3301 35.5704L15.4701 34.1143L16.4501 33.0742Z" fill="#0000F1" />
      <path d="M22.9605 7.41802L18.4805 6.65531L18.6205 4.92188L23.0305 5.75392L22.9605 7.41802Z" fill="#FFBD00" />
      <path d="M17.5702 30.3691L17.3602 31.6172L16.4502 33.0733L16.8002 31.7559L17.5702 30.3691Z" fill="#0020FF" />
      <path d="M36.2599 16.2257L32.1299 15.0469V13.2441L36.2599 14.4922V16.2257Z" fill="#8DFF6A" />
      <path d="M38.7101 28.4971L36.3301 28.2197L36.8901 27.7344L39.3401 28.0811L38.7101 28.4971Z" fill="#006CFF" />
      <path d="M39.3396 28.0811L36.8896 27.7344L37.4496 27.041L39.9696 27.457L39.3396 28.0811Z" fill="#0078FF" />
      <path d="M3.85031 15.1846L1.82031 16.5714L1.89031 13.8672L3.92031 12.4805L3.85031 15.1846Z" fill="#97FF60" />
      <path d="M38.0795 28.6367L35.7695 28.4287L36.3295 28.2207L38.7095 28.4981L38.0795 28.6367Z" fill="#0068FF" />
      <path d="M39.9702 27.4571L37.4502 27.041L38.0102 26.0703L40.5302 26.625L39.9702 27.4571Z" fill="#008CFF" />
      <path d="M14.2097 6.30961H10.4297L10.7797 4.4375H14.4897L14.2097 6.30961Z" fill="#FFA700" />
      <path d="M23.17 23.5059L21.28 23.9912L21.21 25.794L23.17 25.2393V23.5059Z" fill="#00C4FF" />
      <path d="M12.5299 38.0654L12.5999 39.6602L10.9199 39.5215V37.9961L12.5299 38.0654Z" fill="#000084" />
      <path d="M15.33 35.5703L15.47 37.0264L14.07 37.7891L14 36.2637L15.33 35.5703Z" fill="#0000BB" />
      <path d="M33.6704 26.9023H31.1504L31.5704 27.6651H34.0904L33.6704 26.9023Z" fill="#0084FF" />
      <path d="M18.6203 27.5273L18.1303 28.6367L17.5703 30.3702L18.2003 29.1914L18.6203 27.5273Z" fill="#0068FF" />
      <path d="M39.5498 20.7325L36.0498 19.6924L36.1898 17.959L39.6898 19.1377L39.5498 20.7325Z" fill="#3CFFBA" />
      <path d="M25.4099 23.2285L23.1699 23.5059V25.2393L25.4799 24.8233L25.4099 23.2285Z" fill="#00D0FF" />
      <path d="M2.66039 29.0528L2.31039 30.7862L1.40039 28.2207L1.75039 26.5566L2.66039 29.0528Z" fill="#0064FF" />
      <path d="M37.38 28.5664L35.21 28.4277H35.77L38.08 28.6357L37.38 28.5664Z" fill="#0068FF" />
      <path d="M21.2799 23.9902L19.8799 24.6836L19.6699 26.5557L21.2099 25.793L21.2799 23.9902Z" fill="#00B8FF" />
      <path d="M40.5298 26.625L38.0098 26.0703L38.4998 24.9609L41.0898 25.6543L40.5298 26.625Z" fill="#00A4FF" />
      <path d="M32.0601 11.4405L27.5801 10.1924V8.45898L31.9901 9.7764L32.0601 11.4405Z" fill="#EBFF0C" />
      <path d="M27.58 8.46002L22.96 7.41996L23.03 5.75586L27.51 6.9346L27.58 8.46002Z" fill="#FFC800" />
      <path d="M30.59 24.6152H28L28.21 25.9326L30.87 25.8633L30.59 24.6152Z" fill="#00BCFF" />
      <path d="M36.8204 28.2891L34.6504 28.1504L35.2104 28.4277L37.3804 28.5664L36.8204 28.2891Z" fill="#0074FF" />
      <path d="M41.09 25.6553L38.5 24.9619L38.92 23.6445L41.51 24.4766L41.09 25.6553Z" fill="#00C4FF" />
      <path d="M27.8602 23.0898L25.4102 23.2285L25.4802 24.8233L28.0002 24.6153L27.8602 23.0898Z" fill="#00DCFE" />
      <path d="M19.8801 24.6836L18.9701 25.5156L18.6201 27.5264L19.6701 26.5557L19.8801 24.6836Z" fill="#00B0FF" />
      <path d="M36.2596 14.4923L32.1296 13.2442L32.0596 11.4414L36.1896 12.8975L36.2596 14.4923Z" fill="#B7FF40" />
      <path d="M33.2501 25.9326L30.8701 25.8633L31.1501 26.834L33.6701 26.9033L33.2501 25.9326Z" fill="#00A4FF" />
      <path d="M39.6904 19.1368L36.1904 17.958L36.2604 16.2246L39.7604 17.542L39.6904 19.1368Z" fill="#63FF94" />
      <path d="M14.0703 37.7891L14.1403 39.2451L12.6003 39.6612L12.5303 38.0664L14.0703 37.7891Z" fill="#000096" />
      <path d="M1.95992 19.3458L0.629922 21.0792L0.419922 18.2364L1.81992 16.5723L1.95992 19.3458Z" fill="#53FFA4" />
      <path d="M6.85988 9.01372L4.12988 9.98444L4.54988 7.62697L7.20988 6.79492L6.85988 9.01372Z" fill="#FFD700" />
      <path d="M18.6202 4.9229L14.4902 4.43753L14.7702 2.91211L18.7602 3.39747L18.6202 4.9229Z" fill="#FF7A00" />
      <path d="M10.4296 6.30864L7.13965 6.794L7.69965 4.78321L10.7796 4.36719L10.4296 6.30864Z" fill="#FF9C00" />
      <path d="M23.0301 5.75591L18.6201 4.92386L18.7601 3.39844L23.0301 4.29982V5.75591Z" fill="#FF8900" />
      <path d="M36.1898 27.8047L34.0898 27.666L34.6498 28.1514L36.8198 28.2901L36.1898 27.8047Z" fill="#0084FF" />
      <path d="M7.70016 38.1348L7.91016 39.5909L6.37016 38.2735L6.16016 36.748L7.70016 38.1348Z" fill="#00009F" />
      <path d="M41.5099 24.4766L38.9199 23.6446L39.2699 22.2578L41.9299 23.2285L41.5099 24.4766Z" fill="#00E4F8" />
      <path d="M6.15977 36.7481L6.36977 38.2735L4.89977 36.6094L4.75977 35.084L6.15977 36.7481Z" fill="#0000C8" />
      <path d="M17.3602 31.6172L17.5002 32.9346L16.5202 34.46L16.4502 33.0733L17.3602 31.6172Z" fill="#0018FF" />
      <path d="M1.7502 26.5557L1.4002 28.2198L0.700195 25.5157L1.1202 23.8516L1.7502 26.5557Z" fill="#00ACFF" />
      <path d="M9.3102 39.0362L9.5202 40.5616L7.9102 39.5909L7.7002 38.1348L9.3102 39.0362Z" fill="#000089" />
      <path d="M18.1304 28.6367V29.8155L17.3604 31.6182L17.5704 30.3702L18.1304 28.6367Z" fill="#005CFF" />
      <path d="M23.1703 21.5645L21.4203 21.9805L21.2803 23.9913L23.1703 23.5059V21.5645Z" fill="#16FFE1" />
      <path d="M16.5201 34.4609L16.8001 35.8477L15.4701 37.0264L15.3301 35.5703L16.5201 34.4609Z" fill="#0000ED" />
      <path d="M31.9898 9.77643L27.5798 8.45902L27.5098 6.93359L31.8498 8.32034L31.9898 9.77643Z" fill="#FFD700" />
      <path d="M25.4099 21.4258L23.1699 21.5645V23.5059L25.4099 23.2286V21.4258Z" fill="#1CFFDB" />
      <path d="M3.91965 12.4805L1.88965 13.8673L2.09965 11.2324L4.12965 9.98438L3.91965 12.4805Z" fill="#D7FF1F" />
      <path d="M4.75969 35.085L4.89969 36.6104L3.63969 34.5303L3.42969 33.0742L4.75969 35.085Z" fill="#0000FA" />
      <path d="M30.3804 23.1592L27.8604 23.0898L28.0004 24.6153H30.5904L30.3804 23.1592Z" fill="#02E8F4" />
      <path d="M14.4903 4.43754L10.7803 4.3682L11.2703 2.77344L14.7703 2.91211L14.4903 4.43754Z" fill="#FF6F00" />
      <path d="M27.5103 6.93365L23.0303 5.75491V4.29883L27.3703 5.5469L27.5103 6.93365Z" fill="#FF9800" />
      <path d="M21.4199 21.9805L20.0899 22.5352L19.8799 24.6846L21.2799 23.9913L21.4199 21.9805Z" fill="#13FCE4" />
      <path d="M35.6999 27.041L33.6699 26.9023L34.0899 27.6651L36.1899 27.8037L35.6999 27.041Z" fill="#009CFF" />
      <path d="M18.9699 25.5156L18.6199 26.5557L18.1299 28.6358L18.6199 27.5264L18.9699 25.5156Z" fill="#00A8FF" />
      <path d="M32.8998 24.7539L30.5898 24.6152L30.8698 25.8633L33.2498 25.9326L32.8998 24.7539Z" fill="#00CCFF" />
      <path d="M41.9295 23.2286L39.2695 22.2578L39.5495 20.7324L42.2095 21.8418L41.9295 23.2286Z" fill="#1FFFD7" />
      <path d="M10.9896 39.5225L11.1296 41.0479L9.51957 40.5625L9.30957 39.0371L10.9896 39.5225Z" fill="#000080" />
      <path d="M39.7598 17.543L36.2598 16.2256V14.4922L39.7598 15.9483V17.543Z" fill="#8DFF6A" />
      <path d="M36.1902 12.8975L32.0602 11.4414L31.9902 9.77734L36.0502 11.3028L36.1902 12.8975Z" fill="#E1FF16" />
      <path d="M27.7902 21.4258H25.4102V23.2286L27.8602 23.0899L27.7902 21.4258Z" fill="#23FFD4" />
      <path d="M3.42957 33.0733L3.63957 34.5294L2.51957 32.2412L2.30957 30.7852L3.42957 33.0733Z" fill="#0024FF" />
      <path d="M40.32 28.8447L38.71 28.4981L39.34 28.082L40.95 28.4981L40.32 28.8447Z" fill="#0084FF" />
      <path d="M15.4703 37.0254L15.6803 38.4815L14.1403 39.2442L14.0703 37.7881L15.4703 37.0254Z" fill="#0000BB" />
      <path d="M39.6201 28.9141L38.0801 28.6367L38.7101 28.498L40.3201 28.8447L39.6201 28.9141Z" fill="#0080FF" />
      <path d="M40.9498 28.4971L39.3398 28.0811L39.9698 27.457L41.6498 28.0117L40.9498 28.4971Z" fill="#0090FF" />
      <path d="M38.9199 28.8438L37.3799 28.5664L38.0799 28.6357L39.6199 28.9131L38.9199 28.8438Z" fill="#0084FF" />
      <path d="M41.6497 27.9424L39.9697 27.457L40.5297 26.625L42.2097 27.249L41.6497 27.9424Z" fill="#00A0FF" />
      <path d="M20.0897 22.5352L19.2497 23.2979L18.9697 25.5167L19.8797 24.6846L20.0897 22.5352Z" fill="#0CF4EB" />
      <path d="M1.11996 23.8516L0.699961 25.5157L0.209961 22.7422L0.629961 21.0781L1.11996 23.8516Z" fill="#13FCE4" />
      <path d="M35.28 26.1397L33.25 25.9316L33.67 26.9024L35.7 27.041L35.28 26.1397Z" fill="#00B8FF" />
      <path d="M23.0298 4.29985L18.7598 3.39846L18.9698 2.28906L23.0298 3.19045V4.29985Z" fill="#FF6000" />
      <path d="M42.2098 21.8409L39.5498 20.7315L39.6898 19.1367L42.4198 20.3848L42.2098 21.8409Z" fill="#43FFB4" />
      <path d="M1.81992 16.5713L0.419922 18.2354L0.489922 15.4619L1.88992 13.8672L1.81992 16.5713Z" fill="#97FF60" />
      <path d="M12.6002 39.6602L12.7402 41.1162L11.1302 41.0469L10.9902 39.5215L12.6002 39.6602Z" fill="#000084" />
      <path d="M18.7595 3.3975L14.7695 2.91214L15.1895 1.66406L18.9695 2.2881L18.7595 3.3975Z" fill="#FF4E00" />
      <path d="M31.8501 8.32037L27.5101 6.93362L27.3701 5.54688L31.6401 7.0723L31.8501 8.32037Z" fill="#FFAB00" />
      <path d="M38.2903 28.5664L36.8203 28.2891L37.3803 28.5664L38.9203 28.8438L38.2903 28.5664Z" fill="#008CFF" />
      <path d="M30.24 21.6338L27.79 21.4258L27.86 23.0899L30.38 23.1592L30.24 21.6338Z" fill="#2CFFCA" />
      <path d="M42.2103 27.2491L40.5303 26.625L41.0903 25.6543L42.7703 26.417L42.2103 27.2491Z" fill="#00B8FF" />
      <path d="M7.1398 6.79594L4.5498 7.62799L5.1798 5.54787L7.6298 4.78516L7.1398 6.79594Z" fill="#FF9400" />
      <path d="M10.7802 4.3682L7.7002 4.78422L8.3302 3.05079L11.2702 2.77344L10.7802 4.3682Z" fill="#FF6400" />
      <path d="M32.6899 23.4356L30.3799 23.1582L30.5899 24.6143L32.8999 24.753L32.6899 23.4356Z" fill="#0CF4EB" />
      <path d="M25.3399 19.4141L23.2399 19.4834L23.1699 21.5635L25.4099 21.4248L25.3399 19.4141Z" fill="#50FFA7" />
      <path d="M23.2399 19.4844L21.4199 19.7617V21.9805L23.1699 21.5645L23.2399 19.4844Z" fill="#4DFFAA" />
      <path d="M39.7604 15.9473L36.2604 14.4912L36.1904 12.8965L39.6904 14.4219L39.7604 15.9473Z" fill="#B4FF43" />
      <path d="M18.1304 29.8145L18.4104 31.0625L17.5004 32.9346L17.3604 31.6172L18.1304 29.8145Z" fill="#0058FF" />
      <path d="M27.3703 5.54693L23.0303 4.29885V3.18945L27.2303 4.50686L27.3703 5.54693Z" fill="#FF6F00" />
      <path d="M2.31039 30.7862L2.52039 32.2423L1.61039 29.7461L1.40039 28.2207L2.31039 30.7862Z" fill="#0068FF" />
      <path d="M36.0496 11.3018L31.9896 9.7764L31.8496 8.32031L35.8396 9.91507L36.0496 11.3018Z" fill="#FFE200" />
      <path d="M17.4995 32.9355L17.9195 34.253L16.7995 35.8477L16.5195 34.461L17.4995 32.9355Z" fill="#0014FF" />
      <path d="M18.6199 26.5566L18.6899 27.666L18.1299 29.8155V28.6368L18.6199 26.5566Z" fill="#00A0FF" />
      <path d="M37.6604 28.0127L36.1904 27.8047L36.8204 28.29L38.2904 28.5674L37.6604 28.0127Z" fill="#009CFF" />
      <path d="M27.7198 19.6221L25.3398 19.4141L25.4098 21.4248H27.7898L27.7198 19.6221Z" fill="#53FFA4" />
      <path d="M42.7698 26.418L41.0898 25.6553L41.5098 24.4766L43.2598 25.3779L42.7698 26.418Z" fill="#00D4FF" />
      <path d="M14.7695 2.91312L11.2695 2.77444L11.8295 1.45703L15.1895 1.66504L14.7695 2.91312Z" fill="#FF3F00" />
      <path d="M4.12961 9.98443L2.09961 11.2325L2.65961 8.80569L4.54961 7.62695L4.12961 9.98443Z" fill="#FFD300" />
      <path d="M21.4198 19.7617L20.1598 20.1777L20.0898 22.5352L21.4198 21.9805V19.7617Z" fill="#49FFAD" />
      <path d="M34.8604 25.0313L32.9004 24.7539L33.2504 25.9326L35.2804 26.1407L34.8604 25.0313Z" fill="#00DCFE" />
      <path d="M42.4204 20.3858L39.6904 19.1377L39.8304 17.543L42.5604 18.9297L42.4204 20.3858Z" fill="#66FF90" />
      <path d="M19.2501 23.2969L18.9701 24.1983L18.6201 26.5557L18.9701 25.5157L19.2501 23.2969Z" fill="#09F0EE" />
      <path d="M14.1396 39.2441L14.3496 40.7696L12.7396 41.1163L12.5996 39.6602L14.1396 39.2441Z" fill="#00009B" />
      <path d="M16.7997 35.8477L17.0097 37.3037L15.6797 38.4825L15.4697 37.0264L16.7997 35.8477Z" fill="#0000E8" />
      <path d="M37.1002 27.3184L35.7002 27.041L36.1902 27.8037L37.6602 28.0117L37.1002 27.3184Z" fill="#00B0FF" />
      <path d="M43.2598 25.378L41.5098 24.4766L41.9298 23.2285L43.6798 24.1992L43.2598 25.378Z" fill="#0CF4EB" />
      <path d="M32.5502 21.9795L30.2402 21.6328L30.3802 23.1582L32.6902 23.4356L32.5502 21.9795Z" fill="#33FFC4" />
      <path d="M30.1697 19.9004L27.7197 19.623L27.7897 21.4258L30.2397 21.6338L30.1697 19.9004Z" fill="#56FFA0" />
      <path d="M23.0297 3.19047L18.9697 2.28908L19.1797 1.45703L23.0997 2.42776L23.0297 3.19047Z" fill="#FF3B00" />
      <path d="M7.91012 39.5908L8.19012 40.9083L6.65012 39.5908L6.37012 38.2734L7.91012 39.5908Z" fill="#0000A8" />
      <path d="M39.6898 14.4229L36.1898 12.8975L36.0498 11.3027L39.4798 12.9668L39.6898 14.4229Z" fill="#D7FF1F" />
      <path d="M31.6405 7.0733L27.3705 5.54787L27.2305 4.50781L31.3605 6.03324L31.6405 7.0733Z" fill="#FF8600" />
      <path d="M6.37039 38.2745L6.65039 39.5919L5.25039 37.9278L4.90039 36.541L6.37039 38.2745Z" fill="#0000CD" />
      <path d="M0.63 21.0792L0.21 22.7433L0 19.9698L0.42 18.2363L0.63 21.0792Z" fill="#53FFA4" />
      <path d="M20.16 20.1777L19.39 20.8711L19.25 23.2979L20.09 22.5352L20.16 20.1777Z" fill="#46FFB1" />
      <path d="M18.9704 2.28812L15.1904 1.66408L15.6104 0.832031L19.1804 1.45607L18.9704 2.28812Z" fill="#FF2900" />
      <path d="M1.4002 28.2198L1.6102 29.7452L0.910195 27.041L0.700195 25.5156L1.4002 28.2198Z" fill="#00B0FF" />
      <path d="M25.4102 17.4043L23.2402 17.2656V19.4844L25.3402 19.4151L25.4102 17.4043Z" fill="#80FF77" />
      <path d="M9.52016 40.5625L9.80016 41.8799L8.19016 40.9092L7.91016 39.5918L9.52016 40.5625Z" fill="#000092" />
      <path d="M35.8396 9.9151L31.8496 8.32034L31.6396 7.07227L35.4896 8.73636L35.8396 9.9151Z" fill="#FFB900" />
      <path d="M23.2399 17.2656L21.4199 17.4043V19.7618L23.2399 19.4844V17.2656Z" fill="#80FF77" />
      <path d="M34.5804 23.7822L32.6904 23.4355L32.9004 24.753L34.8604 25.0303L34.5804 23.7822Z" fill="#16FFE1" />
      <path d="M27.2303 4.5059L23.0303 3.18849L23.1003 2.42578L27.0903 3.74319L27.2303 4.5059Z" fill="#FF4E00" />
      <path d="M42.4898 18.9288L39.7598 17.542V15.9473L42.4898 17.4727V18.9288Z" fill="#8AFF6D" />
      <path d="M1.89023 13.8672L0.490234 15.5313L0.770234 12.8272L2.10023 11.2324L1.89023 13.8672Z" fill="#D7FF1F" />
      <path d="M4.89965 36.5401L5.24965 37.9268L3.98965 35.9854L3.63965 34.5293L4.89965 36.5401Z" fill="#0000FF" />
      <path d="M36.6803 26.4873L35.2803 26.1406L35.7003 27.042L37.1003 27.3194L36.6803 26.4873Z" fill="#00C8FF" />
      <path d="M27.7898 17.6816L25.4098 17.4043L25.3398 19.4151L27.7198 19.5538L27.7898 17.6816Z" fill="#80FF77" />
      <path d="M43.6797 24.1993L41.9297 23.1592L42.2097 21.8418L44.0297 22.9512L43.6797 24.1993Z" fill="#29FFCE" />
      <path d="M11.2701 2.77444L8.33008 3.05179L9.03008 1.73438L11.8301 1.45703L11.2701 2.77444Z" fill="#FF3000" />
      <path d="M7.69969 4.78422L5.17969 5.54693L5.94969 3.74416L8.32969 3.05078L7.69969 4.78422Z" fill="#FF5900" />
      <path d="M40.3201 29.3291L39.6201 28.9131L40.3201 28.8438L41.0201 29.2598L40.3201 29.3291Z" fill="#0098FF" />
      <path d="M41.0203 29.2608L40.3203 28.8447L40.9503 28.498L41.7203 28.9834L41.0203 29.2608Z" fill="#009CFF" />
      <path d="M11.1295 41.0479L11.4095 42.3653L9.79953 41.8799L9.51953 40.5625L11.1295 41.0479Z" fill="#000084" />
      <path d="M39.6199 29.1904L38.9199 28.8438L39.6199 28.9131L40.3199 29.3291L39.6199 29.1904Z" fill="#0098FF" />
      <path d="M21.4202 17.4043L20.1602 17.751V20.1778L21.4202 19.7618V17.4043Z" fill="#80FF77" />
      <path d="M41.7202 28.9824L40.9502 28.4971L41.6502 28.0117L42.3502 28.5664L41.7202 28.9824Z" fill="#00A4FF" />
      <path d="M18.6899 27.666L19.0399 28.8448L18.4099 31.0636L18.1299 29.8155L18.6899 27.666Z" fill="#009CFF" />
      <path d="M15.6796 38.4824L15.8196 40.0078L14.3496 40.7706L14.1396 39.2451L15.6796 38.4824Z" fill="#0000BB" />
      <path d="M18.41 31.0625L18.9 32.3106L17.92 34.252L17.5 32.9346L18.41 31.0625Z" fill="#0054FF" />
      <path d="M15.1901 1.66409L11.8301 1.45607L12.4601 0.554688L15.6101 0.832037L15.1901 1.66409Z" fill="#FF1600" />
      <path d="M32.4799 20.3858L30.1699 19.9004L30.2399 21.6338L32.5499 21.9805L32.4799 20.3858Z" fill="#5AFF9D" />
      <path d="M38.99 28.9131L38.29 28.5664L38.92 28.8438L39.62 29.1904L38.99 28.9131Z" fill="#00A0FF" />
      <path d="M3.63953 34.5303L3.98953 35.9864L2.86953 33.6983L2.51953 32.2422L3.63953 34.5303Z" fill="#0028FF" />
      <path d="M18.9701 24.1992L19.1101 25.2393L18.6901 27.6661L18.6201 26.5567L18.9701 24.1992Z" fill="#06ECF1" />
      <path d="M39.4798 12.9669L36.0498 11.3028L35.8398 9.91602L39.1998 11.7188L39.4798 12.9669Z" fill="#FBF100" />
      <path d="M30.2397 18.167L27.7897 17.6816L27.7197 19.6231L30.1697 19.9004L30.2397 18.167Z" fill="#80FF77" />
      <path d="M42.3504 28.5674L41.6504 27.9434L42.2104 27.25L42.9804 27.9434L42.3504 28.5674Z" fill="#00B4FF" />
      <path d="M36.2604 25.4473L34.8604 25.0312L35.2804 26.1406L36.6804 26.4873L36.2604 25.4473Z" fill="#02E8F4" />
      <path d="M31.3598 6.03228L27.2298 4.50685L27.0898 3.74414L31.0798 5.3389L31.3598 6.03228Z" fill="#FF6400" />
      <path d="M4.55016 7.627L2.66016 8.80573L3.36016 6.65627L5.18016 5.54688L4.55016 7.627Z" fill="#FF9100" />
      <path d="M44.03 22.9503L42.21 21.8409L42.42 20.3848L44.24 21.6328L44.03 22.9503Z" fill="#46FFB1" />
      <path d="M34.4398 22.4658L32.5498 21.9805L32.6898 23.4366L34.5798 23.7832L34.4398 22.4658Z" fill="#39FFBE" />
      <path d="M23.0997 2.42679L19.1797 1.45607L19.3897 0.970703L23.0997 2.01076V2.42679Z" fill="#FF2200" />
      <path d="M42.4904 17.4727L39.7604 15.9473L39.6904 14.4219L42.4204 16.0166L42.4904 17.4727Z" fill="#ADFF49" />
      <path d="M19.3897 20.8711L19.1097 21.7031L18.9697 24.1993L19.2497 23.2979L19.3897 20.8711Z" fill="#43FFB4" />
      <path d="M38.3602 28.3584L37.6602 28.0117L38.2902 28.5664L38.9902 28.9131L38.3602 28.3584Z" fill="#00B0FF" />
      <path d="M35.4904 8.73736L31.6404 7.07326L31.3604 6.0332L35.1404 7.83598L35.4904 8.73736Z" fill="#FF9800" />
      <path d="M17.9198 34.252L18.1998 35.6387L17.0098 37.3028L16.7998 35.8467L17.9198 34.252Z" fill="#0014FF" />
      <path d="M25.4102 15.3242L23.2402 15.0469V17.2657L25.4102 17.4043V15.3242Z" fill="#B4FF43" />
      <path d="M42.98 27.9434L42.21 27.25L42.77 26.418L43.54 27.1807L42.98 27.9434Z" fill="#00C8FF" />
      <path d="M12.7399 41.1162L13.0199 42.4336L11.4099 42.3643L11.1299 41.0469L12.7399 41.1162Z" fill="#00008D" />
      <path d="M27.0896 3.74515L23.0996 2.42774V2.01172L26.9496 3.32913L27.0896 3.74515Z" fill="#FF3800" />
      <path d="M0.699961 25.5157L0.909961 27.0411L0.419961 24.3369L0.209961 22.7422L0.699961 25.5157Z" fill="#13FCE4" />
      <path d="M27.8602 15.7402L25.4102 15.3242V17.4043L27.7902 17.6817L27.8602 15.7402Z" fill="#ADFF49" />
      <path d="M23.2399 15.0469H21.4199V17.4043L23.2399 17.2657V15.0469Z" fill="#B7FF40" />
      <path d="M19.1804 1.45706L15.6104 0.833018L16.1004 0.347656L19.3904 0.971693L19.1804 1.45706Z" fill="#F60B00" />
      <path d="M20.1596 17.75L19.3896 18.3047V20.8702L20.1596 20.1768V17.75Z" fill="#80FF77" />
      <path d="M0.42 18.2364L0 19.9698L0.07 17.1963L0.49 15.4629L0.42 18.2364Z" fill="#97FF60" />
      <path d="M37.7996 27.7344L37.0996 27.3184L37.6596 28.0117L38.3596 28.3584L37.7996 27.7344Z" fill="#00C0FF" />
      <path d="M2.52035 32.2422L2.87035 33.6983L1.96035 31.2022L1.61035 29.7461L2.52035 32.2422Z" fill="#0068FF" />
      <path d="M35.9801 24.2686L34.5801 23.7832L34.8601 25.0313L36.2601 25.4473L35.9801 24.2686Z" fill="#1FFFD7" />
      <path d="M43.5395 27.1797L42.7695 26.417L43.2595 25.377L44.0295 26.2783L43.5395 27.1797Z" fill="#00E0FB" />
      <path d="M32.4799 18.7901L30.2399 18.166L30.1699 19.8995L32.4799 20.3848V18.7901Z" fill="#80FF77" />
      <path d="M44.2399 21.6338L42.4199 20.3858L42.5599 18.9297L44.3099 20.3164L44.2399 21.6338Z" fill="#66FF90" />
      <path d="M39.2002 11.7178L35.8402 9.91506L35.4902 8.73633L38.8502 10.6084L39.2002 11.7178Z" fill="#FFCC00" />
      <path d="M30.31 16.3643L27.86 15.7402L27.79 17.6817L30.24 18.167L30.31 16.3643Z" fill="#AAFF4D" />
      <path d="M21.4198 15.0469L20.0898 15.1855L20.1598 17.751L21.4198 17.4043V15.0469Z" fill="#BAFF3C" />
      <path d="M34.3705 21.0088L32.4805 20.3848L32.5505 21.9795L34.4405 22.4649L34.3705 21.0088Z" fill="#5AFF9D" />
      <path d="M31.0802 5.33891L27.0902 3.74415L26.9502 3.32812L30.7302 4.85355L31.0802 5.33891Z" fill="#FF4E00" />
      <path d="M2.09953 11.2335L0.769531 12.8282L1.32953 10.4014L2.65953 8.80664L2.09953 11.2335Z" fill="#FFD000" />
      <path d="M42.4205 16.0176L39.6905 14.4229L39.4805 12.9668L42.2105 14.7002L42.4205 16.0176Z" fill="#D1FF26" />
      <path d="M11.8303 1.45607L9.03027 1.66409L9.94027 0.7627L12.4603 0.554688L11.8303 1.45607Z" fill="#F10800" />
      <path d="M37.3097 26.9024L36.6797 26.4863L37.1697 27.3184L37.7997 27.7344L37.3097 26.9024Z" fill="#00D8FF" />
      <path d="M17.0097 37.3027L17.2197 38.7588L15.8197 40.0069L15.6797 38.4815L17.0097 37.3027Z" fill="#0000ED" />
      <path d="M35.1401 7.83599L31.3601 6.03322L31.0801 5.33984L34.7901 7.07328L35.1401 7.83599Z" fill="#FF7A00" />
      <path d="M15.61 0.833024L12.46 0.555675L13.16 0.0703125L16.1 0.347662L15.61 0.833024Z" fill="#DF0000" />
      <path d="M25.4802 13.2441L23.2402 12.8281V15.0469L25.4102 15.3243L25.4802 13.2441Z" fill="#E4FF13" />
      <path d="M23.0996 2.01174L19.3896 0.971681L19.6696 0.902344L23.0996 1.87307V2.01174Z" fill="#FA0F00" />
      <path d="M8.3302 3.05081L5.9502 3.74419L6.8602 2.35744L9.0302 1.66406L8.3302 3.05081Z" fill="#FF2500" />
      <path d="M44.0298 26.2793L43.2598 25.378L43.6798 24.1992L44.4498 25.2393L44.0298 26.2793Z" fill="#16FFE1" />
      <path d="M14.3502 40.7695L14.5602 42.0869L13.0202 42.4336L12.7402 41.1162L14.3502 40.7695Z" fill="#00009F" />
      <path d="M26.9496 3.32718L23.0996 2.00977V1.87109L26.7396 3.18851L26.9496 3.32718Z" fill="#FF2500" />
      <path d="M28.0002 13.8682L25.4802 13.2441L25.4102 15.3243L27.8602 15.7403L28.0002 13.8682Z" fill="#DBFF1C" />
      <path d="M23.2396 12.8272L21.3496 12.6191L21.4196 15.046H23.2396V12.8272Z" fill="#EBFF0C" />
      <path d="M19.1104 25.2383L19.5304 26.417L19.0404 28.8438L18.6904 27.6651L19.1104 25.2383Z" fill="#02E8F4" />
      <path d="M35.7704 23.0195L34.4404 22.4648L34.5804 23.7823L35.9804 24.2676L35.7704 23.0195Z" fill="#3CFFBA" />
      <path d="M19.1102 28.8438L19.6702 30.0225L18.9002 32.3106L18.4102 31.0625L19.1102 28.8438Z" fill="#0098FF" />
      <path d="M19.1097 21.7031L19.2497 22.6738L19.1097 25.2393L18.9697 24.1993L19.1097 21.7031Z" fill="#43FFB4" />
      <path d="M19.3896 0.970718L16.0996 0.346681L16.5896 0.277344L19.6696 0.901381L19.3896 0.970718Z" fill="#DF0000" />
      <path d="M8.19039 40.9092L8.47039 42.0879L7.00039 40.7705L6.65039 39.5918L8.19039 40.9092Z" fill="#0000B2" />
      <path d="M44.3096 20.3155L42.5596 18.9287V17.4727L44.3096 18.9287V20.3155Z" fill="#8AFF6D" />
      <path d="M6.65 39.5918L7 40.7706L5.6 39.1065L5.25 37.9277L6.65 39.5918Z" fill="#0000DA" />
      <path d="M32.6202 17.1953L30.3102 16.3633L30.2402 18.1661L32.4802 18.7901L32.6202 17.1953Z" fill="#A7FF50" />
      <path d="M19.3904 18.3047L19.1104 18.9981V21.7022L19.3904 20.8702V18.3047Z" fill="#80FF77" />
      <path d="M36.9598 25.9326L36.2598 25.4473L36.6798 26.4873L37.3098 26.9034L36.9598 25.9326Z" fill="#0CF4EB" />
      <path d="M40.1104 29.8145L40.3204 29.3291L41.0204 29.2598L40.8104 29.7451L40.1104 29.8145Z" fill="#00A8FF" />
      <path d="M38.8496 10.6094L35.4896 8.73732L35.1396 7.83594L38.4296 9.70805L38.8496 10.6094Z" fill="#FFAB00" />
      <path d="M1.61016 29.7452L1.96016 31.2013L1.26016 28.5664L0.910156 27.041L1.61016 29.7452Z" fill="#00B0FF" />
      <path d="M30.5204 14.6299L28.0004 13.8672L27.8604 15.7393L30.3104 16.4327L30.5204 14.6299Z" fill="#D4FF23" />
      <path d="M39.4805 29.6074L39.6205 29.1914L40.3205 29.3301L40.1105 29.8154L39.4805 29.6074Z" fill="#00ACFF" />
      <path d="M5.18035 5.54691L3.36035 6.65631L4.27035 4.85354L5.95035 3.74414L5.18035 5.54691Z" fill="#FF5500" />
      <path d="M0.21 22.7422L0.42 24.337L0.21 21.4942L0 19.9688L0.21 22.7422Z" fill="#53FFA4" />
      <path d="M9.80043 41.8809L10.0104 42.9903L8.47043 42.0889L8.19043 40.9102L9.80043 41.8809Z" fill="#00009B" />
      <path d="M30.7302 4.85355L26.9502 3.32813L26.7402 3.18945L30.3802 4.78421L30.7302 4.85355Z" fill="#FF3F00" />
      <path d="M40.8096 29.7451L41.0196 29.2598L41.7196 28.9824L41.5096 29.6065L40.8096 29.7451Z" fill="#00ACFF" />
      <path d="M20.09 15.1855L19.25 15.6709L19.39 18.3057L20.16 17.751L20.09 15.1855Z" fill="#BEFF39" />
      <path d="M18.8999 32.3105L19.2499 33.6973L18.1999 35.6387L17.9199 34.252L18.8999 32.3105Z" fill="#0050FF" />
      <path d="M34.3705 19.5537L32.4805 18.791V20.3858L34.3705 21.0098V19.5537Z" fill="#80FF77" />
      <path d="M44.4497 25.2393L43.6797 24.1992L44.0297 22.9512L44.7997 24.0606L44.4497 25.2393Z" fill="#30FFC7" />
      <path d="M42.2102 14.7003L39.4802 12.9668L39.2002 11.7188L41.8602 13.5215L42.2102 14.7003Z" fill="#F1FC06" />
      <path d="M38.7803 29.3301L38.9903 28.9141L39.6203 29.1914L39.4803 29.6074L38.7803 29.3301Z" fill="#00B4FF" />
      <path d="M5.25023 37.9278L5.60023 39.1065L4.34023 37.1651L3.99023 35.9863L5.25023 37.9278Z" fill="#0000FF" />
      <path d="M34.7905 7.07231L31.0805 5.33888L30.7305 4.85352L34.3005 6.65629L34.7905 7.07231Z" fill="#FF6400" />
      <path d="M41.5098 29.6065L41.7198 28.9824L42.3498 28.5664L42.1398 29.1904L41.5098 29.6065Z" fill="#00B8FF" />
      <path d="M25.6202 11.2324L23.2402 10.6777V12.8272L25.4802 13.2432L25.6202 11.2324Z" fill="#FFD700" />
      <path d="M21.3499 12.6191L19.8799 12.6885L20.0899 15.1846L21.4199 15.046L21.3499 12.6191Z" fill="#F1FC06" />
      <path d="M23.0999 1.87307L19.6699 0.902344L19.9499 1.24903L23.1699 2.21976L23.0999 1.87307Z" fill="#ED0400" />
      <path d="M26.7396 3.18851L23.0996 1.87109L23.1696 2.21778L26.5996 3.46585L26.7396 3.18851Z" fill="#FF1E00" />
      <path d="M28.2105 11.9951L25.6205 11.2324L25.4805 13.2432L28.0005 13.8672L28.2105 11.9951Z" fill="#FFE200" />
      <path d="M38.2197 28.8447L38.3597 28.3594L38.9897 28.9141L38.7797 29.3301L38.2197 28.8447Z" fill="#00C0FF" />
      <path
        d="M0.490313 15.4629L0.0703125 17.1964L0.420312 14.4922L0.840312 12.8281L0.490313 15.4629Z"
        fill="#D7FF1F"
      />
      <path d="M11.4098 42.3643L11.6198 43.4737L10.0098 42.9883L9.7998 41.8789L11.4098 42.3643Z" fill="#000092" />
      <path d="M23.24 10.6787L21.21 10.332L21.35 12.6202L23.24 12.8282V10.6787Z" fill="#FFCC00" />
      <path d="M42.1396 29.1914L42.3496 28.5674L42.9796 27.9434L42.7696 28.6367L42.1396 29.1914Z" fill="#00C4FF" />
      <path d="M35.7001 21.7031L34.3701 21.0098L34.4401 22.4659L35.7701 23.0206L35.7001 21.7031Z" fill="#5DFF9A" />
      <path d="M36.6105 24.8223L35.9805 24.2676L36.2605 25.4463L36.8905 25.9317L36.6105 24.8223Z" fill="#26FFD1" />
      <path d="M44.3099 18.9298L42.4899 17.4737L42.4199 16.0176L44.1699 17.6817L44.3099 18.9298Z" fill="#AAFF4D" />
      <path d="M16.1002 0.346687L13.1602 0.0693374L13.9302 0L16.5902 0.27735L16.1002 0.346687Z" fill="#C80000" />
      <path d="M15.8196 40.0078L16.0296 41.3252L14.5596 42.0879L14.3496 40.7705L15.8196 40.0078Z" fill="#0000C4" />
      <path d="M37.6602 28.1504L37.8002 27.7344L38.3602 28.3584L38.2202 28.8438L37.6602 28.1504Z" fill="#00D0FF" />
      <path
        d="M12.4604 0.555675L9.94043 0.763687L10.8504 0.208987L13.1604 0.0703125L12.4604 0.555675Z"
        fill="#CD0000"
      />
      <path d="M32.8296 15.6016L30.5196 14.6309L30.3096 16.3643L32.6196 17.1963L32.8296 15.6016Z" fill="#CEFF29" />
      <path d="M30.3796 4.78421L26.7396 3.18945L26.5996 3.4668L30.0296 4.99223L30.3796 4.78421Z" fill="#FF3800" />
      <path d="M30.73 12.9668L28.21 11.9961L28 13.8682L30.52 14.6309L30.73 12.9668Z" fill="#FBF100" />
      <path d="M44.8003 24.0596L44.0303 22.9502L44.2403 21.6328L45.0103 22.8809L44.8003 24.0596Z" fill="#4DFFAA" />
      <path d="M3.99012 35.9854L4.34012 37.1641L3.22012 34.9453L2.87012 33.6973L3.99012 35.9854Z" fill="#0030FF" />
      <path d="M19.6698 0.901381L16.5898 0.277344L17.0798 0.624031L19.9498 1.24807L19.6698 0.901381Z" fill="#D10000" />
      <path d="M38.43 9.70709L35.14 7.83498L34.79 7.07227L37.94 9.01371L38.43 9.70709Z" fill="#FF9100" />
      <path d="M18.1998 35.6387L18.4098 37.1641L17.2198 38.7589L17.0098 37.3028L18.1998 35.6387Z" fill="#0014FF" />
      <path d="M25.7602 9.36037L23.2402 8.59766V10.6778L25.6202 11.2325L25.7602 9.36037Z" fill="#FFA700" />
      <path d="M42.7695 28.6358L42.9795 27.9424L43.5395 27.1797L43.3295 27.9424L42.7695 28.6358Z" fill="#00D8FF" />
      <path d="M34.5105 18.0967L32.6205 17.1953L32.4805 18.7901L34.3705 19.5528L34.5105 18.0967Z" fill="#A4FF53" />
      <path d="M34.2999 6.65727L30.7299 4.85449L30.3799 4.78516L33.8099 6.51859L34.2999 6.65727Z" fill="#FF5500" />
      <path d="M41.8596 13.5215L39.1996 11.7188L38.8496 10.6094L41.5096 12.4815L41.8596 13.5215Z" fill="#FFDB00" />
      <path d="M26.5999 3.46682L23.1699 2.21875V2.84279L26.3899 4.02152L26.5999 3.46682Z" fill="#FF1E00" />
      <path d="M2.66008 8.80571L1.33008 10.4005L2.17008 8.18167L3.36008 6.65625L2.66008 8.80571Z" fill="#FF8D00" />
      <path d="M28.4201 10.2627L25.7601 9.36133L25.6201 11.2334L28.2101 11.9962L28.4201 10.2627Z" fill="#FFB600" />
      <path d="M23.1702 2.21877L19.9502 1.24805L20.1602 1.94142L23.1702 2.84281V2.21877Z" fill="#F10800" />
      <path d="M9.03035 1.66311L6.86035 2.35648L7.98035 1.31642L9.94035 0.761719L9.03035 1.66311Z" fill="#E40000" />
      <path d="M37.1699 27.3877L37.3099 26.9023L37.7999 27.7344L37.6599 28.1504L37.1699 27.3877Z" fill="#02E8F4" />
      <path d="M21.2099 10.3311L19.6699 10.2617L19.8799 12.6885L21.3499 12.6192L21.2099 10.3311Z" fill="#FFC100" />
      <path d="M23.2403 8.59767L21.0703 8.18164L21.2103 10.3311L23.2403 10.6778V8.59767Z" fill="#FF9800" />
      <path d="M13.0202 42.4346L13.1602 43.544L11.6202 43.4746L11.4102 42.3652L13.0202 42.4346Z" fill="#000096" />
      <path d="M19.8797 12.6895L18.9697 13.0361L19.2497 15.671L20.0897 15.1856L19.8797 12.6895Z" fill="#F8F500" />
      <path d="M36.4695 23.7129L35.7695 23.0195L35.9795 24.2676L36.6095 24.8223L36.4695 23.7129Z" fill="#43FFB4" />
      <path d="M0.909922 27.0421L1.25992 28.5675L0.769922 25.794L0.419922 24.3379L0.909922 27.0421Z" fill="#13FCE4" />
      <path d="M30.0296 4.99222L26.5996 3.4668L26.3896 4.0215L29.6796 5.47758L30.0296 4.99222Z" fill="#FF3800" />
      <path d="M19.2497 15.6699L18.9697 16.294L19.1097 18.9981L19.3897 18.3047L19.2497 15.6699Z" fill="#C1FF36" />
      <path d="M35.7701 20.3848L34.3701 19.5527V21.0088L35.7001 21.7022L35.7701 20.3848Z" fill="#80FF77" />
      <path d="M25.9002 7.62697L23.2402 6.79492V8.5977L25.7602 9.36041L25.9002 7.62697Z" fill="#FF7E00" />
      <path d="M43.3301 27.9434L43.5401 27.1807L44.0301 26.2793L43.8201 27.1807L43.3301 27.9434Z" fill="#09F0EE" />
      <path d="M19.1104 18.998L19.2504 19.8994V22.6729L19.1104 21.7022V18.998Z" fill="#83FF73" />
      <path d="M31.08 11.3711L28.42 10.2617L28.21 11.9952L30.73 12.9659L31.08 11.3711Z" fill="#FFC400" />
      <path d="M26.3899 4.02249L23.1699 2.84375V3.81447L26.2499 4.92387L26.3899 4.02249Z" fill="#FF2900" />
      <path d="M19.3204 22.6738L19.8104 23.7139L19.5304 26.4181L19.1104 25.2393L19.3204 22.6738Z" fill="#40FFB7" />
      <path d="M28.6998 8.73635L25.8998 7.62695L25.7598 9.36039L28.4198 10.2618L28.6998 8.73635Z" fill="#FF9100" />
      <path d="M44.17 17.6807L42.42 16.0166L42.21 14.6992L43.96 16.4327L44.17 17.6807Z" fill="#CAFF2C" />
      <path d="M45.0102 22.8819L44.2402 21.6338L44.3102 20.3164L45.0802 21.6338L45.0102 22.8819Z" fill="#6AFF8D" />
      <path d="M33.1095 14.0762L30.7295 12.9668L30.5195 14.6309L32.8295 15.6016L33.1095 14.0762Z" fill="#F1FC06" />
      <path d="M19.53 26.418L20.16 27.5274L19.67 30.0235L19.04 28.8448L19.53 26.418Z" fill="#00E4F8" />
      <path d="M37.9398 9.01372L34.7898 7.07227L34.2998 6.65625L37.3798 8.5977L37.9398 9.01372Z" fill="#FF7A00" />
      <path d="M33.8103 6.51859L30.3803 4.78516L30.0303 4.99317L33.3203 6.65727L33.8103 6.51859Z" fill="#FF4E00" />
      <path d="M23.1702 2.84279L20.1602 1.94141L20.4402 3.05081L23.1702 3.81352V2.84279Z" fill="#FF1300" />
      <path d="M19.9501 1.24904L17.0801 0.625L17.5701 1.38771L20.1601 1.94241L19.9501 1.24904Z" fill="#D60000" />
      <path d="M26.0402 6.17092L23.2402 5.13086V6.79496L25.9002 7.62701L26.0402 6.17092Z" fill="#FF5900" />
      <path d="M0 19.9688L0.21 21.4942L0.28 18.7207L0.07 17.1953L0 19.9688Z" fill="#97FF60" />
      <path d="M26.2499 4.92385L23.1699 3.81445L23.2399 5.13186L26.0399 6.17193L26.2499 4.92385Z" fill="#FF3B00" />
      <path d="M2.86996 33.6973L3.21996 34.9454L2.30996 32.5186L1.95996 31.2012L2.86996 33.6973Z" fill="#0070FF" />
      <path d="M36.75 26.4863L36.96 25.9316L37.31 26.9024L37.17 27.3877L36.75 26.4863Z" fill="#16FFE1" />
      <path d="M29.68 5.47757L26.39 4.02148L26.25 4.92287L29.33 6.30962L29.68 5.47757Z" fill="#FF4300" />
      <path d="M23.2404 6.79591L20.8604 6.17188L21.0704 8.18266L23.2404 8.59869V6.79591Z" fill="#FF6C00" />
      <path d="M28.9804 7.35061L26.0404 6.17188L25.9004 7.62796L28.7004 8.73736L28.9804 7.35061Z" fill="#FF6F00" />
      <path d="M34.7901 16.6416L32.8301 15.6016L32.6201 17.1963L34.5101 18.0977L34.7901 16.6416Z" fill="#C7FF30" />
      <path d="M5.94953 3.74417L4.26953 4.85357L5.31953 3.32815L6.85953 2.35742L5.94953 3.74417Z" fill="#FF1E00" />
      <path d="M16.5897 0.27735L13.9297 0L14.6997 0.346687L17.0797 0.624037L16.5897 0.27735Z" fill="#BB0000" />
      <path d="M41.5097 12.4805L38.8497 10.6084L38.4297 9.70703L41.0197 11.5791L41.5097 12.4805Z" fill="#FFBD00" />
      <path d="M29.33 6.31058L26.25 4.92383L26.04 6.1719L28.98 7.35064L29.33 6.31058Z" fill="#FF5500" />
      <path d="M19.6704 30.0234L20.0204 31.4102L19.2504 33.6983L18.9004 32.3116L19.6704 30.0234Z" fill="#0098FF" />
      <path d="M23.1704 3.81349L20.4404 3.05078L20.6504 4.43753L23.2404 5.1309L23.1704 3.81349Z" fill="#FF2900" />
      <path d="M23.2404 5.13087L20.6504 4.4375L20.8604 6.17094L23.2404 6.79497V5.13087Z" fill="#FF4700" />
      <path d="M31.4299 9.9844L28.6999 8.73633L28.4199 10.2618L31.0799 11.3712L31.4299 9.9844Z" fill="#FFA300" />
      <path d="M21.0696 8.18262L19.3896 7.97461L19.6696 10.2627L21.2096 10.3321L21.0696 8.18262Z" fill="#FF8900" />
      <path d="M43.8203 27.1797L44.0303 26.2783L44.4503 25.2383L44.2403 26.209L43.8203 27.1797Z" fill="#1CFFDB" />
      <path d="M33.3197 6.65629L30.0297 4.99219L29.6797 5.47755L32.8297 7.07231L33.3197 6.65629Z" fill="#FF5200" />
      <path d="M36.3302 22.4658L35.7002 21.7031L35.7702 23.0205L36.4702 23.7139L36.3302 22.4658Z" fill="#60FF97" />
      <path d="M38.3604 30.1621L39.4804 29.6074L40.1104 29.8154L39.0604 30.3008L38.3604 30.1621Z" fill="#00BCFF" />
      <path d="M13.1596 0.0693374L10.8496 0.208012L11.8296 0.138675L13.9296 0L13.1596 0.0693374Z" fill="#B20000" />
      <path d="M38.9902 30.3008L40.1102 29.8154L40.8102 29.7461L39.6902 30.3701L38.9902 30.3008Z" fill="#00B8FF" />
      <path d="M17.2203 38.7598L17.3603 40.1465L16.0303 41.3253L15.8203 40.0078L17.2203 38.7598Z" fill="#0000F1" />
      <path d="M37.7305 29.8154L38.7805 29.3301L39.4805 29.6074L38.3605 30.1621L37.7305 29.8154Z" fill="#00C4FF" />
      <path d="M33.5305 12.6885L31.0805 11.3711L30.7305 12.9659L33.1105 14.0753L33.5305 12.6885Z" fill="#FFD700" />
      <path d="M35.9101 19.0684L34.5101 18.0977L34.3701 19.5537L35.7701 20.3858L35.9101 19.0684Z" fill="#A0FF56" />
      <path d="M37.3796 8.5977L34.2996 6.65625L33.8096 6.51758L36.8196 8.38969L37.3796 8.5977Z" fill="#FF6F00" />
      <path d="M20.1603 1.94142L17.5703 1.38672L18.0603 2.56546L20.4403 3.05082L20.1603 1.94142Z" fill="#E40000" />
      <path d="M31.9202 8.8057L28.9802 7.34961L28.7002 8.73636L31.4302 9.98443L31.9202 8.8057Z" fill="#FF8200" />
      <path d="M0.769609 12.8272L0.349609 14.4913L0.979609 11.9952L1.32961 10.4004L0.769609 12.8272Z" fill="#FFD000" />
      <path d="M14.5595 42.0879L14.6995 43.1973L13.1595 43.544L13.0195 42.4346L14.5595 42.0879Z" fill="#0000A8" />
      <path d="M19.6701 10.2617L18.6201 10.4697L18.9701 13.0352L19.8801 12.6885L19.6701 10.2617Z" fill="#FFB900" />
      <path d="M36.4697 25.4463L36.6097 24.8223L36.9597 25.9317L36.7497 26.4864L36.4697 25.4463Z" fill="#30FFC7" />
      <path d="M39.6904 30.3701L40.8104 29.7461L41.5104 29.6074L40.3204 30.1621L39.6904 30.3701Z" fill="#00BCFF" />
      <path d="M45.0796 21.6338L44.3096 20.3164V18.9297L45.0796 20.4551V21.6338Z" fill="#87FF70" />
      <path d="M32.8301 7.07328L29.6801 5.47852L29.3301 6.31056L32.3401 7.83599L32.8301 7.07328Z" fill="#FF5900" />
      <path d="M43.9604 16.4337L42.2104 14.7002L41.8604 13.5215L43.6104 15.3243L43.9604 16.4337Z" fill="#E7FF0F" />
      <path d="M37.1699 29.3291L38.2199 28.8438L38.7799 29.3291L37.7299 29.8145L37.1699 29.3291Z" fill="#00D0FF" />
      <path d="M32.3405 7.83597L29.3305 6.31055L28.9805 7.35061L31.9205 8.80669L32.3405 7.83597Z" fill="#FF6C00" />
      <path d="M20.8597 6.17188L18.9697 5.89453L19.3897 7.97465L21.0697 8.18267L20.8597 6.17188Z" fill="#FF5D00" />
      <path d="M8.47 42.0869L8.68 42.919L7.21 41.6709L7 40.7695L8.47 42.0869Z" fill="#0000C4" />
      <path d="M35.1401 15.2549L33.1101 14.0762L32.8301 15.6016L34.7901 16.6417L35.1401 15.2549Z" fill="#E7FF0F" />
      <path d="M40.3203 30.1621L41.5103 29.6074L42.1403 29.1914L41.0203 29.8848L40.3203 30.1621Z" fill="#00C4FF" />
      <path d="M41.0204 11.5792L38.4304 9.70705L37.9404 9.01367L40.4604 10.9551L41.0204 11.5792Z" fill="#FFA700" />
      <path d="M20.4396 3.05177L18.0596 2.56641L18.5496 4.02249L20.6496 4.43852L20.4396 3.05177Z" fill="#FF1300" />
      <path d="M6.99961 40.7696L7.20961 41.671L5.87961 40.0762L5.59961 39.1055L6.99961 40.7696Z" fill="#0000E8" />
      <path d="M44.2402 26.21L44.4502 25.2393L44.8002 24.0605L44.5202 25.1699L44.2402 26.21Z" fill="#36FFC1" />
      <path d="M36.6104 28.6358L37.6604 28.1504L38.2204 28.8438L37.1704 29.3291L36.6104 28.6358Z" fill="#00E0FB" />
      <path d="M20.6498 4.43751L18.5498 4.02148L18.9698 5.8936L20.8598 6.17095L20.6498 4.43751Z" fill="#FF3400" />
      <path d="M18.9701 13.0352L18.6201 13.5205L18.9701 16.294L19.2501 15.67L18.9701 13.0352Z" fill="#FBF100" />
      <path d="M19.2502 33.6973L19.3902 35.2227L18.4102 37.1641L18.2002 35.6387L19.2502 33.6973Z" fill="#0054FF" />
      <path d="M9.94047 0.761731L7.98047 1.31643L9.10047 0.761731L10.8505 0.207031L9.94047 0.761731Z" fill="#BB0000" />
      <path d="M17.0802 0.625006L14.7002 0.347656L15.4702 1.17971L17.5702 1.38772L17.0802 0.625006Z" fill="#BF0000" />
      <path d="M33.9501 11.3711L31.4301 9.98438L31.0801 11.3711L33.5301 12.6192L33.9501 11.3711Z" fill="#FFB600" />
      <path d="M36.8203 8.38969L33.8103 6.51758L33.3203 6.65625L36.2603 8.52836L36.8203 8.38969Z" fill="#FF6800" />
      <path d="M10.0097 42.9893L10.1497 43.8213L8.67973 42.9199L8.46973 42.0879L10.0097 42.9893Z" fill="#0000AD" />
      <path d="M1.95977 31.2012L2.30977 32.5186L1.67977 29.9532L1.25977 28.5664L1.95977 31.2012Z" fill="#00B4FF" />
      <path d="M0.419961 24.337L0.769961 25.7931L0.559961 23.0196L0.209961 21.4941L0.419961 24.337Z" fill="#53FFA4" />
      <path d="M36.4002 21.2862L35.7702 20.3848L35.7002 21.7022L36.3302 22.4649L36.4002 21.2862Z" fill="#7DFF7A" />
      <path d="M3.35992 6.65629L2.16992 8.18171L3.14992 6.24026L4.26992 4.85352L3.35992 6.65629Z" fill="#FF4E00" />
      <path d="M41.0195 29.8848L42.1395 29.1914L42.7695 28.6367L41.5795 29.3994L41.0195 29.8848Z" fill="#00D4FF" />
      <path d="M5.59984 39.1055L5.87984 40.0762L4.61984 38.1348L4.33984 37.1641L5.59984 39.1055Z" fill="#0004FF" />
      <path d="M36.2598 24.4063L36.4698 23.7129L36.6098 24.8223L36.4698 25.4463L36.2598 24.4063Z" fill="#49FFAD" />
      <path d="M18.9697 16.2949L19.1097 17.127L19.3197 19.9005L19.1097 18.9991L18.9697 16.2949Z" fill="#C1FF36" />
      <path d="M36.1201 27.9434L37.1701 27.3887L37.6601 28.1514L36.6101 28.6367L36.1201 27.9434Z" fill="#0CF4EB" />
      <path d="M36.1198 17.75L34.7898 16.6406L34.5098 18.0967L35.9098 19.0674L36.1198 17.75Z" fill="#C1FF36" />
      <path d="M34.5097 10.3321L31.9197 8.80664L31.4297 9.98538L33.9497 11.3721L34.5097 10.3321Z" fill="#FF9800" />
      <path d="M19.3899 7.97461L18.1299 8.04395L18.6199 10.4708L19.6699 10.2627L19.3899 7.97461Z" fill="#FF7E00" />
      <path d="M36.2601 8.52836L33.3201 6.65625L32.8301 7.07227L35.6301 8.87505L36.2601 8.52836Z" fill="#FF6C00" />
      <path d="M45.0799 20.4551L44.3099 18.9297L44.1699 17.6816L44.9399 19.2071L45.0799 20.4551Z" fill="#A7FF50" />
      <path d="M43.6098 15.3233L41.8598 13.5205L41.5098 12.4805L43.1898 14.3526L43.6098 15.3233Z" fill="#FFEA00" />
      <path d="M35.5604 14.0069L33.5304 12.6895L33.1104 14.0762L35.1404 15.2549L35.5604 14.0069Z" fill="#FFE600" />
      <path d="M40.4599 10.9551L37.9399 9.01368L37.3799 8.59766L39.8299 10.5391L40.4599 10.9551Z" fill="#FF9400" />
      <path d="M19.3203 19.9004L19.8103 20.9405V23.7139L19.3203 22.6739V19.9004Z" fill="#83FF73" />
      <path d="M35.0699 9.50004L32.3399 7.83594L31.9199 8.80666L34.5099 10.3321L35.0699 9.50004Z" fill="#FF8200" />
      <path d="M35.6298 8.87504L32.8298 7.07227L32.3398 7.83498L35.0698 9.49908L35.6298 8.87504Z" fill="#FF7300" />
      <path d="M11.6198 43.4736L11.7598 44.3057L10.1498 43.8203L10.0098 42.9883L11.6198 43.4736Z" fill="#0000A4" />
      <path d="M13.9301 0L11.8301 0.138675L12.8801 0.485362L14.7001 0.346687L13.9301 0Z" fill="#A40000" />
      <path d="M44.5195 25.169L44.7995 24.0596L45.0095 22.8809L44.7295 24.1289L44.5195 25.169Z" fill="#50FFA7" />
      <path d="M41.5801 29.3994L42.7701 28.6367L43.3301 27.9434L42.1401 28.7754L41.5801 29.3994Z" fill="#00E4F8" />
      <path d="M17.5697 1.3877L15.4697 1.17969L16.2397 2.35842L18.0597 2.56644L17.5697 1.3877Z" fill="#CD0000" />
      <path d="M19.8103 23.7129L20.4403 24.8223L20.1603 27.5264L19.5303 26.4171L19.8103 23.7129Z" fill="#40FFB7" />
      <path d="M35.7695 27.041L36.7495 26.4863L37.1695 27.3877L36.1195 27.8731L35.7695 27.041Z" fill="#1FFFD7" />
      <path
        d="M0.0703125 17.1963L0.280313 18.7218L0.560313 16.0869L0.350313 14.4922L0.0703125 17.1963Z"
        fill="#D7FF1F"
      />
      <path d="M6.86031 2.35647L5.32031 3.32719L6.58031 2.28713L7.98031 1.31641L6.86031 2.35647Z" fill="#DA0000" />
      <path d="M16.0296 41.3242L16.1696 42.503L14.6996 43.1963L14.5596 42.0869L16.0296 41.3242Z" fill="#0000CD" />
      <path d="M4.33973 37.1641L4.61973 38.1348L3.56973 35.9854L3.21973 34.9453L4.33973 37.1641Z" fill="#003CFF" />
      <path d="M18.9703 5.89356L17.5703 5.82422L18.1303 8.04302L19.3903 7.97368L18.9703 5.89356Z" fill="#FF4E00" />
      <path d="M36.5395 20.0391L35.9095 19.0684L35.7695 20.3858L36.3995 21.2872L36.5395 20.0391Z" fill="#9DFF5A" />
      <path d="M36.1904 23.2276L36.3304 22.4648L36.4704 23.7129L36.2604 24.4063L36.1904 23.2276Z" fill="#63FF94" />
      <path d="M18.0602 2.56543L16.2402 2.35742L16.9402 3.95218L18.5502 4.02152L18.0602 2.56543Z" fill="#E80000" />
      <path d="M36.0503 12.8965L33.9503 11.3711L33.5303 12.6192L35.5603 14.0059L36.0503 12.8965Z" fill="#FFC400" />
      <path d="M18.6199 10.4707L18.1299 10.8867L18.6199 13.5216L18.9699 13.0362L18.6199 10.4707Z" fill="#FFAE00" />
      <path d="M39.8303 10.5401L37.3803 8.59864L36.8203 8.39062L39.2003 10.3321L39.8303 10.5401Z" fill="#FF8600" />
      <path d="M36.47 16.502L35.14 15.2539L34.79 16.6407L36.12 17.7501L36.47 16.502Z" fill="#DEFF19" />
      <path d="M20.1599 27.5273L20.5799 28.9141L20.0199 31.4102L19.6699 30.0235L20.1599 27.5273Z" fill="#00E4F8" />
      <path d="M18.5504 4.02246L16.9404 3.95312L17.5704 5.89457H18.9704L18.5504 4.02246Z" fill="#FF2200" />
      <path d="M18.4097 37.1641L18.5497 38.6201L17.3597 40.1456L17.2197 38.7588L18.4097 37.1641Z" fill="#0018FF" />
      <path d="M43.1895 14.3536L41.5095 12.4815L41.0195 11.5801L42.6995 13.5215L43.1895 14.3536Z" fill="#FFD000" />
      <path d="M42.1396 28.7744L43.3296 27.9424L43.8196 27.1797L42.6296 28.0117L42.1396 28.7744Z" fill="#0FF8E7" />
      <path d="M44.94 19.2071L44.17 17.6817L43.96 16.4336L44.73 18.0977L44.94 19.2071Z" fill="#C1FF36" />
      <path d="M35.4902 26.0713L36.4702 25.4473L36.7502 26.4873L35.7702 27.042L35.4902 26.0713Z" fill="#33FFC4" />
      <path d="M13.1601 43.5439L13.2301 44.376L11.6901 44.3067L11.6201 43.4746L13.1601 43.5439Z" fill="#0000A8" />
      <path d="M44.7305 24.129L45.0105 22.8809L45.0805 21.6328L44.8005 22.9502L44.7305 24.129Z" fill="#6AFF8D" />
      <path d="M10.8496 0.208009L9.09961 0.762709L10.3596 0.624034L11.8296 0.138672L10.8496 0.208009Z" fill="#9F0000" />
      <path d="M36.4697 30.7168L38.3597 30.1621L38.9897 30.3008L37.0997 30.9248L36.4697 30.7168Z" fill="#00C8FF" />
      <path d="M36.6102 11.9268L34.5102 10.332L33.9502 11.3721L36.0502 12.8975L36.6102 11.9268Z" fill="#FFAB00" />
      <path d="M35.8398 30.3692L37.7298 29.8145L38.3598 30.1611L36.4698 30.7158L35.8398 30.3692Z" fill="#00D0FF" />
      <path d="M39.1998 10.3321L36.8198 8.39062L36.2598 8.5293L38.5698 10.4014L39.1998 10.3321Z" fill="#FF8200" />
      <path d="M1.25953 28.5665L1.67953 29.9532L1.18953 27.2491L0.769531 25.793L1.25953 28.5665Z" fill="#16FFE1" />
      <path d="M1.33047 10.4004L0.980469 11.9952L1.82047 9.7764L2.17047 8.18164L1.33047 10.4004Z" fill="#FF8D00" />
      <path d="M37.0996 30.9248L38.9896 30.3008L39.6896 30.3701L37.7296 30.9942L37.0996 30.9248Z" fill="#00C8FF" />
      <path d="M35.2803 29.8848L37.1703 29.3301L37.7303 29.8154L35.8403 30.3701L35.2803 29.8848Z" fill="#00D8FF" />
      <path d="M14.6999 0.347656L12.8799 0.486331L13.9299 1.31838L15.4699 1.17971L14.6999 0.347656Z" fill="#A80000" />
      <path d="M18.6201 13.5215L18.6901 14.2842L19.1101 17.127L18.9701 16.295L18.6201 13.5215Z" fill="#FEED00" />
      <path d="M37.2398 11.1641L35.0698 9.5L34.5098 10.332L36.6098 11.9268L37.2398 11.1641Z" fill="#FF9800" />
      <path d="M38.5699 10.4014L36.2599 8.5293L35.6299 8.87598L37.8699 10.6788L38.5699 10.4014Z" fill="#FF8200" />
      <path d="M36.1904 22.1192L36.4004 21.2871L36.3304 22.4658L36.1904 23.2286V22.1192Z" fill="#7DFF7A" />
      <path d="M36.7502 18.8594L36.1202 17.75L35.9102 19.0674L36.5402 20.0381L36.7502 18.8594Z" fill="#BAFF3C" />
      <path d="M36.9596 15.3926L35.5596 14.0059L35.1396 15.2539L36.4696 16.502L36.9596 15.3926Z" fill="#FBF100" />
      <path d="M37.7305 30.9942L39.6905 30.3701L40.3205 30.1621L38.3605 30.8555L37.7305 30.9942Z" fill="#00C8FF" />
      <path d="M42.6299 28.0118L43.8199 27.1797L44.2399 26.209L42.9799 27.1797L42.6299 28.0118Z" fill="#26FFD1" />
      <path d="M34.79 29.2608L36.61 28.6367L37.17 29.3301L35.28 29.8848L34.79 29.2608Z" fill="#02E8F4" />
      <path d="M3.21957 34.9463L3.56957 35.9864L2.72957 33.6289L2.30957 32.5195L3.21957 34.9463Z" fill="#0078FF" />
      <path d="M37.8703 10.6778L35.6303 8.875L35.0703 9.49904L37.2403 11.1631L37.8703 10.6778Z" fill="#FF8900" />
      <path d="M42.7 13.5206L41.02 11.5791L40.46 10.9551L42.14 12.8965L42.7 13.5206Z" fill="#FFB900" />
      <path d="M20.02 31.4102L20.16 33.0049L19.39 35.2237L19.25 33.6983L20.02 31.4102Z" fill="#0098FF" />
      <path d="M0.209961 21.4942L0.559961 23.0196L0.629961 20.3155L0.279961 18.7207L0.209961 21.4942Z" fill="#97FF60" />
      <path d="M18.13 8.04297L17.5 8.38966L18.13 10.8858L18.62 10.4698L18.13 8.04297Z" fill="#FF7300" />
      <path d="M35.2803 25.0996L36.2603 24.4062L36.4703 25.4463L35.4903 26.0703L35.2803 25.0996Z" fill="#4DFFAA" />
      <path d="M44.7304 18.0977L43.9604 16.4336L43.6104 15.3242L44.3804 17.0577L44.7304 18.0977Z" fill="#DEFF19" />
      <path d="M4.27039 4.85355L3.15039 6.2403L4.41039 4.71487L5.32039 3.32812L4.27039 4.85355Z" fill="#FF1A00" />
      <path d="M38.3604 30.8555L40.3204 30.1621L41.0204 29.8848L38.9904 30.5781L38.3604 30.8555Z" fill="#00D0FF" />
      <path d="M34.2998 28.4981L36.1198 27.9434L36.6098 28.6367L34.7898 29.2608L34.2998 28.4981Z" fill="#13FCE4" />
      <path d="M44.7998 22.9512L45.0798 21.6338V20.4551L44.7998 21.8418V22.9512Z" fill="#87FF70" />
      <path d="M19.1104 17.127L19.5304 18.0283L19.8104 20.9405L19.3204 19.9005L19.1104 17.127Z" fill="#C4FF33" />
      <path d="M15.4697 1.17969L13.9297 1.31836L14.9097 2.56644L16.2397 2.35842L15.4697 1.17969Z" fill="#B60000" />
      <path d="M37.4496 14.4219L36.0496 12.8965L35.5596 14.0059L36.9596 15.3926L37.4496 14.4219Z" fill="#FFD700" />
      <path d="M42.1401 12.8965L40.4601 10.9551L39.8301 10.5391L41.5101 12.4805L42.1401 12.8965Z" fill="#FFA700" />
      <path d="M17.3603 40.1465L17.5003 41.3252L16.1703 42.504L16.0303 41.3252L17.3603 40.1465Z" fill="#0000FA" />
      <path d="M14.7002 43.1973L14.7702 44.0293L13.2302 44.376L13.1602 43.544L14.7002 43.1973Z" fill="#0000BB" />
      <path d="M42.9805 27.1807L44.2405 26.21L44.5205 25.1699L43.2605 26.21L42.9805 27.1807Z" fill="#3CFFBA" />
      <path d="M38.9902 30.5772L41.0202 29.8838L41.5802 29.3984L39.5502 30.1611L38.9902 30.5772Z" fill="#00DCFE" />
      <path d="M37.1701 17.75L36.4701 16.502L36.1201 17.75L36.7501 18.8594L37.1701 17.75Z" fill="#D4FF23" />
      <path d="M17.5705 5.82422L16.7305 6.17091L17.5005 8.3897L18.1305 8.04302L17.5705 5.82422Z" fill="#FF3F00" />
      <path d="M36.3304 21.0098L36.5404 20.0391L36.4004 21.2871L36.1904 22.1192L36.3304 21.0098Z" fill="#9AFF5D" />
      <path d="M7.98008 1.31837L6.58008 2.2891L7.91008 1.66506L9.10008 0.763672L7.98008 1.31837Z" fill="#B20000" />
      <path d="M8.67996 42.9199L8.74996 43.4746L7.34996 42.2266L7.20996 41.6719L8.67996 42.9199Z" fill="#0000D6" />
      <path d="M33.9502 27.6651L35.7702 27.041L36.1202 27.8731L34.3002 28.4971L33.9502 27.6651Z" fill="#26FFD1" />
      <path d="M7.20988 41.6709L7.34988 42.2256L6.01988 40.7002L5.87988 40.0762L7.20988 41.6709Z" fill="#0000FA" />
      <path d="M35.21 24.0606L36.19 23.2285L36.26 24.4073L35.28 25.1006L35.21 24.0606Z" fill="#63FF94" />
      <path d="M19.8096 20.9395L20.4396 21.9795V24.8224L19.8096 23.713V20.9395Z" fill="#83FF73" />
      <path d="M16.2402 2.35742L14.9102 2.56543L15.8902 4.1602L16.9402 3.95218L16.2402 2.35742Z" fill="#D60000" />
      <path d="M11.8304 0.138672L10.3604 0.624034L11.6204 0.970721L12.8804 0.485359L11.8304 0.138672Z" fill="#960000" />
      <path d="M44.3804 17.0577L43.6104 15.3242L43.1904 14.3535L43.9604 16.1563L44.3804 17.0577Z" fill="#F4F802" />
      <path d="M16.9396 3.95312L15.8896 4.16114L16.7296 6.17192L17.5696 5.82524L16.9396 3.95312Z" fill="#FF1300" />
      <path d="M10.1497 43.8213L10.2197 44.3067L8.74969 43.4746L8.67969 42.9199L10.1497 43.8213Z" fill="#0000BF" />
      <path d="M38.0798 13.5899L36.6098 11.9258L36.0498 12.8965L37.4498 14.4219L38.0798 13.5899Z" fill="#FFC100" />
      <path d="M0.349609 14.4922L0.559609 16.087L1.18961 13.5909L0.979609 11.9961L0.349609 14.4922Z" fill="#FFD000" />
      <path d="M41.5102 12.4815L39.8302 10.54L39.2002 10.332L40.8102 12.2735L41.5102 12.4815Z" fill="#FF9C00" />
      <path d="M44.8002 21.8419L45.0802 20.4551L44.9402 19.207L44.6602 20.7325L44.8002 21.8419Z" fill="#A0FF56" />
      <path d="M18.1296 10.8867L18.0596 11.5801L18.6896 14.2843L18.6196 13.5215L18.1296 10.8867Z" fill="#FFAB00" />
      <path d="M5.88012 40.0762L6.02012 40.7003L4.83012 38.8975L4.62012 38.1348L5.88012 40.0762Z" fill="#0014FF" />
      <path d="M39.5498 30.1621L41.5798 29.3994L42.1398 28.7754L40.0398 29.6074L39.5498 30.1621Z" fill="#06ECF1" />
      <path d="M2.30969 32.5186L2.72969 33.628L2.02969 31.1319L1.67969 29.9531L2.30969 32.5186Z" fill="#00BCFF" />
      <path d="M19.3902 35.2227L19.6002 36.6787L18.5502 38.6202L18.4102 37.1641L19.3902 35.2227Z" fill="#0054FF" />
      <path d="M33.6699 26.7637L35.4899 26.0703L35.7699 27.041L33.9499 27.6651L33.6699 26.7637Z" fill="#39FFBE" />
      <path d="M38.7104 12.8975L37.2404 11.1641L36.6104 11.9268L38.0804 13.5215L38.7104 12.8975Z" fill="#FFAE00" />
      <path d="M20.4402 24.8223L20.8602 26.209L20.5802 28.9132L20.1602 27.5264L20.4402 24.8223Z" fill="#40FFB7" />
      <path d="M40.8103 12.2735L39.2003 10.332L38.5703 10.4014L40.1103 12.2735H40.8103Z" fill="#FF9800" />
      <path d="M0.76957 25.793L1.18957 27.2491L1.04957 24.545L0.55957 23.0195L0.76957 25.793Z" fill="#56FFA0" />
      <path d="M43.2598 26.209L44.5198 25.169L44.7298 24.1289L43.4698 25.2383L43.2598 26.209Z" fill="#53FFA4" />
      <path d="M37.5897 16.71L36.9597 15.3926L36.4697 16.502L37.0997 17.7501L37.5897 16.71Z" fill="#EEFF09" />
      <path d="M39.4102 12.4805L37.8702 10.6777L37.2402 11.1631L38.7102 12.8965L39.4102 12.4805Z" fill="#FF9F00" />
      <path d="M40.1101 12.2725L38.5701 10.4004L37.8701 10.6777L39.4101 12.4805L40.1101 12.2725Z" fill="#FF9800" />
      <path d="M36.6101 19.8994L36.7501 18.8594L36.5401 20.0381L36.3301 21.0088L36.6101 19.8994Z" fill="#B4FF43" />
      <path d="M11.6904 44.3057V44.791L10.2204 44.3057L10.1504 43.8203L11.6904 44.3057Z" fill="#0000B6" />
      <path d="M33.25 30.9932L35.84 30.3691L36.47 30.7158L33.88 31.3399L33.25 30.9932Z" fill="#00D8FF" />
      <path d="M43.9602 16.1563L43.1902 14.3535L42.7002 13.5215L43.4002 15.3936L43.9602 16.1563Z" fill="#FFDE00" />
      <path d="M35.21 22.9512L36.19 22.1191V23.2285L35.21 24.0606V22.9512Z" fill="#7DFF7A" />
      <path d="M33.8799 31.3408L36.4699 30.7168L37.0999 30.9248L34.4399 31.5488L33.8799 31.3408Z" fill="#00D0FF" />
      <path d="M32.7598 30.4395L35.2798 29.8848L35.8398 30.3701L33.2498 30.9942L32.7598 30.4395Z" fill="#00E0FB" />
      <path d="M34.4404 31.5479L37.1004 30.9238L37.7304 30.9932L35.0704 31.6172L34.4404 31.5479Z" fill="#00D0FF" />
      <path d="M2.17031 8.18168L1.82031 9.77644L2.87031 7.835L3.15031 6.24023L2.17031 8.18168Z" fill="#FF4E00" />
      <path d="M32.2695 29.8145L34.7895 29.2598L35.2795 29.8838L32.7595 30.4385L32.2695 29.8145Z" fill="#09F0EE" />
      <path d="M40.04 29.6065L42.14 28.7744L42.63 28.0117L40.53 28.9131L40.04 29.6065Z" fill="#19FFDE" />
      <path d="M4.62031 38.1358L4.83031 38.8985L3.85031 36.8184L3.57031 35.9863L4.62031 38.1358Z" fill="#0048FF" />
      <path d="M33.5303 25.793L35.2803 25.0996L35.4903 26.0703L33.6703 26.7637L33.5303 25.793Z" fill="#50FFA7" />
      <path d="M44.6602 20.7325L44.9402 19.2071L44.7302 18.0977L44.4502 19.6924L44.6602 20.7325Z" fill="#BEFF39" />
      <path d="M12.8801 0.486328L11.6201 0.97169L12.8801 1.80374L13.9301 1.31838L12.8801 0.486328Z" fill="#960000" />
      <path d="M38.15 15.8789L37.45 14.3535L36.96 15.3936L37.59 16.711L38.15 15.8789Z" fill="#FFE600" />
      <path d="M16.1702 42.5039V43.336L14.7702 44.0293L14.7002 43.1973L16.1702 42.5039Z" fill="#0000DA" />
      <path d="M35.0703 31.6182L37.7303 30.9941L38.3603 30.8555L35.7003 31.5488L35.0703 31.6182Z" fill="#00D0FF" />
      <path d="M18.6904 14.2832L19.0404 15.1846L19.5304 18.0274L19.1104 17.126L18.6904 14.2832Z" fill="#FFEA00" />
      <path d="M31.8496 29.1221L34.2996 28.498L34.7896 29.2608L32.2696 29.8155L31.8496 29.1221Z" fill="#16FFE1" />
      <path d="M17.5 8.39062L17.29 9.01466L18.06 11.5801L18.13 10.8868L17.5 8.39062Z" fill="#FF6C00" />
      <path d="M0.280273 18.7208L0.630273 20.3155L0.980273 17.6807L0.560273 16.0859L0.280273 18.7208Z" fill="#D7FF1F" />
      <path d="M43.4697 25.2393L44.7297 24.1299L44.7997 22.9512L43.5397 24.1992L43.4697 25.2393Z" fill="#6DFF8A" />
      <path d="M5.32016 3.32912L4.41016 4.71587L5.81016 3.60647L6.58016 2.28906L5.32016 3.32912Z" fill="#D10000" />
      <path d="M43.3996 15.3926L42.6996 13.5205L42.1396 12.8965L42.8396 14.7686L43.3996 15.3926Z" fill="#FFCC00" />
      <path d="M20.5795 28.9141L20.7195 30.4395L20.1595 33.005L20.0195 31.4102L20.5795 28.9141Z" fill="#00E4F8" />
      <path d="M36.9604 18.9287L37.1704 17.75L36.7504 18.8594L36.6104 19.8995L36.9604 18.9287Z" fill="#CEFF29" />
      <path d="M9.10016 0.763675L7.91016 1.66506L9.38016 1.52639L10.3602 0.625L9.10016 0.763675Z" fill="#960000" />
      <path d="M35.35 21.9112L36.33 21.0098L36.19 22.1192L35.21 22.9512L35.35 21.9112Z" fill="#97FF60" />
      <path d="M13.2304 44.376L13.1604 44.8613L11.6904 44.792V44.3066L13.2304 44.376Z" fill="#0000BB" />
      <path d="M35.7002 31.5488L38.3602 30.8555L38.9902 30.5781L36.2602 31.3408L35.7002 31.5488Z" fill="#00D8FF" />
      <path d="M18.5504 38.6211L18.6904 39.7998L17.5004 41.3253L17.3604 40.1465L18.5504 38.6211Z" fill="#0020FF" />
      <path d="M40.5303 28.9131L42.6303 28.0117L42.9803 27.1797L40.8803 28.1504L40.5303 28.9131Z" fill="#29FFCE" />
      <path d="M31.5 28.2901L33.95 27.666L34.3 28.4981L31.85 29.1221L31.5 28.2901Z" fill="#29FFCE" />
      <path d="M38.7102 15.1153L38.0802 13.5898L37.4502 14.4219L38.1502 15.878L38.7102 15.1153Z" fill="#FFD000" />
      <path d="M33.3896 24.7539L35.2096 24.0605L35.2796 25.1006L33.5296 25.794L33.3896 24.7539Z" fill="#66FF90" />
      <path d="M44.4496 19.6915L44.7296 18.0967L44.3796 17.0566L44.0996 18.6514L44.4496 19.6915Z" fill="#D4FF23" />
      <path d="M42.8398 14.7686L42.1398 12.8965L41.5098 12.4805L42.2098 14.2832L42.8398 14.7686Z" fill="#FFB900" />
      <path d="M13.9299 1.31836L12.8799 1.80372L14.1399 3.0518L14.9099 2.56643L13.9299 1.31836Z" fill="#A80000" />
      <path d="M1.68043 29.9542L2.03043 31.1329L1.61043 28.4981L1.19043 27.25L1.68043 29.9542Z" fill="#19FFDE" />
      <path d="M19.5303 18.0273L20.1603 19.1367L20.4403 21.9796L19.8103 20.9395L19.5303 18.0273Z" fill="#C4FF33" />
      <path d="M16.7299 6.17188L16.3799 6.72657L17.2899 9.01471L17.4999 8.39067L16.7299 6.17188Z" fill="#FF3400" />
      <path d="M3.57047 35.9864L3.85047 36.8184L3.01047 34.5303L2.73047 33.6289L3.57047 35.9864Z" fill="#0080FF" />
      <path d="M39.4101 14.5606L38.7101 12.8965L38.0801 13.5899L38.7101 15.1153L39.4101 14.5606Z" fill="#FFC100" />
      <path d="M36.2598 31.3408L38.9898 30.5781L39.5498 30.1621L36.8198 30.9942L36.2598 31.3408Z" fill="#00E4F8" />
      <path d="M43.54 24.1993L44.8 22.9512V21.8418L43.54 23.1592V24.1993Z" fill="#87FF70" />
      <path d="M31.2197 27.3877L33.6697 26.7637L33.9497 27.6651L31.4997 28.2891L31.2197 27.3877Z" fill="#3CFFBA" />
      <path d="M37.38 18.0283L37.59 16.7109L37.17 17.751L36.96 18.9297L37.38 18.0283Z" fill="#E4FF13" />
      <path d="M42.2096 14.2842L41.5096 12.4814L40.8096 12.2734L41.5096 14.0762L42.2096 14.2842Z" fill="#FFB200" />
      <path d="M14.9096 2.56641L14.1396 3.05177L15.2596 4.71587L15.8896 4.16117L14.9096 2.56641Z" fill="#C80000" />
      <path d="M15.8898 4.16016L15.2598 4.71486L16.3798 6.72564L16.7298 6.17094L15.8898 4.16016Z" fill="#F10800" />
      <path d="M35.5596 20.9405L36.6096 19.9004L36.3296 21.0098L35.3496 21.9112L35.5596 20.9405Z" fill="#B1FF46" />
      <path d="M40.11 14.2139L39.41 12.4805L38.71 12.8965L39.41 14.5606L40.11 14.2139Z" fill="#FFB200" />
      <path d="M0.980469 11.9961L1.19047 13.5909L2.03047 11.3721L1.82047 9.77734L0.980469 11.9961Z" fill="#FF8D00" />
      <path d="M40.8799 28.1504L42.9799 27.1797L43.2599 26.209L41.1599 27.249L40.8799 28.1504Z" fill="#40FFB7" />
      <path d="M41.5104 14.0762L40.8104 12.2734H40.1104L40.8104 14.0762H41.5104Z" fill="#FFAB00" />
      <path d="M40.8102 14.0762L40.1102 12.2734L39.4102 12.4814L40.1102 14.2149L40.8102 14.0762Z" fill="#FFAE00" />
      <path d="M33.4596 23.7832L35.2096 22.9512V24.0606L33.3896 24.7539L33.4596 23.7832Z" fill="#7DFF7A" />
      <path d="M30.1699 31.6875L33.2499 30.9941L33.8799 31.3408L30.7299 32.0342L30.1699 31.6875Z" fill="#00DCFE" />
      <path d="M29.6797 31.1328L32.7597 30.4395L33.2497 30.9942L30.1697 31.6875L29.6797 31.1328Z" fill="#00E4F8" />
      <path d="M0.55957 23.0206L1.04957 24.546V21.8418L0.62957 20.3164L0.55957 23.0206Z" fill="#97FF60" />
      <path d="M44.0997 18.6524L44.3797 17.0576L43.9597 16.1562L43.6797 17.8203L44.0997 18.6524Z" fill="#EBFF0C" />
      <path d="M8.74961 43.4746L8.67961 43.6826L7.34961 42.5039V42.2266L8.74961 43.4746Z" fill="#0000ED" />
      <path d="M20.1596 33.0039L20.3696 34.46L19.5996 36.6788L19.3896 35.2227L20.1596 33.0039Z" fill="#009CFF" />
      <path d="M20.4404 21.9805L20.8604 23.3672V26.2101L20.4404 24.8233V21.9805Z" fill="#83FF73" />
      <path d="M30.7305 32.0332L33.8805 31.3398L34.4405 31.5479L31.2905 32.3106L30.7305 32.0332Z" fill="#00D4FF" />
      <path d="M7.34953 42.2266V42.5039L6.08953 41.0479L6.01953 40.7012L7.34953 42.2266Z" fill="#0000FF" />
      <path d="M18.0596 11.5801L18.3396 12.4815L19.0396 15.1856L18.6896 14.2842L18.0596 11.5801Z" fill="#FFA300" />
      <path d="M29.1904 30.5078L32.2704 29.8145L32.7604 30.4385L29.6804 31.1319L29.1904 30.5078Z" fill="#0CF4EB" />
      <path d="M36.8203 30.9248L39.5503 30.1621L40.0403 29.6074L37.3103 30.4395L36.8203 30.9248Z" fill="#0CF4EB" />
      <path d="M14.7702 44.0293L14.5602 44.584L13.1602 44.8613L13.2302 44.376L14.7702 44.0293Z" fill="#0000CD" />
      <path d="M31.0098 26.417L33.5298 25.793L33.6698 26.7637L31.2198 27.3877L31.0098 26.417Z" fill="#50FFA7" />
      <path d="M10.2197 44.3067L10.0797 44.5147L8.67969 43.6826L8.74969 43.4746L10.2197 44.3067Z" fill="#0000D6" />
      <path d="M37.9399 17.2657L38.1499 15.8789L37.5899 16.711L37.3799 18.0284L37.9399 17.2657Z" fill="#F8F500" />
      <path d="M31.29 32.3115L34.44 31.5488L35.07 31.6182L31.92 32.3809L31.29 32.3115Z" fill="#00D4FF" />
      <path d="M10.3599 0.625L9.37988 1.52639L10.8499 1.87307L11.6199 0.971687L10.3599 0.625Z" fill="#890000" />
      <path d="M28.8398 29.7451L31.8498 29.1211L32.2698 29.8145L29.1898 30.5078L28.8398 29.7451Z" fill="#19FFDE" />
      <path d="M17.4999 41.3242L17.4299 42.2256L16.1699 43.335V42.4336L17.4999 41.3242Z" fill="#0000FF" />
      <path d="M43.5404 23.1592L44.8004 21.8418L44.6604 20.7324L43.4004 22.1192L43.5404 23.1592Z" fill="#A0FF56" />
      <path d="M6.02008 40.7012L6.09008 41.0479L4.97008 39.3145L4.83008 38.8984L6.02008 40.7012Z" fill="#0024FF" />
      <path d="M3.15012 6.24027L2.87012 7.83503L4.13012 6.24027L4.41012 4.71484L3.15012 6.24027Z" fill="#FF1600" />
      <path d="M35.9099 20.0391L36.9599 18.9297L36.6099 19.9004L35.6299 20.9405L35.9099 20.0391Z" fill="#C7FF30" />
      <path d="M43.6804 17.8194L43.9604 16.1553L43.4004 15.3926L43.1904 17.0567L43.6804 17.8194Z" fill="#FEED00" />
      <path d="M41.1602 27.2491L43.2602 26.209L43.4702 25.2383L41.3702 26.2783L41.1602 27.2491Z" fill="#56FFA0" />
      <path d="M6.57957 2.2881L5.80957 3.60551L7.27957 2.91214L7.90957 1.66406L6.57957 2.2881Z" fill="#A80000" />
      <path d="M31.9199 32.4502L35.0699 31.6182L35.6999 31.5488L32.4799 32.3809L31.9199 32.4502Z" fill="#00D4FF" />
      <path d="M33.53 22.8115L35.35 21.9102L35.21 22.9502L33.46 23.7823L33.53 22.8115Z" fill="#97FF60" />
      <path d="M28.4902 28.9131L31.5002 28.2891L31.8502 29.1211L28.8402 29.7451L28.4902 28.9131Z" fill="#29FFCE" />
      <path d="M2.73027 33.629L3.01027 34.5303L2.38027 32.1035L2.03027 31.1328L2.73027 33.629Z" fill="#00C4FF" />
      <path d="M37.3096 30.4395L40.0396 29.6074L40.5296 28.9141L37.7296 29.8154L37.3096 30.4395Z" fill="#1CFFDB" />
      <path d="M11.6901 44.792L11.4801 44.9307L10.0801 44.5147L10.2201 44.3066L11.6901 44.792Z" fill="#0000CD" />
      <path d="M30.9404 25.4473L33.3904 24.7539L33.5304 25.794L31.0104 26.418L30.9404 25.4473Z" fill="#66FF90" />
      <path d="M38.5004 16.6407L38.7104 15.1152L38.1504 15.8779L37.9404 17.2647L38.5004 16.6407Z" fill="#FFDE00" />
      <path d="M0.55957 16.086L0.97957 17.6808L1.53957 15.2539L1.18957 13.5898L0.55957 16.086Z" fill="#FFD300" />
      <path d="M20.8601 26.209L21.0001 27.7344L20.7201 30.4386L20.5801 28.9131L20.8601 26.209Z" fill="#40FFB7" />
      <path d="M32.4805 32.3799L35.7005 31.5479L36.2605 31.3398L33.0405 32.1719L32.4805 32.3799Z" fill="#00DCFE" />
      <path d="M43.1896 17.0577L43.3996 15.3936L42.8396 14.7695L42.5596 16.503L43.1896 17.0577Z" fill="#FFDB00" />
      <path d="M43.4004 22.1182L44.6604 20.7315L44.4504 19.6914L43.1904 21.0782L43.4004 22.1182Z" fill="#B7FF40" />
      <path d="M19.04 15.1855L19.67 16.2256L20.16 19.1378L19.53 18.0284L19.04 15.1855Z" fill="#FFE600" />
      <path d="M4.82961 38.8985L4.96961 39.3145L3.98961 37.3037L3.84961 36.8184L4.82961 38.8985Z" fill="#0054FF" />
      <path d="M1.1898 27.2491L1.6098 28.4972L1.3998 25.9317L1.0498 24.5449L1.1898 27.2491Z" fill="#56FFA0" />
      <path d="M28.21 28.0127L31.22 27.3887L31.5 28.2901L28.49 28.9141L28.21 28.0127Z" fill="#3CFFBA" />
      <path d="M19.5998 36.6797V37.9278L18.6898 39.7999L18.5498 38.6211L19.5998 36.6797Z" fill="#005CFF" />
      <path d="M17.29 9.01367L17.5 9.84572L18.34 12.4805L18.06 11.5792L17.29 9.01367Z" fill="#FF6400" />
      <path d="M39.2 16.1553L39.41 14.5605L38.71 15.1152L38.5 16.6407L39.2 16.1553Z" fill="#FFD000" />
      <path d="M36.3302 19.2061L37.3802 18.0273L36.9602 18.9287L35.9102 20.0381L36.3302 19.2061Z" fill="#DEFF19" />
      <path d="M11.6196 0.970703L10.8496 1.87209L12.3196 2.6348L12.8796 1.80275L11.6196 0.970703Z" fill="#890000" />
      <path d="M41.3701 26.2793L43.4701 25.2393L43.5401 24.1992L41.4401 25.3086L41.3701 26.2793Z" fill="#6DFF8A" />
      <path d="M42.5597 16.4327L42.8397 14.7686L42.2097 14.2832L41.9297 16.0166L42.5597 16.4327Z" fill="#FFCC00" />
      <path d="M33.8103 21.9102L35.5603 20.9395L35.3503 21.9102L33.5303 22.8116L33.8103 21.9102Z" fill="#ADFF49" />
      <path d="M37.7305 29.8145L40.5305 28.9131L40.8805 28.1504L38.0805 29.0518L37.7305 29.8145Z" fill="#2CFFCA" />
      <path d="M30.9404 24.4766L33.4604 23.7832L33.3904 24.7539L30.9404 25.4473V24.4766Z" fill="#7DFF7A" />
      <path d="M27.02 32.7276L30.45 31.8955L29.96 31.4102L26.46 32.2422L27.02 32.7276Z" fill="#00DCFE" />
      <path d="M26.4597 32.2422L29.9597 31.4102L29.4697 30.8555L25.9697 31.6875L26.4597 32.2422Z" fill="#02E8F4" />
      <path d="M39.8302 15.8096L40.1102 14.2148L39.4102 14.5615L39.2002 16.1563L39.8302 15.8096Z" fill="#FFC400" />
      <path d="M16.1696 43.3359L15.9596 43.8213L14.5596 44.584L14.7696 44.0293L16.1696 43.3359Z" fill="#0000ED" />
      <path d="M33.04 32.1719L36.26 31.3399L36.82 30.9238L33.53 31.8252L33.04 32.1719Z" fill="#02E8F4" />
      <path d="M27.5795 33.0733L31.0095 32.2412L30.4495 31.8945L27.0195 32.7266L27.5795 33.0733Z" fill="#00D4FF" />
      <path d="M13.1605 44.8623L12.8805 45.001L11.4805 44.9316L11.6905 44.793L13.1605 44.8623Z" fill="#0000D1" />
      <path d="M41.9305 16.0176L42.2105 14.2842L41.5105 14.0762L41.2305 15.8096L41.9305 16.0176Z" fill="#FFC400" />
      <path d="M28.0703 27.1113L31.0103 26.418L31.2203 27.3887L28.2103 28.0127L28.0703 27.1113Z" fill="#53FFA4" />
      <path d="M25.9705 31.6875L29.4705 30.8555L28.9805 30.1621L25.4805 30.9248L25.9705 31.6875Z" fill="#0FF8E7" />
      <path d="M40.5301 15.7403L40.8101 14.0762L40.1101 14.2148L39.8301 15.8096L40.5301 15.7403Z" fill="#FFC100" />
      <path d="M43.1898 21.0792L44.4498 19.6924L44.0998 18.6523L42.8398 20.1778L43.1898 21.0792Z" fill="#CEFF29" />
      <path d="M41.2303 15.8096L41.5103 14.0762H40.8103L40.5303 15.7403L41.2303 15.8096Z" fill="#FFBD00" />
      <path d="M28.1401 33.2129L31.6401 32.3809L31.0101 32.2422L27.5801 33.0742L28.1401 33.2129Z" fill="#00D4FF" />
      <path d="M36.8901 18.5137L37.9401 17.2656L37.3801 18.0283L36.3301 19.2071L36.8901 18.5137Z" fill="#F1FC06" />
      <path d="M16.3799 6.72656V7.55861L17.4999 9.84675L17.2899 9.0147L16.3799 6.72656Z" fill="#FF2D00" />
      <path d="M25.4799 30.9248L28.9799 30.1621L28.6299 29.3301L25.1299 30.1621L25.4799 30.9248Z" fill="#1CFFDB" />
      <path d="M1.82031 9.77739L2.03031 11.3721L3.08031 9.4307L2.87031 7.83594L1.82031 9.77739Z" fill="#FF4E00" />
      <path d="M20.1602 19.1367L20.5802 20.4541L20.8602 23.3663L20.4402 21.9796L20.1602 19.1367Z" fill="#C4FF33" />
      <path d="M12.8803 1.80273L12.3203 2.63478L13.7903 3.88286L14.1403 3.05081L12.8803 1.80273Z" fill="#9B0000" />
      <path d="M0.629883 20.3165L1.04988 21.8419L1.39988 19.2764L0.979883 17.6816L0.629883 20.3165Z" fill="#D4FF23" />
      <path d="M3.84977 36.8174L3.98977 37.3028L3.21977 35.1533L3.00977 34.5293L3.84977 36.8174Z" fill="#008CFF" />
      <path d="M41.4404 25.3077L43.5404 24.1983V23.1582L41.4404 24.3369V25.3077Z" fill="#87FF70" />
      <path d="M28.6996 33.2822L32.1996 32.3809H31.6396L28.1396 33.2129L28.6996 33.2822Z" fill="#00D4FF" />
      <path d="M7.91027 1.66407L7.28027 2.91214L8.89027 2.70413L9.38027 1.52539L7.91027 1.66407Z" fill="#8D0000" />
      <path d="M34.0896 21.0098L35.9096 20.0391L35.6296 20.9404L33.8096 21.9112L34.0896 21.0098Z" fill="#C4FF33" />
      <path d="M38.0801 29.0528L40.8801 28.1514L41.1601 27.25L38.3601 28.2207L38.0801 29.0528Z" fill="#43FFB4" />
      <path d="M33.5303 31.8262L36.8203 30.9248L37.3103 30.4395L34.0203 31.3408L33.5303 31.8262Z" fill="#0FF8E7" />
      <path d="M18.6197 39.8008L18.5497 40.7022L17.4297 42.2276L17.4997 41.3262L18.6197 39.8008Z" fill="#0028FF" />
      <path d="M20.7202 30.4395L20.8602 31.9649L20.3702 34.461L20.1602 33.0049L20.7202 30.4395Z" fill="#02E8F4" />
      <path d="M31.0804 23.5752L33.5304 22.8125L33.4604 23.7832L30.9404 24.4766L31.0804 23.5752Z" fill="#94FF63" />
      <path d="M2.03035 31.1329L2.38035 32.1036L1.96035 29.6074L1.61035 28.498L2.03035 31.1329Z" fill="#1CFFDB" />
      <path d="M8.68027 43.6826L8.54027 43.544L7.28027 42.5039H7.35027L8.68027 43.6826Z" fill="#0000FF" />
      <path d="M28 26.1406L30.94 25.4473L31.01 26.418L28.07 27.1114L28 26.1406Z" fill="#66FF90" />
      <path d="M7.34984 42.503H7.27984L6.08984 41.1162V41.0469L7.34984 42.503Z" fill="#0010FF" />
      <path d="M25.1296 30.1621L28.6296 29.3301L28.3496 28.498L24.8496 29.2608L25.1296 30.1621Z" fill="#30FFC7" />
      <path d="M42.8399 20.1778L44.0999 18.6524L43.6799 17.8203L42.4199 19.3457L42.8399 20.1778Z" fill="#E4FF13" />
      <path d="M15.2601 4.71484L15.1201 5.54689L16.3801 7.55768V6.72563L15.2601 4.71484Z" fill="#E80000" />
      <path d="M14.14 3.05078L13.79 3.88283L15.12 5.54693L15.26 4.71488L14.14 3.05078Z" fill="#BB0000" />
      <path d="M4.40988 4.71487L4.12988 6.24029L5.59988 5.13089L5.80988 3.60547L4.40988 4.71487Z" fill="#D10000" />
      <path d="M37.4496 17.959L38.4996 16.5723L37.9396 17.2656L36.8896 18.5137L37.4496 17.959Z" fill="#FEED00" />
      <path d="M10.08 44.5137L9.87004 44.3057L8.54004 43.543L8.68004 43.6816L10.08 44.5137Z" fill="#0000F1" />
      <path d="M18.3398 12.4805L18.8998 13.5205L19.6698 16.2247L19.0398 15.1846L18.3398 12.4805Z" fill="#FF9F00" />
      <path d="M29.3302 33.1426L32.7602 32.3105L32.2002 32.3799L28.7002 33.2813L29.3302 33.1426Z" fill="#00D8FF" />
      <path d="M6.08973 41.0479V41.1172L5.03973 39.4531L4.96973 39.3145L6.08973 41.0479Z" fill="#0038FF" />
      <path d="M14.5599 44.584L14.2799 44.7227L12.8799 45L13.1599 44.8613L14.5599 44.584Z" fill="#0000E3" />
      <path d="M41.4398 24.3379L43.5398 23.1592L43.3998 22.1191L41.2998 23.2979L41.4398 24.3379Z" fill="#9DFF5A" />
      <path d="M24.8503 29.2608L28.3503 28.4981L28.1403 27.5273L24.5703 28.2901L24.8503 29.2608Z" fill="#43FFB4" />
      <path d="M42.4197 19.3448L43.6797 17.8194L43.1897 17.0566L41.9297 18.5821L42.4197 19.3448Z" fill="#F4F802" />
      <path d="M34.0195 31.3399L37.3095 30.4385L37.7295 29.8145L34.3695 30.7158L34.0195 31.3399Z" fill="#1FFFD7" />
      <path d="M34.5098 20.2471L36.3298 19.207L35.9098 20.0391L34.0898 21.0098L34.5098 20.2471Z" fill="#D7FF1F" />
      <path d="M23.4496 33.6289L27.0196 32.7275L26.4596 32.2422L22.8896 33.1436L23.4496 33.6289Z" fill="#00D8FF" />
      <path d="M38.3604 28.2207L41.1604 27.25L41.3704 26.2793L38.5004 27.3194L38.3604 28.2207Z" fill="#56FFA0" />
      <path d="M22.8901 33.1436L26.4601 32.2422L25.9701 31.6875L22.3301 32.5195L22.8901 33.1436Z" fill="#00E4F8" />
      <path d="M38.0802 17.543L39.2002 16.1562L38.5002 16.6416L37.4502 17.959L38.0802 17.543Z" fill="#FFDE00" />
      <path d="M28 25.1699L30.94 24.4766V25.4473L28 26.1407V25.1699Z" fill="#7DFF7A" />
      <path d="M31.2901 22.6738L33.8101 21.8418L33.5301 22.8125L31.0801 23.5752L31.2901 22.6738Z" fill="#AAFF4D" />
      <path d="M11.4801 44.9307L11.2001 44.792L9.87012 44.3066L10.0801 44.5147L11.4801 44.9307Z" fill="#0000E8" />
      <path d="M24.0102 33.9746L27.5802 33.0732L27.0202 32.7266L23.4502 33.6279L24.0102 33.9746Z" fill="#00D0FF" />
      <path d="M1.0498 24.546L1.3998 25.9327L1.4698 23.2979L1.0498 21.8418V24.546Z" fill="#94FF63" />
      <path d="M22.3298 32.5186L25.9698 31.6865L25.4798 30.9238L21.8398 31.7559L22.3298 32.5186Z" fill="#0CF4EB" />
      <path d="M29.8201 32.7969L33.2501 31.9648L32.7601 32.3115L29.3301 33.1436L29.8201 32.7969Z" fill="#00E4F8" />
      <path d="M20.8604 23.3672L21.0004 24.8926V27.7354L20.8604 26.21V23.3672Z" fill="#83FF73" />
      <path d="M1.19043 13.5899L1.54043 15.254L2.38043 13.0352L2.03043 11.3711L1.19043 13.5899Z" fill="#FF9100" />
      <path d="M17.43 42.2266L17.15 42.7813L15.96 43.8213L16.17 43.336L17.43 42.2266Z" fill="#0008FF" />
      <path d="M41.9301 18.5831L43.1901 17.0576L42.5601 16.4336L41.3701 18.0284L41.9301 18.5831Z" fill="#FFE600" />
      <path d="M24.6398 34.1836L28.1398 33.2129L27.5798 33.0742L24.0098 33.9756L24.6398 34.1836Z" fill="#00CCFF" />
      <path d="M20.3696 34.4609V35.709L19.5996 37.9278V36.6797L20.3696 34.4609Z" fill="#00A0FF" />
      <path d="M3.00988 34.5303L3.21988 35.1544L2.58988 32.8662L2.37988 32.1035L3.00988 34.5303Z" fill="#00CCFF" />
      <path d="M38.7101 17.2647L39.8301 15.8086L39.2001 16.1553L38.0801 17.542L38.7101 17.2647Z" fill="#FFD300" />
      <path d="M4.97023 39.3135L5.04023 39.4522L4.13023 37.5801L3.99023 37.3027L4.97023 39.3135Z" fill="#0064FF" />
      <path d="M21.8402 31.7569L25.4802 30.9248L25.1302 30.1621L21.4902 30.9248L21.8402 31.7569Z" fill="#1CFFDB" />
      <path d="M24.57 28.2891L28.14 27.5264L28 26.625L24.5 27.3184L24.57 28.2891Z" fill="#5AFF9D" />
      <path d="M9.37965 1.52539L8.88965 2.70413L10.5696 2.98148L10.8496 1.87208L9.37965 1.52539Z" fill="#800000" />
      <path d="M41.2998 23.2969L43.3998 22.1182L43.1898 21.0781L41.0898 22.3262L41.2998 23.2969Z" fill="#B4FF43" />
      <path d="M41.3702 18.0284L42.5602 16.4336L41.9302 16.0176L40.7402 17.6123L41.3702 18.0284Z" fill="#FFDB00" />
      <path d="M34.9998 19.6231L36.8898 18.5137L36.3298 19.207L34.5098 20.2471L34.9998 19.6231Z" fill="#E7FF0F" />
      <path d="M39.41 17.1963L40.53 15.7402L39.83 15.8096L38.71 17.2657L39.41 17.1963Z" fill="#FFD000" />
      <path d="M25.1996 34.1836L28.6996 33.2822L28.1396 33.2129L24.6396 34.1836H25.1996Z" fill="#00D0FF" />
      <path d="M34.3701 30.7168L37.7301 29.8154L38.0801 29.0527L34.7201 29.9541L34.3701 30.7168Z" fill="#30FFC7" />
      <path d="M40.7404 17.6114L41.9304 16.0166L41.2304 15.8086L40.1104 17.334L40.7404 17.6114Z" fill="#FFD000" />
      <path d="M40.1102 17.335L41.2302 15.8096L40.5302 15.7402L39.4102 17.1963L40.1102 17.335Z" fill="#FFCC00" />
      <path d="M38.5 27.3194L41.37 26.2793L41.44 25.3086L38.57 26.3487L38.5 27.3194Z" fill="#6DFF8A" />
      <path d="M28.07 24.2676L31.08 23.5742L30.94 24.4756L28 25.169L28.07 24.2676Z" fill="#94FF63" />
      <path d="M31.57 21.9112L34.09 21.0098L33.81 21.9112L31.29 22.6739L31.57 21.9112Z" fill="#C1FF36" />
      <path d="M30.3103 32.3809L33.7403 31.5488L33.2503 31.9649L29.8203 32.7969L30.3103 32.3809Z" fill="#0CF4EB" />
      <path d="M21.4896 30.9239L25.1296 30.1612L24.8496 29.2598L21.1396 30.0225L21.4896 30.9239Z" fill="#2CFFCA" />
      <path d="M17.5 9.8457L17.92 10.8858L18.9 13.5206L18.34 12.4805L17.5 9.8457Z" fill="#FF6000" />
      <path d="M19.6699 16.2246L20.0199 17.6114L20.5799 20.4542L20.1599 19.1368L19.6699 16.2246Z" fill="#FFE600" />
      <path d="M12.8802 44.999L12.5302 44.8604L11.2002 44.791L11.4802 44.9297L12.8802 44.999Z" fill="#0000ED" />
      <path d="M1.61039 28.4971L1.96039 29.6065L1.75039 27.1104L1.40039 25.9316L1.61039 28.4971Z" fill="#56FFA0" />
      <path d="M24.4997 27.3184L27.9997 26.625L27.9297 25.6543L24.4297 26.3477L24.4997 27.3184Z" fill="#6DFF8A" />
      <path d="M25.8302 34.044L29.3302 33.1426L28.7002 33.2119L25.2002 34.1826L25.8302 34.044Z" fill="#00D4FF" />
      <path d="M2.87012 7.83597L3.08012 9.43073L4.34012 7.90531L4.13012 6.31055L2.87012 7.83597Z" fill="#FF1A00" />
      <path d="M41.0902 22.3272L43.1902 21.0791L42.8402 20.1777L40.7402 21.4258L41.0902 22.3272Z" fill="#CAFF2C" />
      <path d="M8.53996 43.543L8.39996 43.127L7.20996 42.1562L7.27996 42.5029L8.53996 43.543Z" fill="#000CFF" />
      <path d="M15.9603 43.8203L15.5403 44.0283L14.2803 44.7217L14.5603 44.583L15.9603 43.8203Z" fill="#0000FF" />
      <path d="M19.9496 34.668L23.4496 33.6279L22.8896 33.1426L19.3896 34.1133L19.9496 34.668Z" fill="#00D0FF" />
      <path d="M19.3901 34.1143L22.8901 33.1436L22.3301 32.5195L18.8301 33.4903L19.3901 34.1143Z" fill="#00DCFE" />
      <path d="M7.27984 42.5039L7.20984 42.1573L6.08984 40.8398V41.1172L7.27984 42.5039Z" fill="#0028FF" />
      <path d="M35.56 19.1377L37.45 17.959L36.89 18.5137L35 19.6231L35.56 19.1377Z" fill="#F8F500" />
      <path d="M5.80961 3.60548L5.59961 5.13091L7.13961 4.43753L7.27961 2.91211L5.80961 3.60548Z" fill="#A40000" />
      <path d="M20.5802 35.0157L24.0102 33.9756L23.4502 33.6289L19.9502 34.669L20.5802 35.0157Z" fill="#00C8FF" />
      <path d="M19.5998 37.9277L19.5298 38.8985L18.5498 40.7012L18.6198 39.7998L19.5998 37.9277Z" fill="#0064FF" />
      <path d="M0.980469 17.6807L1.40047 19.2755L1.96047 16.918L1.54047 15.2539L0.980469 17.6807Z" fill="#FFD700" />
      <path d="M3.98973 37.3038L4.12973 37.5811L3.35973 35.501L3.21973 35.1543L3.98973 37.3038Z" fill="#0098FF" />
      <path d="M21.1397 30.0225L24.8497 29.2598L24.6397 28.2891L20.9297 29.0518L21.1397 30.0225Z" fill="#43FFB4" />
      <path d="M18.8298 33.4893L22.3298 32.5186L21.8398 31.7559L18.3398 32.6572L18.8298 33.4893Z" fill="#06ECF1" />
      <path d="M9.87039 44.3076L9.59039 43.8916L8.40039 43.1289L8.54039 43.5449L9.87039 44.3076Z" fill="#0000FF" />
      <path d="M34.7197 29.9541L38.0797 29.0528L38.3597 28.2207L34.9997 29.1221L34.7197 29.9541Z" fill="#43FFB4" />
      <path d="M38.5703 26.3487L41.4403 25.3086V24.3379L38.5703 25.378V26.3487Z" fill="#83FF73" />
      <path d="M10.8503 1.87109L10.5703 2.98049L12.1803 3.7432L12.3203 2.63381L10.8503 1.87109Z" fill="#840000" />
      <path d="M30.7296 31.8262L34.1596 30.9941L33.7396 31.5488L30.3096 32.3809L30.7296 31.8262Z" fill="#19FFDE" />
      <path d="M20.9997 27.7344L21.1397 29.2598L20.8597 31.964L20.7197 30.5079L20.9997 27.7344Z" fill="#40FFB7" />
      <path d="M21.2101 35.2227L24.6401 34.1826L24.0101 33.9746L20.5801 35.0147L21.2101 35.2227Z" fill="#00C4FF" />
      <path d="M31.9903 21.1475L34.5103 20.2461L34.0903 21.0088L31.5703 21.9102L31.9903 21.1475Z" fill="#D4FF23" />
      <path d="M28.2803 23.3672L31.2903 22.6738L31.0803 23.5752L28.0703 24.2686L28.2803 23.3672Z" fill="#AAFF4D" />
      <path d="M6.09004 41.1172V40.8399L5.11004 39.3145L5.04004 39.4531L6.09004 41.1172Z" fill="#004CFF" />
      <path d="M26.3901 33.7676L29.8201 32.7969L29.3301 33.1436L25.8301 34.0449L26.3901 33.7676Z" fill="#00E0FB" />
      <path d="M18.3396 32.6573L21.8396 31.7559L21.4896 30.9238L17.8496 31.7559L18.3396 32.6573Z" fill="#16FFE1" />
      <path d="M40.7403 21.4258L42.8403 20.1778L42.4203 19.3457L40.3203 20.6631L40.7403 21.4258Z" fill="#DEFF19" />
      <path d="M16.3799 7.55859L16.6599 8.59866L17.9199 10.8868L17.4999 9.84673L16.3799 7.55859Z" fill="#FF2900" />
      <path d="M36.1196 18.791L38.0796 17.543L37.4496 17.959L35.5596 19.1377L36.1196 18.791Z" fill="#FFEA00" />
      <path d="M24.4297 26.3477L27.9297 25.6543L27.9997 24.6836L24.4997 25.4463L24.4297 26.3477Z" fill="#83FF73" />
      <path d="M2.37996 32.1036L2.58996 32.8663L2.16996 30.5088L1.95996 29.6074L2.37996 32.1036Z" fill="#1FFFD7" />
      <path d="M11.1998 44.792L10.8498 44.2373L9.58984 43.8906L9.86984 44.3066L11.1998 44.792Z" fill="#0000FF" />
      <path d="M21.84 35.2237L25.2 34.1836H24.64L21.21 35.2237H21.84Z" fill="#00C4FF" />
      <path d="M20.93 29.0518L24.57 28.2891L24.5 27.3184L20.79 28.0117L20.93 29.0518Z" fill="#56FFA0" />
      <path d="M14.2803 44.7227L13.7903 44.584L12.5303 44.8613L12.8803 45L14.2803 44.7227Z" fill="#0000FF" />
      <path d="M17.8503 31.7569L21.4903 30.9248L21.1403 30.0234L17.5703 30.7861L17.8503 31.7569Z" fill="#29FFCE" />
      <path d="M40.3201 20.6622L42.4201 19.3447L41.9301 18.582L39.8301 19.9688L40.3201 20.6622Z" fill="#EEFF09" />
      <path d="M12.3197 2.63477L12.1797 3.74416L13.7897 4.9229V3.88284L12.3197 2.63477Z" fill="#920000" />
      <path d="M18.5504 40.7012L18.2704 41.3252L17.1504 42.7813L17.4304 42.2266L18.5504 40.7012Z" fill="#0038FF" />
      <path d="M36.7501 18.583L38.7101 17.2656L38.0801 17.543L36.1201 18.791L36.7501 18.583Z" fill="#FFDE00" />
      <path d="M38.5697 25.377L41.4397 24.3369L41.2997 23.2969L38.4297 24.4063L38.5697 25.377Z" fill="#9AFF5D" />
      <path d="M5.03988 39.4532L5.10988 39.3145L4.26988 37.5117L4.12988 37.5811L5.03988 39.4532Z" fill="#0074FF" />
      <path d="M35 29.1211L38.36 28.2197L38.5 27.3184L35.14 28.2891L35 29.1211Z" fill="#5AFF9D" />
      <path d="M16.7999 35.708L19.9499 34.668L19.3899 34.1133L16.1699 35.1533L16.7999 35.708Z" fill="#00C4FF" />
      <path d="M16.17 35.1543L19.39 34.1143L18.83 33.4902L15.54 34.461L16.17 35.1543Z" fill="#00D0FF" />
      <path d="M1.0498 21.8409L1.4698 23.297L1.8198 20.8008L1.3998 19.2754L1.0498 21.8409Z" fill="#D1FF26" />
      <path d="M32.4102 20.5938L35.0002 19.623L34.5102 20.2471L31.9902 21.1485L32.4102 20.5938Z" fill="#E4FF13" />
      <path d="M15.1201 5.54688L15.3301 6.58694L16.6601 8.59772L16.3801 7.55766L15.1201 5.54688Z" fill="#DF0000" />
      <path d="M31.1505 31.1319L34.5805 30.3691L34.1605 30.9932L30.7305 31.8252L31.1505 31.1319Z" fill="#29FFCE" />
      <path d="M20.5801 20.4551L20.7201 22.0498L21.0001 24.8927L20.8601 23.3673L20.5801 20.4551Z" fill="#C4FF33" />
      <path d="M17.4998 36.1241L20.5798 35.0147L19.9498 34.668L16.7998 35.708L17.4998 36.1241Z" fill="#00BCFF" />
      <path d="M26.8796 33.2822L30.3096 32.3809L29.8196 32.8662L26.3896 33.7676L26.8796 33.2822Z" fill="#09F0EE" />
      <path d="M28.5603 22.6035L31.5703 21.9102L31.2903 22.6729L28.2803 23.3662L28.5603 22.6035Z" fill="#BEFF39" />
      <path d="M13.79 3.88281V4.92287L15.33 6.58697L15.12 5.54691L13.79 3.88281Z" fill="#B20000" />
      <path d="M22.4698 35.085L25.8298 34.0449L25.1998 34.1836L21.8398 35.2237L22.4698 35.085Z" fill="#00CCFF" />
      <path d="M15.5405 34.461L18.8305 33.4903L18.3405 32.6582L14.9805 33.6289L15.5405 34.461Z" fill="#00E0FB" />
      <path d="M39.8295 19.9688L41.9295 18.582L41.3695 18.0273L39.2695 19.3448L39.8295 19.9688Z" fill="#FBF100" />
      <path d="M2.03027 11.3711L2.38027 13.0352L3.43027 11.1631L3.08027 9.42969L2.03027 11.3711Z" fill="#FF5200" />
      <path d="M37.38 18.5127L39.41 17.1953L38.71 17.2646L36.75 18.5821L37.38 18.5127Z" fill="#FFDB00" />
      <path d="M18.9004 13.5215L19.2504 14.8389L20.0204 17.6124L19.6704 16.2256L18.9004 13.5215Z" fill="#FF9F00" />
      <path d="M3.21984 35.1534L3.35984 35.5001L2.79984 33.3506L2.58984 32.8652L3.21984 35.1534Z" fill="#00D4FF" />
      <path d="M8.39996 43.128L8.32996 42.4346L7.27996 41.5332L7.20996 42.1572L8.39996 43.128Z" fill="#0028FF" />
      <path d="M18.13 36.333L21.21 35.2236L20.58 35.0156L17.5 36.125L18.13 36.333Z" fill="#00B8FF" />
      <path d="M24.5 25.4473L28 24.6846L28.14 23.7832L24.64 24.5459L24.5 25.4473Z" fill="#9AFF5D" />
      <path d="M20.8601 31.9648L20.9301 33.2823L20.3701 35.7091V34.461L20.8601 31.9648Z" fill="#02E8F4" />
      <path d="M7.20984 42.1573L7.27984 41.5332L6.22984 40.2852L6.08984 40.8399L7.20984 42.1573Z" fill="#0040FF" />
      <path d="M20.7897 28.0811L24.4997 27.3184L24.4297 26.3477L20.7197 27.041L20.7897 28.0811Z" fill="#6DFF8A" />
      <path d="M17.57 30.7862L21.14 30.0235L20.93 29.0527L17.29 29.8154L17.57 30.7862Z" fill="#40FFB7" />
      <path d="M39.27 19.3448L41.37 18.0274L40.74 17.6113L38.71 18.9287L39.27 19.3448Z" fill="#FFE600" />
      <path d="M38.0799 18.6514L40.1099 17.334L39.4099 17.1953L37.3799 18.5127L38.0799 18.6514Z" fill="#FFD700" />
      <path d="M12.5296 44.8623L12.1096 44.3076L10.8496 44.2383L11.1996 44.793L12.5296 44.8623Z" fill="#0000FF" />
      <path d="M38.7101 18.9287L40.7401 17.6113L40.1101 17.334L38.0801 18.6514L38.7101 18.9287Z" fill="#FFDB00" />
      <path d="M14.9796 33.628L18.3396 32.6572L17.8496 31.7559L14.5596 32.6572L14.9796 33.628Z" fill="#0FF8E7" />
      <path d="M9.59008 43.8897L9.52008 43.127L8.33008 42.4336L8.40008 43.127L9.59008 43.8897Z" fill="#0018FF" />
      <path d="M7.27965 2.91114L7.13965 4.43656L8.81965 4.15921L8.88965 2.70312L7.27965 2.91114Z" fill="#890000" />
      <path d="M17.15 42.7812L16.73 43.0586L15.54 44.0293L15.96 43.8213L17.15 42.7812Z" fill="#0018FF" />
      <path d="M38.4297 24.4063L41.2997 23.2969L41.0897 22.3262L38.2197 23.5049L38.4297 24.4063Z" fill="#B1FF46" />
      <path d="M32.9702 20.1074L35.5602 19.1367L35.0002 19.6221L32.4102 20.5928L32.9702 20.1074Z" fill="#F1FC06" />
      <path d="M6.09035 40.8399L6.23035 40.2852L5.32035 38.8984L5.11035 39.3145L6.09035 40.8399Z" fill="#0060FF" />
      <path d="M35.1396 28.2891L38.4996 27.3184L38.5696 26.3477L35.2096 27.3184L35.1396 28.2891Z" fill="#6DFF8A" />
      <path d="M18.8299 36.4014L21.8399 35.2227H21.2099L18.1299 36.3321L18.8299 36.4014Z" fill="#00B8FF" />
      <path d="M1.40039 25.9317L1.75039 27.1104L1.82039 24.6143L1.47039 23.2969L1.40039 25.9317Z" fill="#94FF63" />
      <path d="M23.0997 34.8076L26.3897 33.7676L25.8297 34.0449L22.4697 35.085L23.0997 34.8076Z" fill="#00D8FF" />
      <path d="M14.0701 36.8877L16.8001 35.709L16.1701 35.1543L13.3701 36.2637L14.0701 36.8877Z" fill="#00B4FF" />
      <path d="M31.4304 30.3692L34.8604 29.5371L34.5804 30.3692L31.1504 31.1319L31.4304 30.3692Z" fill="#3CFFBA" />
      <path d="M27.2999 32.7276L30.7299 31.8262L30.3099 32.3809L26.8799 33.2823L27.2999 32.7276Z" fill="#16FFE1" />
      <path d="M13.3702 36.2637L16.1702 35.1543L15.5402 34.4609L12.7402 35.501L13.3702 36.2637Z" fill="#00C0FF" />
      <path d="M4.13035 37.5801L4.27035 37.5108L3.57035 35.6387L3.36035 35.5L4.13035 37.5801Z" fill="#00A8FF" />
      <path d="M28.9096 21.9111L31.9896 21.1484L31.5696 21.9111L28.5596 22.6045L28.9096 21.9111Z" fill="#D1FF26" />
      <path d="M4.12988 6.24026L4.33988 7.90436L5.73988 6.72562L5.59988 5.13086L4.12988 6.24026Z" fill="#D10000" />
      <path d="M14.8403 37.3037L17.5003 36.125L16.8003 35.709L14.0703 36.8877L14.8403 37.3037Z" fill="#00A8FF" />
      <path d="M14.5596 32.6573L17.8496 31.7559L17.5696 30.7852L14.1396 31.6172L14.5596 32.6573Z" fill="#23FFD4" />
      <path d="M10.8495 44.2383L10.6395 43.4756L9.51953 43.1289L9.58953 43.8916L10.8495 44.2383Z" fill="#0010FF" />
      <path d="M12.7404 35.501L15.5404 34.461L14.9804 33.6289L12.1104 34.5996L12.7404 35.501Z" fill="#00D4FF" />
      <path d="M17.2904 29.7461L20.9304 29.0528L20.7904 28.082L17.1504 28.7061L17.2904 29.7461Z" fill="#56FFA0" />
      <path d="M15.54 44.0293L15.05 43.8906L13.79 44.584L14.28 44.7227L15.54 44.0293Z" fill="#0008FF" />
      <path d="M20.7197 27.042L24.4297 26.3487L24.4997 25.4473L20.7897 26.0713L20.7197 27.042Z" fill="#83FF73" />
      <path d="M24.6396 24.5459L28.1396 23.7832L28.4196 22.9512L24.8496 23.6445L24.6396 24.5459Z" fill="#B1FF46" />
      <path d="M38.2201 23.5059L41.0901 22.3272L40.7401 21.4258L37.8701 22.6045L38.2201 23.5059Z" fill="#C4FF33" />
      <path d="M15.5398 37.5811L18.1298 36.333L17.4998 36.125L14.8398 37.3037L15.5398 37.5811Z" fill="#00A8FF" />
      <path d="M33.5297 19.7617L36.1197 18.791L35.5597 19.1377L32.9697 20.1084L33.5297 19.7617Z" fill="#FEED00" />
      <path d="M20.3703 35.709L20.2303 36.749L19.5303 38.8985L19.6003 37.9278L20.3703 35.709Z" fill="#00A8FF" />
      <path d="M19.5301 36.1934L22.4701 35.084L21.8401 35.2227L18.8301 36.4014L19.5301 36.1934Z" fill="#00C0FF" />
      <path d="M8.33027 42.4346L8.61027 41.5332L7.56027 40.7012L7.28027 41.5332L8.33027 42.4346Z" fill="#0040FF" />
      <path d="M5.10953 39.3145L5.31953 38.8985L4.47953 37.2344L4.26953 37.5117L5.10953 39.3145Z" fill="#0088FF" />
      <path d="M1.96 29.6075L2.17 30.5089L2.03 28.0821L1.75 27.1113L1.96 29.6075Z" fill="#5AFF9D" />
      <path d="M7.28047 41.5332L7.56047 40.7012L6.65047 39.5918L6.23047 40.2852L7.28047 41.5332Z" fill="#0058FF" />
      <path d="M1.54004 15.254L1.96004 16.9181L2.80004 14.7686L2.38004 13.0352L1.54004 15.254Z" fill="#FF9400" />
      <path d="M12.1101 34.5997L14.9801 33.6289L14.5601 32.6582L11.6201 33.5596L12.1101 34.5997Z" fill="#06ECF1" />
      <path d="M11.8999 38.1358L14.0699 36.8877L13.3699 36.2637L11.1299 37.4424L11.8999 38.1358Z" fill="#00A0FF" />
      <path d="M23.5896 34.3213L26.8796 33.2812L26.3896 33.7666L23.0996 34.7373L23.5896 34.3213Z" fill="#02E8F4" />
      <path d="M11.1304 37.4414L13.3704 36.2627L12.7404 35.5L10.3604 36.6094L11.1304 37.4414Z" fill="#00B0FF" />
      <path d="M21 24.8926L21.14 26.4873V29.2608L21 27.7354V24.8926Z" fill="#83FF73" />
      <path d="M9.52008 43.128L9.66008 42.1572L8.61008 41.5332L8.33008 42.4346L9.52008 43.128Z" fill="#0034FF" />
      <path d="M35.2096 27.3184L38.5696 26.3477V25.377L35.1396 26.3477L35.2096 27.3184Z" fill="#83FF73" />
      <path d="M13.7904 44.583L13.3004 44.0977L12.1104 44.3057L12.5304 44.8604L13.7904 44.583Z" fill="#0008FF" />
      <path d="M14.1397 31.6182L17.5697 30.7862L17.2897 29.7461L13.9297 30.5088L14.1397 31.6182Z" fill="#39FFBE" />
      <path d="M17.9199 10.8867L18.1999 12.2735L19.2499 14.839L18.8999 13.5215L17.9199 10.8867Z" fill="#FF6000" />
      <path d="M12.6704 38.5508L14.8404 37.3027L14.0704 36.8867L11.9004 38.1348L12.6704 38.5508Z" fill="#0094FF" />
      <path d="M29.3302 21.3565L32.4102 20.5938L31.9902 21.1484L28.9102 21.9112L29.3302 21.3565Z" fill="#E1FF16" />
      <path d="M27.7198 32.0342L31.1498 31.1328L30.7298 31.8262L27.2998 32.7276L27.7198 32.0342Z" fill="#29FFCE" />
      <path d="M31.6397 29.4678L35.0697 28.7051L34.8597 29.6065L31.4297 30.3692L31.6397 29.4678Z" fill="#53FFA4" />
      <path d="M6.23031 40.2842L6.65031 39.5908L5.74031 38.2734L5.32031 38.8975L6.23031 40.2842Z" fill="#0074FF" />
      <path d="M16.31 37.5801L18.83 36.4014L18.13 36.332L15.54 37.5801H16.31Z" fill="#00A8FF" />
      <path d="M37.8695 22.6036L40.7395 21.4248L40.3195 20.6621L37.5195 21.7715L37.8695 22.6036Z" fill="#D7FF1F" />
      <path d="M10.3605 36.6104L12.7405 35.501L12.1105 34.5996L9.73047 35.5703L10.3605 36.6104Z" fill="#00C4FF" />
      <path d="M34.0903 19.6221L36.7503 18.582L36.1203 18.79L33.5303 19.7608L34.0903 19.6221Z" fill="#FFE600" />
      <path d="M2.58992 32.8653L2.79992 33.3506L2.37992 31.0625L2.16992 30.5078L2.58992 32.8653Z" fill="#26FFD1" />
      <path d="M12.1096 44.3067L11.8296 43.5439L10.6396 43.4746L10.8496 44.2373L12.1096 44.3067Z" fill="#0014FF" />
      <path d="M17.1501 28.7051L20.7901 28.0811L20.7201 27.041L17.0801 27.6651L17.1501 28.7051Z" fill="#6DFF8A" />
      <path d="M8.89031 2.70312L8.82031 4.15921L10.5003 4.43656V2.98047L8.89031 2.70312Z" fill="#800000" />
      <path d="M8.60957 41.5323L9.16957 40.4922L8.25957 39.6602L7.55957 40.7002L8.60957 41.5323Z" fill="#005CFF" />
      <path d="M10.2899 39.3135L11.8999 38.1348L11.1299 37.4414L9.37988 38.6201L10.2899 39.3135Z" fill="#008CFF" />
      <path d="M7.56039 40.7002L8.26039 39.6602L7.42039 38.6895L6.65039 39.5908L7.56039 40.7002Z" fill="#0070FF" />
      <path d="M11.6202 33.5586L14.5602 32.6572L14.1402 31.6172L11.2002 32.3799L11.6202 33.5586Z" fill="#1CFFDB" />
      <path d="M9.38035 38.6202L11.1304 37.4414L10.3604 36.6094L8.61035 37.6494L9.38035 38.6202Z" fill="#009CFF" />
      <path d="M13.5099 38.8282L15.5399 37.5801L14.8399 37.3027L12.6699 38.5508L13.5099 38.8282Z" fill="#0094FF" />
      <path d="M10.6395 43.4737L10.7095 42.5029L9.65953 42.1562L9.51953 43.127L10.6395 43.4737Z" fill="#002CFF" />
      <path d="M20.0195 17.6113L20.2295 19.2061L20.7195 22.0489L20.5795 20.4542L20.0195 17.6113Z" fill="#FFE600" />
      <path d="M20.1603 35.8467L23.1003 34.8066L22.4703 35.084L19.5303 36.1934L20.1603 35.8467Z" fill="#00CCFF" />
      <path d="M19.5295 38.8984L19.1795 39.5918L18.2695 41.3252L18.5495 40.7012L19.5295 38.8984Z" fill="#0070FF" />
      <path d="M20.79 26.0703L24.5 25.4463L24.64 24.5449L20.93 25.0996L20.79 26.0703Z" fill="#9AFF5D" />
      <path d="M24.8496 23.6446L28.4196 22.9512L28.6996 22.2578L25.1996 22.9512L24.8496 23.6446Z" fill="#C4FF33" />
      <path d="M9.16977 40.4932L10.2898 39.3145L9.37977 38.6211L8.25977 39.6612L9.16977 40.4932Z" fill="#0074FF" />
      <path d="M11.13 39.8682L12.67 38.5508L11.9 38.1348L10.29 39.3135L11.13 39.8682Z" fill="#0080FF" />
      <path d="M9.66035 42.1563L10.1504 41.0469L9.17035 40.4922L8.61035 41.5322L9.66035 42.1563Z" fill="#004CFF" />
      <path d="M3.3598 35.501L3.5698 35.6397L3.0098 33.5596L2.7998 33.3516L3.3598 35.501Z" fill="#00E0FB" />
      <path d="M37.5203 21.7715L40.3203 20.6621L39.8303 19.9688L37.0303 21.0781L37.5203 21.7715Z" fill="#E7FF0F" />
      <path d="M8.25992 39.6612L9.37992 38.6211L8.60992 37.6504L7.41992 38.6905L8.25992 39.6612Z" fill="#0084FF" />
      <path d="M34.7198 19.6231L37.3798 18.5137L36.7498 18.583L34.0898 19.6231H34.7198Z" fill="#FFE200" />
      <path d="M9.72992 35.5694L12.1099 34.5987L11.6199 33.5586L9.16992 34.46L9.72992 35.5694Z" fill="#00E0FB" />
      <path d="M8.61016 37.6504L10.3602 36.6104L9.73016 35.5703L7.91016 36.541L8.61016 37.6504Z" fill="#00B4FF" />
      <path d="M6.65023 39.5909L7.42023 38.6895L6.58023 37.4414L5.74023 38.2735L6.65023 39.5909Z" fill="#008CFF" />
      <path d="M4.27031 37.5118L4.48031 37.2344L3.85031 35.4316L3.57031 35.6397L4.27031 37.5118Z" fill="#00B4FF" />
      <path d="M10.1499 41.0479L11.1299 39.8692L10.2899 39.3145L9.16992 40.4932L10.1499 41.0479Z" fill="#0068FF" />
      <path d="M35.1403 26.3477L38.5703 25.377L38.4303 24.4062L35.0703 25.377L35.1403 26.3477Z" fill="#9AFF5D" />
      <path d="M5.32047 38.8975L5.81047 38.2735L5.04047 36.748L4.48047 37.2334L5.32047 38.8975Z" fill="#009CFF" />
      <path d="M24.0798 33.6973L27.2998 32.7266L26.8798 33.2813L23.5898 34.3213L24.0798 33.6973Z" fill="#13FCE4" />
      <path d="M17.0096 37.4414L19.5296 36.1934L18.8296 36.4014L16.3096 37.5801L17.0096 37.4414Z" fill="#00B0FF" />
      <path d="M1.40039 19.2754L1.82039 20.8009L2.38039 18.5127L1.96039 16.918L1.40039 19.2754Z" fill="#FFDB00" />
      <path d="M13.9297 30.5079L17.2897 29.7451L17.1497 28.7051L13.7197 29.3291L13.9297 30.5079Z" fill="#53FFA4" />
      <path d="M29.8201 20.8701L32.9701 20.1074L32.4101 20.5928L29.3301 21.3555L29.8201 20.8701Z" fill="#EEFF09" />
      <path d="M37.03 21.0791L39.83 19.9697L39.27 19.3457L36.54 20.5244L37.03 21.0791Z" fill="#F4F802" />
      <path d="M7.42008 38.6905L8.61008 37.6504L7.91008 36.541L6.58008 37.4424L7.42008 38.6905Z" fill="#00A0FF" />
      <path d="M35.3497 19.7617L38.0797 18.6523L37.3797 18.5137L34.7197 19.6231L35.3497 19.7617Z" fill="#FFE200" />
      <path d="M12.0399 40.1455L13.5099 38.8281L12.6699 38.5508L11.1299 39.8682L12.0399 40.1455Z" fill="#007CFF" />
      <path d="M14.3498 38.8975L16.3098 37.5801H15.5398L13.5098 38.8282L14.3498 38.8975Z" fill="#0094FF" />
      <path d="M18.2705 41.3242L17.7805 41.6709L16.7305 43.0577L17.1505 42.7803L18.2705 41.3242Z" fill="#0044FF" />
      <path d="M3.08008 9.42972L3.43008 11.1632L4.69008 9.63773L4.34008 7.9043L3.08008 9.42972Z" fill="#FF1E00" />
      <path d="M31.7096 28.5674L35.1396 27.8047L35.0696 28.7061L31.6396 29.4688L31.7096 28.5674Z" fill="#66FF90" />
      <path d="M10.7102 42.503L11.1302 41.3936L10.1502 41.0469L9.66016 42.1563L10.7102 42.503Z" fill="#0048FF" />
      <path d="M36.5405 20.5244L39.2705 19.3457L38.7105 18.9297L35.9805 20.0391L36.5405 20.5244Z" fill="#FEED00" />
      <path d="M27.9997 31.2012L31.4297 30.3691L31.1497 31.1319L27.7197 32.0332L27.9997 31.2012Z" fill="#3CFFBA" />
      <path d="M35.9796 20.0391L38.7096 18.9297L38.0796 18.6523L35.3496 19.7617L35.9796 20.0391Z" fill="#FFE200" />
      <path d="M11.1999 32.3799L14.1399 31.6172L13.8599 30.5078L10.9199 31.2012L11.1999 32.3799Z" fill="#36FFC1" />
      <path d="M11.1304 41.3946L12.0404 40.1465L11.1304 39.8691L10.1504 41.0479L11.1304 41.3946Z" fill="#0064FF" />
      <path d="M7.91027 36.5411L9.73027 35.5703L9.17027 34.4609L7.28027 35.293L7.91027 36.5411Z" fill="#00D0FF" />
      <path d="M16.6602 8.59766L16.9402 9.91507L18.2002 12.2725L17.9202 10.8858L16.6602 8.59766Z" fill="#FF2900" />
      <path d="M17.0801 27.6651L20.7201 27.041L20.7901 26.0703L17.1501 26.5557L17.0801 27.6651Z" fill="#87FF70" />
      <path d="M11.8296 43.544L11.7596 42.5732L10.7096 42.5039L10.6396 43.4746L11.8296 43.544Z" fill="#0030FF" />
      <path d="M9.17 34.461L11.62 33.5596L11.2 32.3809L8.75 33.1436L9.17 34.461Z" fill="#13FCE4" />
      <path d="M21.1404 29.2598L21.2104 30.6465L20.9304 33.2813L20.8604 31.9639L21.1404 29.2598Z" fill="#43FFB4" />
      <path d="M5.74004 38.2735L6.58004 37.4414L5.95004 36.0547L5.04004 36.7481L5.74004 38.2735Z" fill="#00ACFF" />
      <path d="M16.7298 43.0586L16.0998 42.9199L15.0498 43.8906L15.5398 44.0293L16.7298 43.0586Z" fill="#002CFF" />
      <path d="M5.59961 5.13087L5.73961 6.72564L7.34961 6.03226L7.13961 4.4375L5.59961 5.13087Z" fill="#A80000" />
      <path d="M20.7202 35.3623L23.5902 34.3223L23.1002 34.7383L20.1602 35.8477L20.7202 35.3623Z" fill="#00DCFE" />
      <path d="M10.5703 2.98047V4.43656L12.2503 5.12993L12.1803 3.74318L10.5703 2.98047Z" fill="#800000" />
      <path d="M13.3001 44.0986L12.9501 43.3359L11.8301 43.5439L12.1101 44.3067L13.3001 44.0986Z" fill="#0020FF" />
      <path d="M25.2002 22.9502L28.7002 22.2568L29.1202 21.6328L25.6202 22.2568L25.2002 22.9502Z" fill="#D7FF1F" />
      <path d="M6.5802 37.4424L7.9102 36.541L7.2802 35.293L5.9502 36.0557L6.5802 37.4424Z" fill="#00C0FF" />
      <path d="M15.0498 43.8906L14.4898 43.4746L13.2998 44.0986L13.7898 44.584L15.0498 43.8906Z" fill="#0020FF" />
      <path d="M20.9297 25.1006L24.6397 24.5459L24.8497 23.6445L21.1397 24.1992L20.9297 25.1006Z" fill="#B1FF46" />
      <path d="M35.0704 25.378L38.4304 24.4072L38.2204 23.5059L34.8604 24.4766L35.0704 25.378Z" fill="#B1FF46" />
      <path d="M1.46973 23.2969L1.81973 24.6143L2.09973 22.1875L1.81973 20.8008L1.46973 23.2969Z" fill="#CEFF29" />
      <path d="M12.95 40.1455L14.35 38.8975L13.51 38.8281L12.04 40.1455H12.95Z" fill="#007CFF" />
      <path d="M30.3803 20.5938L33.5303 19.7617L32.9703 20.1084L29.8203 20.8711L30.3803 20.5938Z" fill="#FBF100" />
      <path d="M17.7098 37.0957L20.1598 35.8477L19.5298 36.1943L17.0098 37.4424L17.7098 37.0957Z" fill="#00BCFF" />
      <path d="M4.47961 37.2334L5.03961 36.7481L4.40961 35.084L3.84961 35.4307L4.47961 37.2334Z" fill="#00C4FF" />
      <path d="M15.1196 38.6895L17.0096 37.4414L16.3096 37.5801L14.3496 38.8975L15.1196 38.6895Z" fill="#009CFF" />
      <path d="M13.7204 29.3301L17.1504 28.7061L17.0804 27.666L13.6504 28.1514L13.7204 29.3301Z" fill="#6DFF8A" />
      <path d="M11.76 42.5733L12.11 41.3945H11.13L10.71 42.5039L11.76 42.5733Z" fill="#004CFF" />
      <path d="M24.5001 32.9346L27.7201 32.0332L27.3001 32.7266L24.0801 33.6973L24.5001 32.9346Z" fill="#23FFD4" />
      <path d="M12.1099 41.3946L12.9499 40.1465H12.0399L11.1299 41.3946H12.1099Z" fill="#0064FF" />
      <path d="M7.28004 35.292L9.17004 34.46L8.75004 33.1426L6.79004 33.9053L7.28004 35.292Z" fill="#09F0EE" />
      <path d="M15.3301 6.58789L15.4701 7.97464L16.9401 9.91609L16.6601 8.59868L15.3301 6.58789Z" fill="#DF0000" />
      <path d="M31.78 27.5967L35.21 26.834L35.14 27.8047L31.71 28.5674L31.78 27.5967Z" fill="#7DFF7A" />
      <path d="M3.56977 35.6387L3.84977 35.4307L3.35977 33.5586H3.00977L3.56977 35.6387Z" fill="#02E8F4" />
      <path d="M1.75 27.1114L2.03 28.0821L2.1 25.7246L1.82 24.6152L1.75 27.1114Z" fill="#94FF63" />
      <path d="M12.1797 3.74414L12.2497 5.13089L13.9297 6.30963L13.7897 4.92288L12.1797 3.74414Z" fill="#920000" />
      <path d="M28.21 30.3008L31.64 29.4688L31.43 30.3701L28 31.2022L28.21 30.3008Z" fill="#50FFA7" />
      <path d="M10.92 31.2022L13.93 30.5088L13.72 29.3301L10.71 29.9541L10.92 31.2022Z" fill="#50FFA7" />
      <path d="M8.75039 33.1426L11.2004 32.3799L10.9204 31.2012L8.40039 31.8252L8.75039 33.1426Z" fill="#30FFC7" />
      <path d="M34.8601 24.4756L38.2201 23.5049L37.9401 22.6035L34.5801 23.5742L34.8601 24.4756Z" fill="#C4FF33" />
      <path d="M2.79988 33.3506L3.00988 33.5586L2.65988 31.4785L2.37988 31.0625L2.79988 33.3506Z" fill="#2CFFCA" />
      <path d="M5.04016 36.7481L5.95016 36.0547L5.39016 34.5293L4.41016 35.084L5.04016 36.7481Z" fill="#00D4FF" />
      <path d="M2.17027 30.5088L2.38027 31.0635L2.24027 28.8447L2.03027 28.082L2.17027 30.5088Z" fill="#5AFF9D" />
      <path d="M13.79 4.92383L13.93 6.31058L15.47 7.97468L15.33 6.58793L13.79 4.92383Z" fill="#B20000" />
      <path d="M5.94965 36.0557L7.27965 35.293L6.78965 33.9062L5.38965 34.5303L5.94965 36.0557Z" fill="#00E4F8" />
      <path d="M30.9399 20.4551L34.0899 19.623L33.5299 19.7617L30.3799 20.5938L30.9399 20.4551Z" fill="#FFEA00" />
      <path d="M17.1504 26.5557L20.7904 26.0703L20.9304 25.0996L17.2904 25.585L17.1504 26.5557Z" fill="#9DFF5A" />
      <path d="M25.6201 22.2569L29.1201 21.6328L29.6101 21.0781L26.0401 21.7715L25.6201 22.2569Z" fill="#E7FF0F" />
      <path d="M21.2097 34.7373L24.0797 33.6973L23.5897 34.3213L20.7197 35.3614L21.2097 34.7373Z" fill="#09F0EE" />
      <path d="M20.9305 33.2812L20.7905 34.3906L20.2305 36.7481L20.3705 35.7081L20.9305 33.2812Z" fill="#06ECF1" />
      <path d="M12.9498 43.336L12.8098 42.3652L11.7598 42.5732L11.8298 43.544L12.9498 43.336Z" fill="#003CFF" />
      <path d="M13.7902 39.9375L15.1202 38.6895L14.3502 38.8975L12.9502 40.1455L13.7902 39.9375Z" fill="#0088FF" />
      <path d="M20.7197 22.0488L20.9297 23.6436L21.1397 26.4864L20.9997 24.8917L20.7197 22.0488Z" fill="#C4FF33" />
      <path d="M19.25 14.8379L19.39 16.4327L20.23 19.2061L20.02 17.6114L19.25 14.8379Z" fill="#FF9F00" />
      <path d="M21.1396 24.1992L24.8496 23.6445L25.1996 22.9512L21.4896 23.4365L21.1396 24.1992Z" fill="#C7FF30" />
      <path d="M2.37988 13.0362L2.79988 14.7696L3.77988 12.8975L3.42988 11.1641L2.37988 13.0362Z" fill="#FF5900" />
      <path d="M18.34 36.5401L20.72 35.3613L20.16 35.8467L17.71 37.0948L18.34 36.5401Z" fill="#00D0FF" />
      <path d="M34.5102 23.5743L37.8702 22.6035L37.5202 21.7715L34.1602 22.7422L34.5102 23.5743Z" fill="#D7FF1F" />
      <path d="M15.8201 38.2744L17.7101 37.0957L17.0101 37.4424L15.1201 38.6905L15.8201 38.2744Z" fill="#00ACFF" />
      <path d="M31.5004 20.4551L34.7204 19.623H34.0904L30.9404 20.4551H31.5004Z" fill="#FFE600" />
      <path d="M13.0904 41.1856L13.7904 39.9375L12.9504 40.1455L12.1104 41.3936L13.0904 41.1856Z" fill="#0070FF" />
      <path d="M12.8098 42.3643L13.0898 41.1855L12.1098 41.3936L11.7598 42.5723L12.8098 42.3643Z" fill="#0054FF" />
      <path d="M13.6504 28.1504L17.0804 27.6651L17.1504 26.625L13.7204 27.041L13.6504 28.1504Z" fill="#87FF70" />
      <path d="M31.6396 26.626L35.1396 25.8633L35.2096 26.834L31.7796 27.5967L31.6396 26.626Z" fill="#94FF63" />
      <path d="M6.79043 33.9063L8.75043 33.1436L8.40043 31.8262L6.44043 32.4502L6.79043 33.9063Z" fill="#26FFD1" />
      <path d="M24.78 32.0332L28 31.2012L27.72 32.0332L24.5 32.9346L24.78 32.0332Z" fill="#39FFBE" />
      <path d="M14.4902 43.4746L14.0002 42.7812L12.9502 43.3359L13.3002 44.0987L14.4902 43.4746Z" fill="#0038FF" />
      <path d="M3.85035 35.4317L4.41035 35.085L3.99035 33.3516L3.36035 33.5596L3.85035 35.4317Z" fill="#0CF4EB" />
      <path d="M28.35 29.3291L31.71 28.5664L31.64 29.4678L28.21 30.2998L28.35 29.3291Z" fill="#66FF90" />
      <path d="M20.2297 36.748L19.8797 37.5801L19.1797 39.5909L19.5297 38.8975L20.2297 36.748Z" fill="#00B0FF" />
      <path d="M34.1602 22.7422L37.5202 21.7715L37.0302 21.0781L33.7402 22.0488L34.1602 22.7422Z" fill="#E4FF13" />
      <path d="M10.7096 29.9532L13.7196 29.3291L13.6496 28.1504L10.6396 28.6358L10.7096 29.9532Z" fill="#6AFF8D" />
      <path d="M32.13 20.6631L35.35 19.7617L34.72 19.623L31.5 20.4551L32.13 20.6631Z" fill="#FFE600" />
      <path d="M8.40043 31.8252L10.9204 31.2012L10.7104 29.9531L8.19043 30.4385L8.40043 31.8252Z" fill="#49FFAD" />
      <path d="M26.04 21.7725L29.61 21.0791L30.1 20.7324L26.6 21.3565L26.04 21.7725Z" fill="#F4F802" />
      <path d="M7.13965 4.43751L7.34965 6.03227L9.02965 5.82425L8.81965 4.16016L7.13965 4.43751Z" fill="#8D0000" />
      <path d="M16.1002 42.919L15.4702 42.5723L14.4902 43.4737L15.0502 43.8897L16.1002 42.919Z" fill="#0040FF" />
      <path d="M5.38973 34.5293L6.78973 33.9053L6.43973 32.4492L4.96973 32.9346L5.38973 34.5293Z" fill="#1FFFD7" />
      <path d="M4.41023 35.085L5.39023 34.5303L4.97023 32.9355L3.99023 33.3516L4.41023 35.085Z" fill="#16FFE1" />
      <path d="M33.74 22.0489L37.03 21.0781L36.54 20.5234L33.25 21.4248L33.74 22.0489Z" fill="#F1FC06" />
      <path d="M17.29 25.586L20.93 25.1006L21.14 24.1992L17.5 24.6846L17.29 25.586Z" fill="#B4FF43" />
      <path d="M32.6899 20.9405L35.9799 20.0391L35.3499 19.7617L32.1299 20.6631L32.6899 20.9405Z" fill="#FFE600" />
      <path d="M19.1803 39.5918L18.6203 40.0078L17.7803 41.6719L18.2703 41.3252L19.1803 39.5918Z" fill="#007CFF" />
      <path d="M33.2504 21.4258L36.5404 20.5244L35.9804 20.0391L32.6904 20.9404L33.2504 21.4258Z" fill="#FEED00" />
      <path d="M21.63 33.9063L24.5 32.9355L24.08 33.6983L21.21 34.7383L21.63 33.9063Z" fill="#1CFFDB" />
      <path d="M17.7796 41.6719H17.0796L16.0996 42.9893L16.7296 43.0586L17.7796 41.6719Z" fill="#0054FF" />
      <path d="M4.33984 7.9053L4.68984 9.63874L6.08984 8.52934L5.73984 6.72656L4.33984 7.9053Z" fill="#D60000" />
      <path d="M14.63 39.5215L15.89 38.2734L15.12 38.6895L13.79 39.9375L14.63 39.5215Z" fill="#0098FF" />
      <path d="M21.4902 23.4365L25.2002 22.9512L25.6202 22.2578L21.9102 22.7432L21.4902 23.4365Z" fill="#D7FF1F" />
      <path d="M1.95996 16.919L2.37996 18.5138L3.14996 16.4336L2.79996 14.7695L1.95996 16.919Z" fill="#FF9C00" />
      <path d="M3.01016 33.5586H3.36016L3.01016 31.6172L2.66016 31.4785L3.01016 33.5586Z" fill="#30FFC7" />
      <path d="M31.5 25.6553L34.93 24.8926L35.14 25.8633L31.64 26.626L31.5 25.6553Z" fill="#AAFF4D" />
      <path d="M13.9996 42.7813L13.7896 41.8105L12.8096 42.3652L12.9496 43.336L13.9996 42.7813Z" fill="#0050FF" />
      <path d="M18.8998 35.7783L21.2098 34.7383L20.7198 35.3623L18.3398 36.5411L18.8998 35.7783Z" fill="#00E4F8" />
      <path d="M16.5196 37.7198L18.3396 36.541L17.7096 37.0957L15.8896 38.2745L16.5196 37.7198Z" fill="#00C0FF" />
      <path d="M26.5996 21.3555L30.0996 20.7314L30.6596 20.5234L27.1596 21.1475L26.5996 21.3555Z" fill="#FEED00" />
      <path d="M13.7197 27.042L17.1497 26.5567L17.2897 25.5859L13.8597 25.9326L13.7197 27.042Z" fill="#A0FF56" />
      <path d="M24.9903 31.0635L28.2103 30.3008L28.0003 31.2022L24.7803 32.0342L24.9903 31.0635Z" fill="#50FFA7" />
      <path d="M13.9298 40.7002L14.6298 39.5215L13.7898 39.9375L13.0898 41.1856L13.9298 40.7002Z" fill="#0080FF" />
      <path d="M6.44016 32.4502L8.40016 31.8262L8.19016 30.4395L6.16016 30.9248L6.44016 32.4502Z" fill="#46FFB1" />
      <path d="M21.2093 26.4863V27.9424V30.6466L21.1396 29.2598L21.2093 26.4863Z" fill="#83FF73" />
      <path d="M28.3503 28.2891L31.7803 27.5957L31.7103 28.5664L28.2803 29.3291L28.3503 28.2891Z" fill="#7DFF7A" />
      <path d="M13.7896 41.8106L13.9296 40.7012L13.0896 41.1865L12.8096 42.3653L13.7896 41.8106Z" fill="#0068FF" />
      <path d="M2.38023 31.0625L2.66023 31.4786L2.52023 29.3291L2.24023 28.8438L2.38023 31.0625Z" fill="#5DFF9A" />
      <path d="M18.2002 12.2734L18.3402 13.8682L19.3902 16.4337L19.2502 14.8389L18.2002 12.2734Z" fill="#FF6000" />
      <path d="M1.82031 20.8018L2.10031 22.1886L2.66031 19.9698L2.38031 18.5137L1.82031 20.8018Z" fill="#FFE200" />
      <path d="M10.6396 28.6358L13.6496 28.1504L13.7196 27.041L10.6396 27.457V28.6358Z" fill="#87FF70" />
      <path d="M8.1898 30.4395L10.7098 29.9541L10.6398 28.6367L8.0498 29.0527L8.1898 30.4395Z" fill="#6AFF8D" />
      <path d="M31.2197 24.6836L34.7197 23.9902L34.9297 24.8916L31.4997 25.6543L31.2197 24.6836Z" fill="#BEFF39" />
      <path d="M3.35977 33.5596L3.98977 33.3516L3.70977 31.5488L3.00977 31.6182L3.35977 33.5596Z" fill="#36FFC1" />
      <path d="M17.5 24.6836L21.14 24.1983L21.49 23.4355L17.85 23.8516L17.5 24.6836Z" fill="#CAFF2C" />
      <path d="M21.9102 22.7422L25.6202 22.2568L26.0402 21.7715L22.4002 22.2568L21.9102 22.7422Z" fill="#E7FF0F" />
      <path d="M2.03027 28.0821L2.24027 28.8448L2.31027 26.5567L2.10027 25.7246L2.03027 28.0821Z" fill="#90FF66" />
      <path d="M1.82031 24.6143L2.10031 25.7237L2.38031 23.4356L2.10031 22.1875L1.82031 24.6143Z" fill="#CAFF2C" />
      <path d="M4.97043 32.9346L6.44043 32.4493L6.16043 30.9238L4.69043 31.3399L4.97043 32.9346Z" fill="#40FFB7" />
      <path d="M27.1602 21.1485L30.6602 20.5244L31.2202 20.4551L27.7202 21.0791L27.1602 21.1485Z" fill="#FFE600" />
      <path d="M15.47 42.5733L14.98 41.9492L14 42.7813L14.49 43.4746L15.47 42.5733Z" fill="#0054FF" />
      <path d="M21.9799 32.9346L24.7799 32.0332L24.4999 32.9346L21.6299 33.9053L21.9799 32.9346Z" fill="#33FFC4" />
      <path d="M3.98996 33.3506L4.96996 32.9346L4.68996 31.3398L3.70996 31.5479L3.98996 33.3506Z" fill="#3CFFBA" />
      <path d="M15.3299 38.8975L16.5199 37.7188L15.8899 38.2734L14.6299 39.5215L15.3299 38.8975Z" fill="#00ACFF" />
      <path d="M8.82031 4.16016L9.03031 5.82425L10.7103 6.1016L10.5003 4.43751L8.82031 4.16016Z" fill="#800000" />
      <path d="M20.2305 19.207L20.3705 20.8018L20.9305 23.6446L20.7205 22.0499L20.2305 19.207Z" fill="#FFEA00" />
      <path d="M30.8701 23.8516L34.3701 23.1582L34.7201 23.9903L31.2201 24.6836L30.8701 23.8516Z" fill="#D1FF26" />
      <path d="M28.21 27.3184L31.64 26.625L31.78 27.5957L28.35 28.2891L28.21 27.3184Z" fill="#94FF63" />
      <path d="M19.3204 34.877L21.6304 33.9062L21.2104 34.7383L18.9004 35.7784L19.3204 34.877Z" fill="#16FFE1" />
      <path d="M17.1495 36.8867L18.8995 35.7773L18.3395 36.5401L16.5195 37.7188L17.1495 36.8867Z" fill="#00D8FF" />
      <path d="M25.1302 30.0928L28.3502 29.3301L28.2102 30.3008L24.9902 31.0635L25.1302 30.0928Z" fill="#66FF90" />
      <path d="M27.7197 21.0791L31.2197 20.4551L31.8497 20.5244L28.2797 21.2178L27.7197 21.0791Z" fill="#FFE200" />
      <path d="M13.8604 25.9317L17.2904 25.585L17.5004 24.6836L14.1404 24.9609L13.8604 25.9317Z" fill="#B7FF40" />
      <path d="M21.21 30.6465L21 31.8946L20.79 34.3907L20.93 33.2813L21.21 30.6465Z" fill="#43FFB4" />
      <path d="M3.42969 11.1641L3.77969 12.8975L5.03969 11.4414L4.68969 9.63867L3.42969 11.1641Z" fill="#FF2500" />
      <path d="M6.15984 30.9248L8.18984 30.4395L8.04984 29.0527L6.08984 29.3994L6.15984 30.9248Z" fill="#66FF90" />
      <path d="M14.7697 40.0078L15.3297 38.8984L14.6297 39.5225L13.9297 40.7012L14.7697 40.0078Z" fill="#0098FF" />
      <path d="M17.0797 41.6709L16.3797 41.3242L15.4697 42.5723L16.0997 42.919L17.0797 41.6709Z" fill="#0068FF" />
      <path d="M30.4502 23.0899L33.9502 22.3965L34.3702 23.1592L30.8702 23.8526L30.4502 23.0899Z" fill="#E1FF16" />
      <path d="M22.4004 22.2569L26.0404 21.7715L26.6004 21.3555L22.8904 21.8408L22.4004 22.2569Z" fill="#F8F500" />
      <path d="M14.98 41.9483L14.7 41.0469L13.79 41.8096L14 42.7803L14.98 41.9483Z" fill="#006CFF" />
      <path d="M16.9404 9.91602L17.0804 11.5801L18.3404 13.8682L18.2004 12.2735L16.9404 9.91602Z" fill="#FF2900" />
      <path d="M2.65953 31.4795L3.00953 31.6182L2.86953 29.6074L2.51953 29.3301L2.65953 31.4795Z" fill="#60FF97" />
      <path d="M28.2803 21.2168L31.8503 20.5234L32.4103 20.8008L28.9103 21.4248L28.2803 21.2168Z" fill="#FFE600" />
      <path d="M17.8496 23.8516L21.4896 23.4356L21.9096 22.7422L18.2696 23.0889L17.8496 23.8516Z" fill="#DEFF19" />
      <path d="M10.6396 27.4571L13.7196 27.041L13.8596 25.9316L10.8496 26.209L10.6396 27.4571Z" fill="#A4FF53" />
      <path d="M14.7 41.0479L14.77 40.0078L13.93 40.7012L13.79 41.8106L14.7 41.0479Z" fill="#0080FF" />
      <path d="M8.0498 29.0518L10.6398 28.6358V27.457L8.1198 27.7344L8.0498 29.0518Z" fill="#87FF70" />
      <path d="M30.0303 22.3965L33.4603 21.7031L33.9503 22.3965L30.4503 23.0899L30.0303 22.3965Z" fill="#EEFF09" />
      <path d="M28.9102 21.4248L32.4102 20.8008L32.9702 21.1475L29.4702 21.8408L28.9102 21.4248Z" fill="#FFEA00" />
      <path d="M5.74023 6.72658L6.09023 8.52935L7.63023 7.83598L7.35023 6.0332L5.74023 6.72658Z" fill="#AD0000" />
      <path d="M29.4697 21.8418L32.9697 21.1484L33.4597 21.7031L30.0297 22.3965L29.4697 21.8418Z" fill="#FBF100" />
      <path d="M20.7899 34.3906L20.3699 35.292L19.8799 37.5801L20.2299 36.7481L20.7899 34.3906Z" fill="#0CF4EB" />
      <path d="M28.0703 26.2783L31.5003 25.6543L31.6403 26.625L28.2103 27.3184L28.0703 26.2783Z" fill="#AAFF4D" />
      <path d="M22.1905 31.8945L24.9905 31.0625L24.7805 32.0332L21.9805 32.9346L22.1905 31.8945Z" fill="#4DFFAA" />
      <path d="M18.6201 40.0078L17.9201 40.0771L17.0801 41.6719H17.7801L18.6201 40.0078Z" fill="#0088FF" />
      <path d="M10.5 4.4375L10.71 6.1016L12.46 6.79497L12.25 5.13087L10.5 4.4375Z" fill="#800000" />
      <path d="M4.6898 31.3399L6.1598 30.9239L6.0898 29.3984L4.5498 29.6064L4.6898 31.3399Z" fill="#66FF90" />
      <path d="M19.8801 37.5801L19.3201 38.0654L18.6201 40.0069L19.1801 39.5909L19.8801 37.5801Z" fill="#00B8FF" />
      <path d="M16.0301 37.9961L17.1501 36.8867L16.5201 37.7188L15.3301 38.8975L16.0301 37.9961Z" fill="#00C8FF" />
      <path d="M25.1299 28.9824L28.3499 28.2891L28.2799 29.3291L25.1299 30.0918V28.9824Z" fill="#7DFF7A" />
      <path d="M3.01012 31.6182L3.71012 31.5489L3.57012 29.7461L2.87012 29.6074L3.01012 31.6182Z" fill="#60FF97" />
      <path d="M22.8896 21.8418L26.5996 21.3564L27.1596 21.1484L23.5196 21.6338L22.8896 21.8418Z" fill="#FFEA00" />
      <path d="M15.4697 7.97461L15.6797 9.56937L17.0797 11.5802L16.9397 9.91606L15.4697 7.97461Z" fill="#DF0000" />
      <path d="M3.71031 31.5489L4.69031 31.2715L4.55031 29.6074L3.57031 29.7461L3.71031 31.5489Z" fill="#63FF94" />
      <path d="M2.24023 28.8448L2.52023 29.3301V27.25L2.31023 26.5566L2.24023 28.8448Z" fill="#90FF66" />
      <path d="M19.6703 33.9063L21.9803 32.9355L21.6303 33.9063L19.3203 34.877L19.6703 33.9063Z" fill="#2CFFCA" />
      <path d="M14.1396 24.961L17.4996 24.6836L17.8496 23.8516L14.4896 24.0596L14.1396 24.961Z" fill="#D1FF26" />
      <path d="M2.7998 14.7686L3.1498 16.4327L4.1298 14.6299L3.7798 12.8965L2.7998 14.7686Z" fill="#FF6000" />
      <path d="M17.6404 35.917L19.3204 34.877L18.9004 35.7783L17.1504 36.8877L17.6404 35.917Z" fill="#0CF4EB" />
      <path d="M12.25 5.13086L12.46 6.79496L14.07 7.97369L13.93 6.3096L12.25 5.13086Z" fill="#920000" />
      <path d="M18.2695 23.0899L21.9095 22.7432L22.3995 22.2578L18.8295 22.5352L18.2695 23.0899Z" fill="#EEFF09" />
      <path d="M27.79 25.3076L31.22 24.6836L31.5 25.6543L28.07 26.2784L27.79 25.3076Z" fill="#C1FF36" />
      <path d="M16.3805 41.3242L15.8205 40.7695L14.9805 41.9483L15.4705 42.5723L16.3805 41.3242Z" fill="#007CFF" />
      <path d="M13.9297 6.31055L14.0697 7.97465L15.6797 9.56941L15.4697 7.97465L13.9297 6.31055Z" fill="#B20000" />
      <path d="M20.9297 23.6445V25.17L21.2097 27.9435L21.1397 26.4874L20.9297 23.6445Z" fill="#C1FF36" />
      <path d="M6.08984 29.3985L8.04984 29.0518L8.11984 27.7344L6.08984 27.9424V29.3985Z" fill="#8AFF6D" />
      <path d="M23.5195 21.6328L27.1595 21.1475L27.7195 21.0781L24.0795 21.5635L23.5195 21.6328Z" fill="#FFE200" />
      <path d="M10.8496 26.209L13.8596 25.9317L14.1396 24.9609L11.1296 25.1689L10.8496 26.209Z" fill="#BEFF39" />
      <path d="M15.4695 39.0362L16.0295 37.9961L15.3295 38.8975L14.7695 40.0069L15.4695 39.0362Z" fill="#00B4FF" />
      <path d="M19.3896 16.4336L19.5996 18.0977L20.3696 20.8019L20.2296 19.2071L19.3896 16.4336Z" fill="#FFA300" />
      <path d="M2.09961 25.7237L2.30961 26.5557L2.58961 24.4756L2.37961 23.4355L2.09961 25.7237Z" fill="#C7FF30" />
      <path d="M8.12012 27.7344L10.6401 27.4571L10.8501 26.209L8.33012 26.417L8.12012 27.7344Z" fill="#A7FF50" />
      <path d="M2.37988 18.5137L2.65988 19.9698L3.42988 18.0284L3.14988 16.4336L2.37988 18.5137Z" fill="#FFA300" />
      <path d="M22.3304 30.7852L25.1304 30.0918L24.9904 31.0625L22.1904 31.8946L22.3304 30.7852Z" fill="#63FF94" />
      <path d="M24.9902 27.9424L28.2102 27.3184L28.3502 28.2891L25.1302 28.9825L24.9902 27.9424Z" fill="#97FF60" />
      <path d="M2.09961 22.1875L2.37961 23.4356L2.86961 21.2862L2.65961 19.9688L2.09961 22.1875Z" fill="#FFEA00" />
      <path d="M27.4404 24.4063L30.8704 23.8516L31.2204 24.6836L27.7904 25.3076L27.4404 24.4063Z" fill="#D4FF23" />
      <path d="M15.8202 40.7705L15.4702 40.0078L14.7002 41.0479L14.9802 41.9493L15.8202 40.7705Z" fill="#0090FF" />
      <path d="M15.4702 40.0078V39.0371L14.7702 40.0078L14.7002 41.0479L15.4702 40.0078Z" fill="#00A0FF" />
      <path d="M24.0801 21.5635L27.7201 21.0781L28.2801 21.2168L24.7101 21.6328L24.0801 21.5635Z" fill="#FFDE00" />
      <path d="M2.51953 29.3301L2.86953 29.6075L2.93953 27.666L2.51953 27.25V29.3301Z" fill="#8DFF6A" />
      <path d="M14.4902 24.0606L17.8502 23.8526L18.2702 23.0898L14.9102 23.2979L14.4902 24.0606Z" fill="#E4FF13" />
      <path d="M18.8301 22.5352L22.4001 22.2578L22.8901 21.8418L19.3901 22.1191L18.8301 22.5352Z" fill="#FEED00" />
      <path d="M4.5498 29.6075L6.0898 29.3994V27.9434L4.6198 28.0127L4.5498 29.6075Z" fill="#8AFF6D" />
      <path d="M16.5203 36.9561L17.6403 35.916L17.1503 36.8867L16.0303 37.9961L16.5203 36.9561Z" fill="#02E8F4" />
      <path d="M17.9199 40.0772L17.1499 39.8691L16.3799 41.3252L17.0799 41.6719L17.9199 40.0772Z" fill="#0098FF" />
      <path d="M19.9499 32.7266L22.1899 31.8945L21.9799 32.9346L19.6699 33.9053L19.9499 32.7266Z" fill="#46FFB1" />
      <path d="M26.9502 23.5752L30.4502 23.0898L30.8702 23.8526L27.4402 24.4073L26.9502 23.5752Z" fill="#E4FF13" />
      <path d="M7.34961 6.03223L7.62961 7.835L9.30961 7.62699L9.02961 5.82422L7.34961 6.03223Z" fill="#920000" />
      <path d="M4.69043 9.6387L5.04043 11.4415L6.44043 10.3321L6.09043 8.5293L4.69043 9.6387Z" fill="#DF0000" />
      <path d="M24.71 21.6328L28.28 21.2168L28.91 21.4248L25.34 21.9102L24.71 21.6328Z" fill="#FFDE00" />
      <path d="M17.9896 34.8076L19.6696 33.9062L19.3196 34.877L17.6396 35.917L17.9896 34.8076Z" fill="#26FFD1" />
      <path d="M21.21 27.9434L21.07 29.1914L21 31.8956L21.21 30.6475V27.9434Z" fill="#83FF73" />
      <path d="M26.46 22.8818L30.03 22.3965L30.45 23.0899L26.95 23.5752L26.46 22.8818Z" fill="#F1FC06" />
      <path d="M25.3398 21.9111L28.9098 21.4258L29.4698 21.8418L25.8998 22.3272L25.3398 21.9111Z" fill="#FFE600" />
      <path d="M2.87012 29.6075L3.57012 29.7461V27.9434L2.94012 27.666L2.87012 29.6075Z" fill="#8DFF6A" />
      <path d="M3.57031 29.7461L4.55031 29.6075L4.62031 28.0127L3.57031 27.9434V29.7461Z" fill="#8DFF6A" />
      <path d="M24.8496 26.834L28.0696 26.2793L28.2096 27.3194L24.9896 27.9434L24.8496 26.834Z" fill="#ADFF49" />
      <path d="M25.9004 22.3272L29.4704 21.8418L30.0304 22.3965L26.4604 22.8819L25.9004 22.3272Z" fill="#FEED00" />
      <path d="M11.1299 25.1699L14.1399 24.9619L14.4899 24.0605L11.5499 24.1992L11.1299 25.1699Z" fill="#D7FF1F" />
      <path d="M22.3301 29.6758L25.1301 28.9824V30.0918L22.3301 30.7852V29.6758Z" fill="#7DFF7A" />
      <path d="M6.08984 27.9434L8.11984 27.7354L8.32984 26.418L6.29984 26.4873L6.08984 27.9434Z" fill="#AAFF4D" />
      <path d="M19.3199 38.0664L18.5499 38.2744L17.9199 40.0772L18.6199 40.0079L19.3199 38.0664Z" fill="#00C4FF" />
      <path d="M19.3896 22.1182L22.8896 21.8408L23.5196 21.6328L19.9496 21.9102L19.3896 22.1182Z" fill="#FFE200" />
      <path d="M8.33008 26.418L10.8501 26.2793L11.1301 25.1699L8.61008 25.2393L8.33008 26.418Z" fill="#C4FF33" />
      <path d="M16.0997 37.9278L16.5197 36.957L16.0297 37.9971L15.4697 39.0372L16.0997 37.9278Z" fill="#00D8FF" />
      <path d="M21.0001 31.8945L20.6501 32.8653L20.3701 35.2921L20.7901 34.3907L21.0001 31.8945Z" fill="#46FFB1" />
      <path d="M2.30957 26.5567L2.51957 27.2501L2.79957 25.2393L2.58957 24.4766L2.30957 26.5567Z" fill="#C1FF36" />
      <path d="M18.3398 13.8672L18.5498 15.6006L19.5998 18.0968L19.3898 16.4327L18.3398 13.8672Z" fill="#FF6400" />
      <path d="M14.9805 23.2979L18.2705 23.0899L18.8305 22.5352L15.4705 22.6738L14.9805 23.2979Z" fill="#F8F500" />
      <path d="M17.1503 39.8691L16.5203 39.4531L15.8203 40.7705L16.3803 41.3252L17.1503 39.8691Z" fill="#00A8FF" />
      <path d="M20.3703 35.293L19.7403 35.917L19.3203 38.0665L19.8803 37.5811L20.3703 35.293Z" fill="#0FF8E7" />
      <path d="M24.5 25.794L27.79 25.3086L28.07 26.2793L24.85 26.834L24.5 25.794Z" fill="#C4FF33" />
      <path d="M20.0202 31.5479L22.3302 30.7852L22.1902 31.8946L19.9502 32.7266L20.0202 31.5479Z" fill="#63FF94" />
      <path d="M20.3701 20.8008L20.4401 22.3955L20.9301 25.169V23.6436L20.3701 20.8008Z" fill="#FFEA00" />
      <path d="M3.78027 12.8975L4.13027 14.6309L5.32027 13.1748L5.04027 11.4414L3.78027 12.8975Z" fill="#FF3000" />
      <path d="M16.0997 38.7598V37.9277L15.4697 39.0371V40.0079L16.0997 38.7598Z" fill="#00C8FF" />
      <path d="M19.9502 21.9111L23.5202 21.6338L24.0802 21.5645L20.5802 21.8418L19.9502 21.9111Z" fill="#FFDB00" />
      <path d="M16.5197 39.4531L16.0997 38.7598L15.4697 40.0078L15.8197 40.7706L16.5197 39.4531Z" fill="#00B8FF" />
      <path d="M16.9395 35.708L17.9895 34.8066L17.6395 35.916L16.5195 36.9561L16.9395 35.708Z" fill="#1CFFDB" />
      <path d="M4.62012 28.0127L6.09012 27.9434L6.30012 26.4873L4.83012 26.418L4.62012 28.0127Z" fill="#ADFF49" />
      <path d="M9.03027 5.82422L9.31027 7.62699L10.9903 7.835L10.7103 6.10157L9.03027 5.82422Z" fill="#840000" />
      <path d="M22.1904 28.4981L24.9904 27.9434L25.1304 28.9834L22.3304 29.6768L22.1904 28.4981Z" fill="#97FF60" />
      <path d="M18.2702 33.5586L19.9502 32.7266L19.6702 33.9053L17.9902 34.8067L18.2702 33.5586Z" fill="#43FFB4" />
      <path d="M2.37988 23.4366L2.58988 24.4766L3.07988 22.4658L2.86988 21.2871L2.37988 23.4366Z" fill="#FBF100" />
      <path d="M24.1504 24.8223L27.4404 24.4062L27.7904 25.3076L24.5004 25.793L24.1504 24.8223Z" fill="#D7FF1F" />
      <path d="M11.4805 24.1983L14.4905 24.0596L14.9805 23.2969L12.0405 23.3662L11.4805 24.1983Z" fill="#EEFF09" />
      <path d="M2.51953 27.2491L2.93953 27.6651L3.14953 25.8623L2.79953 25.2383L2.51953 27.2491Z" fill="#BEFF39" />
      <path d="M15.4697 22.6738L18.8297 22.5352L19.3897 22.1191L16.0997 22.2578L15.4697 22.6738Z" fill="#FFE600" />
      <path d="M20.5801 21.8418L24.0801 21.5645L24.7101 21.6338L21.2801 21.9111L20.5801 21.8418Z" fill="#FFD700" />
      <path d="M3.15039 16.4336L3.43039 18.0284L4.41039 16.295L4.13039 14.6309L3.15039 16.4336Z" fill="#FF6C00" />
      <path d="M6.2998 26.4873L8.3298 26.418L8.6098 25.2393L6.6498 25.1699L6.2998 26.4873Z" fill="#CAFF2C" />
      <path d="M3.57031 27.9424L4.62031 28.0118L4.83031 26.417L3.85031 26.209L3.57031 27.9424Z" fill="#B4FF43" />
      <path d="M2.66016 19.9688L2.87016 21.2862L3.57016 19.4141L3.43016 18.0273L2.66016 19.9688Z" fill="#FFAE00" />
      <path d="M8.61035 25.2383L11.1304 25.169L11.4804 24.1982L9.03035 24.1289L8.61035 25.2383Z" fill="#DEFF19" />
      <path d="M17.0801 11.5801L17.2901 13.3135L18.5501 15.6016L18.3401 13.8682L17.0801 11.5801Z" fill="#FF2D00" />
      <path d="M23.6602 23.9902L26.9502 23.5742L27.4402 24.4063L24.1502 24.8223L23.6602 23.9902Z" fill="#EBFF0C" />
      <path d="M18.5504 38.2744L17.7804 38.2051L17.1504 39.8692L17.9204 40.0772L18.5504 38.2744Z" fill="#00CCFF" />
      <path d="M6.08984 8.52931L6.43984 10.3321L7.97984 9.63871L7.62984 7.83594L6.08984 8.52931Z" fill="#BB0000" />
      <path d="M2.94043 27.6661L3.57043 27.9434L3.85043 26.21L3.15043 25.8633L2.94043 27.6661Z" fill="#B7FF40" />
      <path d="M21.2803 21.9102L24.7103 21.6328L25.3403 21.9102L21.9103 22.1875L21.2803 21.9102Z" fill="#FFD700" />
      <path d="M20.0195 30.2998L22.3295 29.6758V30.7852L20.0195 31.5479V30.2998Z" fill="#7DFF7A" />
      <path d="M21.9805 27.3193L24.8505 26.834L24.9905 27.874L22.1905 28.4981L21.9805 27.3193Z" fill="#B1FF46" />
      <path d="M16.5196 36.541L16.9396 35.709L16.5196 36.9571L16.0996 37.9278L16.5196 36.541Z" fill="#16FFE1" />
      <path d="M23.1699 23.2275L26.4599 22.8809L26.9499 23.5742L23.6599 23.9903L23.1699 23.2275Z" fill="#F8F500" />
      <path d="M21.9102 22.1875L25.3402 21.9102L25.9002 22.3262L22.5402 22.6729L21.9102 22.1875Z" fill="#FFDB00" />
      <path d="M10.71 6.10156L10.99 7.835L12.67 8.59771L12.46 6.79494L10.71 6.10156Z" fill="#890000" />
      <path d="M22.54 22.6729L25.9 22.3262L26.46 22.8809L23.17 23.2276L22.54 22.6729Z" fill="#FFE600" />
      <path d="M20.93 25.1699L20.79 26.5567L21.07 29.1915L21.21 27.9434L20.93 25.1699Z" fill="#C1FF36" />
      <path d="M16.0996 22.2568L19.3896 22.1182L19.9496 21.9102L16.7296 21.9795L16.0996 22.2568Z" fill="#FFD700" />
      <path d="M12.04 23.3672L14.98 23.2979L15.47 22.6738H12.6L12.04 23.3672Z" fill="#FFEA00" />
      <path d="M18.4095 32.2422L20.0195 31.5488L19.9495 32.7276L18.2695 33.5596L18.4095 32.2422Z" fill="#60FF97" />
      <path d="M17.2904 34.3213L18.2704 33.5586L17.9904 34.8067L16.9404 35.7081L17.2904 34.3213Z" fill="#3CFFBA" />
      <path d="M15.6797 9.56836L15.8897 11.3018L17.2897 13.3126L17.0797 11.5791L15.6797 9.56836Z" fill="#E40000" />
      <path d="M17.7795 38.2041L17.0795 37.8574L16.5195 39.4522L17.1495 39.8682L17.7795 38.2041Z" fill="#00D8FF" />
      <path d="M19.7398 35.916L18.9698 36.1934L18.5498 38.2735L19.3198 38.0655L19.7398 35.916Z" fill="#16FFE1" />
      <path d="M16.5896 37.3037L16.5196 36.541L16.0996 37.9278V38.7598L16.5896 37.3037Z" fill="#0CF4EB" />
      <path d="M4.83008 26.417L6.30008 26.4864L6.65008 25.1689L5.18008 24.9609L4.83008 26.417Z" fill="#D1FF26" />
      <path d="M12.46 6.79492L12.67 8.5977L14.35 9.70709L14.07 7.97366L12.46 6.79492Z" fill="#9B0000" />
      <path d="M2.58984 24.4756L2.79984 25.2383L3.28984 23.3662L3.07984 22.4648L2.58984 24.4756Z" fill="#F4F802" />
      <path d="M21.7002 26.209L24.5002 25.793L24.8502 26.833L21.9802 27.3184L21.7002 26.209Z" fill="#C7FF30" />
      <path d="M19.5996 18.0977L19.6696 19.7618L20.4396 22.3966L20.3696 20.8018L19.5996 18.0977Z" fill="#FFA700" />
      <path d="M14.0703 7.97461L14.3503 9.70805L15.8903 11.3028L15.6803 9.56937L14.0703 7.97461Z" fill="#BB0000" />
      <path d="M17.0796 37.8574L16.5896 37.3027L16.0996 38.7588L16.5196 39.4522L17.0796 37.8574Z" fill="#02E8F4" />
      <path d="M21.0704 29.2598L20.6504 30.2998V32.8653L21.0004 31.8946L21.0704 29.2598Z" fill="#80FF77" />
      <path d="M9.03027 24.1299L11.4803 24.1992L12.0403 23.3672L9.59027 23.2285L9.03027 24.1299Z" fill="#F8F500" />
      <path d="M19.9502 28.9834L22.1902 28.498L22.3302 29.6768L20.0202 30.3008L19.9502 28.9834Z" fill="#9AFF5D" />
      <path d="M20.6502 32.8652L20.0202 33.5586L19.7402 35.9161L20.3702 35.292L20.6502 32.8652Z" fill="#49FFAD" />
      <path d="M16.7305 21.9805L19.9505 21.9111L20.5805 21.8418L17.4305 21.9111L16.7305 21.9805Z" fill="#FFD000" />
      <path d="M6.65039 25.169L8.61039 25.2383L9.03039 24.1289L7.07039 23.9902L6.65039 25.169Z" fill="#E7FF0F" />
      <path d="M5.04004 11.4414L5.32004 13.1749L6.72004 12.1348L6.44004 10.332L5.04004 11.4414Z" fill="#F10800" />
      <path d="M3.84961 26.21L4.82961 26.418L5.17961 24.9619L4.19961 24.6152L3.84961 26.21Z" fill="#DBFF1C" />
      <path d="M21.21 25.169L24.15 24.8223L24.5 25.793L21.7 26.209L21.21 25.169Z" fill="#DEFF19" />
      <path d="M12.5996 22.6729H15.4696L16.0996 22.2568L13.2296 22.1875L12.5996 22.6729Z" fill="#FFD700" />
      <path d="M2.7998 25.2393L3.1498 25.8633L3.5698 24.1299L3.2898 23.3672L2.7998 25.2393Z" fill="#EBFF0C" />
      <path d="M7.62988 7.83497L7.97988 9.63774L9.58988 9.42973L9.30988 7.62695L7.62988 7.83497Z" fill="#9F0000" />
      <path d="M16.8695 35.085L17.2895 34.3223L16.9395 35.709L16.5195 36.5411L16.8695 35.085Z" fill="#36FFC1" />
      <path d="M2.87012 21.2862L3.08012 22.4649L3.71012 20.6621L3.57012 19.4141L2.87012 21.2862Z" fill="#FFB900" />
      <path d="M17.4297 21.9111L20.6497 21.8418L21.2797 21.9111L18.1297 21.9805L17.4297 21.9111Z" fill="#FFCC00" />
      <path d="M18.4102 30.8555L20.0202 30.3008V31.5489L18.4102 32.2422V30.8555Z" fill="#80FF77" />
      <path d="M3.15039 25.8623L3.85039 26.209L4.20039 24.6143L3.57039 24.1289L3.15039 25.8623Z" fill="#E1FF16" />
      <path d="M20.7197 24.2676L23.6597 23.9902L24.1497 24.8223L21.2797 25.169L20.7197 24.2676Z" fill="#F1FC06" />
      <path d="M17.43 32.8662L18.41 32.2422L18.27 33.5596L17.29 34.3223L17.43 32.8662Z" fill="#5DFF9A" />
      <path d="M4.12988 14.6299L4.40988 16.294L5.52988 14.9073L5.31988 13.1738L4.12988 14.6299Z" fill="#FF3B00" />
      <path d="M18.9703 36.1934L18.2003 36.2627L17.7803 38.2041L18.5503 38.2735L18.9703 36.1934Z" fill="#1CFFDB" />
      <path d="M19.6699 27.7344L21.9799 27.3184L22.1899 28.4971L19.9499 28.9825L19.6699 27.7344Z" fill="#B7FF40" />
      <path d="M18.1299 21.9795L21.2799 21.9102L21.9099 22.1875L18.8299 22.3262L18.1299 21.9795Z" fill="#FFCC00" />
      <path d="M3.42969 18.0284L3.56969 19.4151L4.54969 17.8203L4.40969 16.2949L3.42969 18.0284Z" fill="#FF7A00" />
      <path d="M20.1602 23.4365L23.1702 23.2285L23.6602 23.9912L20.7202 24.2686L20.1602 23.4365Z" fill="#FFEA00" />
      <path d="M9.58984 23.2276L12.0398 23.3662L12.5998 22.6729L10.2198 22.4648L9.58984 23.2276Z" fill="#FFDB00" />
      <path d="M18.8301 22.3262L21.9101 22.1875L22.5401 22.6729L19.5301 22.8115L18.8301 22.3262Z" fill="#FFD000" />
      <path d="M5.17969 24.961L6.64969 25.169L7.06969 23.9902L5.66969 23.7129L5.17969 24.961Z" fill="#F4F802" />
      <path d="M19.5303 22.8125L22.5403 22.6738L23.1703 23.2285L20.1603 23.4365L19.5303 22.8125Z" fill="#FFDB00" />
      <path d="M13.2305 22.1875L16.1005 22.2568L16.7305 21.9795L13.9305 21.9102L13.2305 22.1875Z" fill="#FFC800" />
      <path d="M18.5498 15.6016L18.6198 17.2657L19.6698 19.7618L19.5998 18.0977L18.5498 15.6016Z" fill="#FF6C00" />
      <path d="M20.4398 22.3965L20.2998 23.8526L20.7898 26.5567L20.9298 25.17L20.4398 22.3965Z" fill="#FBF100" />
      <path d="M16.9395 35.6387L16.8695 35.084L16.5195 36.5401L16.5895 37.3028L16.9395 35.6387Z" fill="#30FFC7" />
      <path d="M7.07031 23.9912L9.03031 24.1299L9.59031 23.2285L7.70031 22.9512L7.07031 23.9912Z" fill="#FFE600" />
      <path d="M18.2001 36.2627L17.4301 36.0547L17.0801 37.8575L17.7801 38.1348L18.2001 36.2627Z" fill="#23FFD4" />
      <path d="M17.4298 36.0547L16.9398 35.6387L16.5898 37.3028L17.0798 37.8575L17.4298 36.0547Z" fill="#29FFCE" />
      <path d="M19.3203 26.4863L21.7003 26.209L21.9803 27.3184L19.6703 27.7344L19.3203 26.4863Z" fill="#D1FF26" />
      <path d="M18.2695 29.4678L19.9495 28.9824L20.0195 30.2998L18.4095 30.8545L18.2695 29.4678Z" fill="#9DFF5A" />
      <path d="M3.08008 22.4649L3.29008 23.3663L3.92008 21.7022L3.71008 20.6621L3.08008 22.4649Z" fill="#FFC400" />
      <path d="M20.0197 33.5586L19.2497 34.044L18.9697 36.1934L19.7397 35.9161L20.0197 33.5586Z" fill="#4DFFAA" />
      <path d="M9.30957 7.62695L9.58957 9.42973L11.1996 9.70708L10.9896 7.83497L9.30957 7.62695Z" fill="#920000" />
      <path d="M13.9297 21.9102L16.7297 21.9795L17.4297 21.9102L14.6997 21.7715L13.9297 21.9102Z" fill="#FFC100" />
      <path d="M4.2002 24.6153L5.1802 24.962L5.6702 23.7139L4.7602 23.2285L4.2002 24.6153Z" fill="#FEED00" />
      <path d="M17.0101 33.4893L17.4301 32.8652L17.2901 34.3907L16.8701 35.084L17.0101 33.4893Z" fill="#5AFF9D" />
      <path d="M6.44043 10.332L6.72043 12.1348L8.19043 11.5108L7.98043 9.63867L6.44043 10.332Z" fill="#C80000" />
      <path d="M20.7901 26.5566L20.3701 27.7354L20.6501 30.3009L21.0701 29.1915L20.7901 26.5566Z" fill="#BEFF39" />
      <path d="M17.4297 31.3408L18.4097 30.8555V32.2422L17.4297 32.8663V31.3408Z" fill="#80FF77" />
      <path d="M10.2197 22.4658L12.5997 22.6738L13.2297 22.1885L10.9197 21.9805L10.2197 22.4658Z" fill="#FFC800" />
      <path d="M20.6495 30.3008L20.0195 31.1328V33.5596L20.6495 32.8663V30.3008Z" fill="#80FF77" />
      <path d="M18.9004 25.3779L21.2104 25.1699L21.6304 26.21L19.3204 26.4873L18.9004 25.3779Z" fill="#E7FF0F" />
      <path d="M3.29004 23.3672L3.57004 24.1299L4.20004 22.5352L3.92004 21.7031L3.29004 23.3672Z" fill="#FFD300" />
      <path d="M17.29 13.3125L17.43 15.0459L18.62 17.2647L18.55 15.6006L17.29 13.3125Z" fill="#FF3400" />
      <path d="M3.57031 24.1299L4.20031 24.6153L4.76031 23.2285L4.20031 22.5352L3.57031 24.1299Z" fill="#FFDE00" />
      <path d="M14.7002 21.7715L17.4302 21.9102L18.1302 21.9795L15.4702 21.9102L14.7002 21.7715Z" fill="#FFB900" />
      <path d="M5.66992 23.7129L7.06992 23.9903L7.69992 22.9502L6.36992 22.6035L5.66992 23.7129Z" fill="#FFD700" />
      <path d="M7.7002 22.9502L9.5902 23.2276L10.2202 22.4648L8.4002 22.1875L7.7002 22.9502Z" fill="#FFCC00" />
      <path d="M3.57031 19.4151L3.71031 20.6631L4.62031 19.1377L4.55031 17.8203L3.57031 19.4151Z" fill="#FF8600" />
      <path d="M17.9902 28.0811L19.6702 27.7344L19.9502 28.9824L18.2702 29.4678L17.9902 28.0811Z" fill="#BAFF3C" />
      <path d="M10.9902 7.83594L11.2002 9.70805L12.8802 10.4014L12.6702 8.59865L10.9902 7.83594Z" fill="#960000" />
      <path d="M18.3398 24.4063L20.7198 24.2676L21.2098 25.169L18.8298 25.377L18.3398 24.4063Z" fill="#FBF100" />
      <path d="M17.1501 33.9063L17.0101 33.4902L16.8701 35.085L16.9401 35.6397L17.1501 33.9063Z" fill="#56FFA0" />
      <path d="M19.2502 34.0449L18.4102 34.2529L18.2002 36.2637L18.9702 36.1944L19.2502 34.0449Z" fill="#4DFFAA" />
      <path d="M5.32031 13.1748L5.53031 14.9083L6.86031 13.8682L6.72031 12.1348L5.32031 13.1748Z" fill="#FF1300" />
      <path d="M15.4697 21.9102L18.1297 21.9795L18.8297 22.3262L16.2397 22.2568L15.4697 21.9102Z" fill="#FFBD00" />
      <path d="M19.6703 19.7617L19.5303 21.2871L20.3003 23.8526L20.4403 22.3965L19.6703 19.7617Z" fill="#FFAE00" />
      <path d="M15.8896 11.3027L16.0296 13.1055L17.4296 15.047L17.2896 13.3135L15.8896 11.3027Z" fill="#F10800" />
      <path d="M10.9199 21.9795L13.2299 22.1875L13.9299 21.9102L11.6899 21.6328L10.9199 21.9795Z" fill="#FFB900" />
      <path d="M17.71 23.5049L20.16 23.4355L20.72 24.2676L18.34 24.4063L17.71 23.5049Z" fill="#FFDB00" />
      <path d="M4.41016 16.295L4.55016 17.8204L5.60016 16.503L5.53016 14.9082L4.41016 16.295Z" fill="#FF4A00" />
      <path d="M16.2402 22.2578L18.8302 22.3271L19.5302 22.8125H17.0102L16.2402 22.2578Z" fill="#FFC100" />
      <path d="M17.0098 22.8125H19.5298L20.1598 23.4365L17.7098 23.5059L17.0098 22.8125Z" fill="#FFCC00" />
      <path d="M12.6699 8.59766L12.8799 10.4004L14.4899 11.5792L14.3499 9.70706L12.6699 8.59766Z" fill="#A40000" />
      <path d="M17.2197 29.8154L18.2697 29.4688L18.4097 30.8555L17.4297 31.3409L17.2197 29.8154Z" fill="#A0FF56" />
      <path d="M14.3496 9.70703L14.4896 11.5791L16.0296 13.1046L15.8896 11.3018L14.3496 9.70703Z" fill="#C40000" />
      <path d="M17.6404 34.1836L17.1504 33.9062L16.9404 35.6397L17.4304 36.0557L17.6404 34.1836Z" fill="#53FFA4" />
      <path d="M17.0098 31.7559L17.4298 31.3398V32.8653L17.0098 33.4893V31.7559Z" fill="#80FF77" />
      <path d="M18.4097 34.2529L17.6397 34.1836L17.4297 36.0557L18.1997 36.2637L18.4097 34.2529Z" fill="#50FFA7" />
      <path d="M17.6396 26.7637L19.3196 26.4863L19.6696 27.7344L17.9896 28.0811L17.6396 26.7637Z" fill="#D7FF1F" />
      <path d="M4.75977 23.2285L5.66977 23.7139L6.36977 22.6045L5.45977 21.9805L4.75977 23.2285Z" fill="#FFC800" />
      <path d="M7.98047 9.6377L8.19047 11.5098L9.73047 11.3018L9.59047 9.42969L7.98047 9.6377Z" fill="#AD0000" />
      <path d="M3.70996 20.6621L3.91996 21.7022L4.68996 20.3155L4.61996 19.1367L3.70996 20.6621Z" fill="#FF9800" />
      <path d="M8.40039 22.1885L10.2204 22.4658L10.9204 21.9805L9.17039 21.5645L8.40039 22.1885Z" fill="#FFB600" />
      <path d="M20.02 31.1328L19.25 31.7568V34.045L20.02 33.5596V31.1328Z" fill="#80FF77" />
      <path d="M11.6904 21.6338L13.9304 21.9111L14.7004 21.7725L12.5304 21.5645L11.6904 21.6338Z" fill="#FFAE00" />
      <path d="M6.37012 22.6045L7.70012 22.9512L8.40012 22.1885L7.07012 21.7031L6.37012 22.6045Z" fill="#FFB900" />
      <path d="M20.2999 23.8516L19.8799 25.169L20.3699 27.7345L20.7899 26.5557L20.2999 23.8516Z" fill="#F8F500" />
      <path d="M4.2002 22.5342L4.7602 23.2276L5.4602 21.9795L4.9702 21.2168L4.2002 22.5342Z" fill="#FFB600" />
      <path d="M3.91992 21.7032L4.19992 22.5352L4.96992 21.2178L4.68992 20.3164L3.91992 21.7032Z" fill="#FFA700" />
      <path d="M20.3702 27.7344L19.7402 28.7051L20.0202 31.1319L20.6502 30.2999L20.3702 27.7344Z" fill="#BAFF3C" />
      <path d="M17.0801 25.5156L18.9001 25.377L19.3201 26.4864L17.6401 26.7637L17.0801 25.5156Z" fill="#F1FC06" />
      <path d="M16.9404 28.29L17.9904 28.082L18.2704 29.4688L17.2204 29.8155L16.9404 28.29Z" fill="#C1FF36" />
      <path d="M17.1498 32.1025L17.0098 31.7559V33.4893L17.1498 33.9053V32.1025Z" fill="#80FF77" />
      <path d="M18.6198 17.2656L18.5498 18.8604L19.5298 21.2872L19.6698 19.7618L18.6198 17.2656Z" fill="#FF7300" />
      <path d="M12.5303 21.5645L14.7003 21.7725L15.4703 21.9111L13.3703 21.7031L12.5303 21.5645Z" fill="#FFA700" />
      <path d="M4.5498 17.8194L4.6198 19.1368L5.6698 17.8887L5.5998 16.502L4.5498 17.8194Z" fill="#FF5D00" />
      <path d="M16.7998 30.0918L17.2198 29.8145L17.4298 31.3399L17.0098 31.7559L16.7998 30.0918Z" fill="#A4FF53" />
      <path d="M6.71973 12.1338L6.85973 13.8672L8.25973 13.3125L8.18973 11.5098L6.71973 12.1338Z" fill="#DA0000" />
      <path d="M16.4502 24.4062H18.3402L18.8302 25.377L17.0802 25.5156L16.4502 24.4062Z" fill="#FFE200" />
      <path d="M9.16992 21.5635L10.9199 21.9795L11.6899 21.6328L10.0099 21.2168L9.16992 21.5635Z" fill="#FFA300" />
      <path d="M19.2502 31.7559L18.4102 32.1025V34.252L19.1802 34.044L19.2502 31.7559Z" fill="#80FF77" />
      <path d="M9.58984 9.42969L9.72984 11.3018L11.3398 11.5791L11.1998 9.70704L9.58984 9.42969Z" fill="#A40000" />
      <path d="M13.3701 21.7031L15.4701 21.9111L16.2401 22.2578L14.2101 22.0498L13.3701 21.7031Z" fill="#FFAB00" />
      <path d="M5.45996 21.9805L6.36996 22.6045L7.06996 21.7031L6.29996 21.0098L5.45996 21.9805Z" fill="#FFA700" />
      <path d="M17.6404 32.1729L17.1504 32.1035V33.9063L17.6404 34.1836V32.1729Z" fill="#80FF77" />
      <path d="M5.53027 14.9072L5.60027 16.502L6.86027 15.5313V13.8672L5.53027 14.9072Z" fill="#FF2500" />
      <path d="M15.75 23.4355L17.71 23.5049L18.34 24.4063H16.45L15.75 23.4355Z" fill="#FFCC00" />
      <path d="M14.21 22.0488L16.24 22.2568L17.01 22.8115L15.05 22.6035L14.21 22.0488Z" fill="#FFAE00" />
      <path d="M18.4096 32.1035L17.6396 32.1729V34.1836L18.4096 34.253V32.1035Z" fill="#80FF77" />
      <path d="M7.07031 21.7031L8.40031 22.1885L9.17031 21.5645L7.98031 21.0098L7.07031 21.7031Z" fill="#FF9F00" />
      <path d="M15.0498 22.6035L16.9398 22.8115L17.6398 23.5049L15.7498 23.4356L15.0498 22.6035Z" fill="#FFBD00" />
      <path d="M16.5195 26.833L17.5695 26.7637L17.9895 28.0811L16.9395 28.2891L16.5195 26.833Z" fill="#E1FF16" />
      <path d="M17.4304 15.0469L17.3604 16.711L18.5504 18.8604L18.6204 17.2657L17.4304 15.0469Z" fill="#FF3F00" />
      <path d="M4.62012 19.1367L4.69012 20.3155L5.67012 19.1367V17.8887L4.62012 19.1367Z" fill="#FF6F00" />
      <path d="M19.5297 21.2871L19.1797 22.6739L19.8797 25.17L20.2997 23.8526L19.5297 21.2871Z" fill="#FFB600" />
      <path d="M10.0098 21.2178L11.6898 21.6338L12.5298 21.5645L10.9198 21.1484L10.0098 21.2178Z" fill="#FF9800" />
      <path d="M4.96973 21.2178L5.45973 21.9805L6.29973 21.0098L5.87973 20.1777L4.96973 21.2178Z" fill="#FF9400" />
      <path d="M19.7397 28.7051L18.9697 29.3985L19.2497 31.7559L20.0197 31.1319L19.7397 28.7051Z" fill="#B7FF40" />
      <path d="M11.2002 9.70703L11.3402 11.5791L12.9502 12.2032L12.8802 10.4004L11.2002 9.70703Z" fill="#A40000" />
      <path d="M16.9398 30.2305L16.7998 30.0918L17.0098 31.7559L17.1498 32.1026L16.9398 30.2305Z" fill="#A7FF50" />
      <path d="M16.5195 28.4277L16.9395 28.2891L17.2195 29.8145L16.7995 30.0918L16.5195 28.4277Z" fill="#C7FF30" />
      <path d="M4.69043 20.3155L4.97043 21.2168L5.88043 20.1768L5.67043 19.1367L4.69043 20.3155Z" fill="#FF8200" />
      <path d="M19.8803 25.1699L19.3203 26.2793L19.7403 28.7061L20.3703 27.7354L19.8803 25.1699Z" fill="#F1FC06" />
      <path d="M15.96 25.4473L17.08 25.5166L17.64 26.7647L16.52 26.834L15.96 25.4473Z" fill="#FBF100" />
      <path d="M16.0303 13.1055V14.8389L17.3603 16.711L17.4303 15.0469L16.0303 13.1055Z" fill="#FF1300" />
      <path d="M8.19043 11.5107L8.26043 13.3135L9.80043 13.1055L9.73043 11.3027L8.19043 11.5107Z" fill="#C40000" />
      <path d="M12.8799 10.4004L12.9499 12.2032L14.5599 13.3819L14.4899 11.5791L12.8799 10.4004Z" fill="#B60000" />
      <path d="M10.9199 21.1484L12.5299 21.5645L13.3699 21.7031L11.8299 21.2871L10.9199 21.1484Z" fill="#FF9400" />
      <path d="M7.98047 21.0088L9.17047 21.5635L10.0105 21.2168L8.89047 20.6621L7.98047 21.0088Z" fill="#FF8D00" />
      <path d="M6.2998 21.0098L7.0698 21.7032L7.9798 21.0098L7.2098 20.3164L6.2998 21.0098Z" fill="#FF8D00" />
      <path d="M14.4902 11.5801L14.5602 13.3829L16.0302 14.8389V13.1055L14.4902 11.5801Z" fill="#D10000" />
      <path d="M5.59961 16.502L5.66961 17.8887L6.78961 16.9873L6.85961 15.5312L5.59961 16.502Z" fill="#FF3800" />
      <path d="M17.4304 30.1621L16.9404 30.2314L17.1504 32.1036L17.6404 32.1729L17.4304 30.1621Z" fill="#ADFF49" />
      <path d="M18.9699 29.3984L18.1299 29.8838L18.4099 32.1026L19.2499 31.7559L18.9699 29.3984Z" fill="#B4FF43" />
      <path d="M15.2598 24.2676L16.4498 24.4063L17.0798 25.5157L15.9598 25.4463L15.2598 24.2676Z" fill="#FFD300" />
      <path d="M6.86035 13.8672V15.5313L8.19035 14.9766L8.26035 13.3125L6.86035 13.8672Z" fill="#F10800" />
      <path d="M11.8301 21.2871L13.3701 21.7031L14.2101 22.0498L12.7401 21.7031L11.8301 21.2871Z" fill="#FF9400" />
      <path d="M18.1297 29.8848L17.4297 30.1621L17.6397 32.1729L18.4097 32.1036L18.1297 29.8848Z" fill="#B1FF46" />
      <path d="M16.0303 26.834H16.5203L16.9403 28.2901L16.5203 28.4287L16.0303 26.834Z" fill="#EBFF0C" />
      <path d="M18.5502 18.8594L18.2002 20.3848L19.1802 22.6729L19.5302 21.2862L18.5502 18.8594Z" fill="#FF7E00" />
      <path d="M14.4902 23.1582L15.7502 23.4356L16.4502 24.4063L15.2602 24.2676L14.4902 23.1582Z" fill="#FFB900" />
      <path d="M12.7402 21.7031L14.2102 22.0498L15.0502 22.6045L13.6502 22.3272L12.7402 21.7031Z" fill="#FF9C00" />
      <path d="M16.5895 28.3594L16.5195 28.4287L16.7995 30.0928L16.9395 30.2315L16.5895 28.3594Z" fill="#CEFF29" />
      <path d="M13.6504 22.3262L15.0504 22.6035L15.7504 23.4356L14.4904 23.1582L13.6504 22.3262Z" fill="#FFA700" />
      <path d="M5.87988 20.1768L6.29988 21.0088L7.20988 20.3154L6.85988 19.4141L5.87988 20.1768Z" fill="#FF7700" />
      <path d="M8.88965 20.6621L10.0096 21.2168L10.9196 21.1475L9.86965 20.5234L8.88965 20.6621Z" fill="#FF8200" />
      <path d="M5.66992 17.8897V19.1377L6.78992 18.3057V16.9883L5.66992 17.8897Z" fill="#FF4A00" />
      <path d="M9.73047 11.3027L9.80047 13.1055L11.3405 13.3135V11.5801L9.73047 11.3027Z" fill="#B60000" />
      <path d="M19.3198 26.2793L18.5498 27.1113L18.9698 29.3995L19.7398 28.7061L19.3198 26.2793Z" fill="#EBFF0C" />
      <path d="M5.66992 19.1367L5.87992 20.1768L6.85992 19.4141L6.78992 18.3047L5.66992 19.1367Z" fill="#FF6000" />
      <path d="M7.20996 20.3164L7.97996 21.0098L8.88996 20.6631L8.25996 19.9004L7.20996 20.3164Z" fill="#FF7700" />
      <path d="M19.1801 22.6738L18.6201 23.9219L19.3201 26.2794L19.8801 25.17L19.1801 22.6738Z" fill="#FFC100" />
      <path d="M15.4004 25.3086L15.9604 25.4473L16.5204 26.834H16.0304L15.4004 25.3086Z" fill="#FFE200" />
      <path d="M17.0098 28.1504L16.5898 28.3584L16.9398 30.2305L17.4298 30.1612L17.0098 28.1504Z" fill="#D7FF1F" />
      <path d="M17.3601 16.7109L17.0801 18.3057L18.2001 20.3858L18.5501 18.8604L17.3601 16.7109Z" fill="#FF4A00" />
      <path d="M9.87012 20.5234L10.9201 21.1475L11.8301 21.2861L10.9201 20.6621L9.87012 20.5234Z" fill="#FF7A00" />
      <path d="M6.86004 15.5313L6.79004 16.9873L8.12004 16.4326L8.19004 14.9766L6.86004 15.5313Z" fill="#FF1A00" />
      <path d="M8.26043 13.3135L8.19043 14.9776L9.66043 14.7696L9.80043 13.1055L8.26043 13.3135Z" fill="#DA0000" />
      <path d="M16.0303 26.625V26.833L16.5203 28.4278L16.5903 28.3584L16.0303 26.625Z" fill="#F4F802" />
      <path d="M18.5503 27.1113L17.7803 27.7354L18.1303 29.8848L18.9703 29.3995L18.5503 27.1113Z" fill="#E4FF13" />
      <path d="M11.3398 11.5801V13.3135L12.8798 14.0069L12.9498 12.2041L11.3398 11.5801Z" fill="#BB0000" />
      <path d="M14.6299 23.9219L15.2599 24.2686L15.9599 25.4473L15.3999 25.3086L14.6299 23.9219Z" fill="#FFC100" />
      <path d="M17.7798 27.7344L17.0098 28.1504L17.4298 30.1612L18.1998 29.8838L17.7798 27.7344Z" fill="#DEFF19" />
      <path d="M6.86035 19.415L7.21035 20.3164L8.26035 19.9004L7.98035 18.9297L6.86035 19.415Z" fill="#FF6000" />
      <path d="M10.9199 20.7324L11.8299 21.2871L12.7399 21.7031L11.8999 21.1484L10.9199 20.7324Z" fill="#FF7E00" />
      <path d="M8.25977 19.9004L8.88977 20.6631L9.86977 20.5244L9.30977 19.7617L8.25977 19.9004Z" fill="#FF6C00" />
      <path d="M16.0303 14.8379L15.8203 16.502L17.0803 18.3048L17.3603 16.71L16.0303 14.8379Z" fill="#FF1E00" />
      <path d="M13.79 22.8125L14.49 23.1592L15.26 24.2686L14.63 23.9219L13.79 22.8125Z" fill="#FFA700" />
      <path d="M12.9499 12.2031L12.8799 14.0059L14.3499 15.046L14.5599 13.3819L12.9499 12.2031Z" fill="#C80000" />
      <path d="M11.9004 21.1484L12.7404 21.7031L13.6504 22.3272L12.8804 21.8418L11.9004 21.1484Z" fill="#FF8600" />
      <path d="M6.79004 16.9883V18.3057L7.98004 17.8203L8.12004 16.4336L6.79004 16.9883Z" fill="#FF3000" />
      <path d="M18.2 20.3848L17.71 21.7022L18.62 23.921L19.18 22.6729L18.2 20.3848Z" fill="#FF8900" />
      <path d="M12.8799 21.8418L13.6499 22.3272L14.4899 23.1592L13.7899 22.8125L12.8799 21.8418Z" fill="#FF9400" />
      <path d="M14.5596 13.3828L14.3496 15.0469L15.8196 16.503L16.0296 14.8389L14.5596 13.3828Z" fill="#E40000" />
      <path d="M18.6199 23.9219L17.9199 24.8926L18.5499 27.1114L19.3199 26.2793L18.6199 23.9219Z" fill="#FFC800" />
      <path d="M6.79004 18.3057L6.86004 19.4151L7.98004 18.9297V17.8203L6.79004 18.3057Z" fill="#FF4A00" />
      <path d="M15.4004 24.9609V25.3076L16.0304 26.833V26.625L15.4004 24.9609Z" fill="#FFD300" />
      <path d="M16.4503 26.2793L16.0303 26.626L16.5903 28.3594L17.0103 28.1514L16.4503 26.2793Z" fill="#FEED00" />
      <path d="M9.80016 13.1055L9.66016 14.7696L11.1302 14.9776L11.3402 13.3135L9.80016 13.1055Z" fill="#CD0000" />
      <path d="M9.30957 19.7617L9.86957 20.5244L10.9196 20.7324L10.4296 19.9697L9.30957 19.7617Z" fill="#FF6400" />
      <path d="M8.19012 14.9775L8.12012 16.4336L9.45012 16.295L9.66012 14.7695L8.19012 14.9775Z" fill="#F10800" />
      <path d="M17.9204 24.8926L17.1504 25.7246L17.7804 27.7354L18.5504 27.1114L17.9204 24.8926Z" fill="#FFD300" />
      <path d="M7.98047 18.9297L8.26047 19.9004L9.31047 19.7617L9.17047 18.791L7.98047 18.9297Z" fill="#FF5200" />
      <path d="M17.1502 25.7246L16.4502 26.2793L17.0102 28.1514L17.7802 27.7354L17.1502 25.7246Z" fill="#FFE200" />
      <path d="M14.5596 23.5059L14.6296 23.9219L15.3996 25.3086V24.9619L14.5596 23.5059Z" fill="#FFB200" />
      <path d="M17.0802 18.3047L16.6602 19.7608L17.7102 21.7022L18.2002 20.3848L17.0802 18.3047Z" fill="#FF5900" />
      <path d="M10.4297 19.9688L10.9197 20.6621L11.8997 21.1475L11.5497 20.4541L10.4297 19.9688Z" fill="#FF6400" />
      <path d="M8.12047 16.4336L7.98047 17.8203L9.24047 17.6123L9.45047 16.2949L8.12047 16.4336Z" fill="#FF2200" />
      <path d="M15.7504 24.4766L15.4004 24.9619L16.0304 26.626L16.4504 26.2793L15.7504 24.4766Z" fill="#FFC400" />
      <path d="M7.98047 17.8193V18.9287L9.17047 18.7901L9.24047 17.6113L7.98047 17.8193Z" fill="#FF3800" />
      <path d="M17.7101 21.7031L17.0801 22.8819L17.9201 24.8926L18.6201 23.9219L17.7101 21.7031Z" fill="#FF9400" />
      <path d="M11.3399 13.3125L11.1299 14.9766L12.5999 15.6006L12.8799 14.0059L11.3399 13.3125Z" fill="#D10000" />
      <path d="M13.6504 22.2578L13.7904 22.8125L14.6304 23.9219L14.5604 23.5059L13.6504 22.2578Z" fill="#FF9400" />
      <path d="M11.5498 20.4551L11.8998 21.1485L12.8798 21.8418L12.5998 21.2178L11.5498 20.4551Z" fill="#FF6C00" />
      <path d="M12.5996 21.2168L12.8796 21.8408L13.7896 22.8116L13.6496 22.2569L12.5996 21.2168Z" fill="#FF7E00" />
      <path d="M15.8204 16.502L15.4004 18.0274L16.6604 19.7608L17.0804 18.3047L15.8204 16.502Z" fill="#FF3000" />
      <path d="M9.16992 18.791L9.30992 19.7617L10.4299 19.9698L10.3599 18.999L9.16992 18.791Z" fill="#FF4A00" />
      <path d="M9.6602 14.7695L9.4502 16.295L10.8502 16.503L11.1302 14.9775L9.6602 14.7695Z" fill="#E80000" />
      <path d="M12.8796 14.0059L12.5996 15.6006L14.0696 16.6407L14.3496 15.0459L12.8796 14.0059Z" fill="#DF0000" />
      <path d="M16.31 23.7832L15.75 24.4766L16.45 26.2794L17.15 25.7247L16.31 23.7832Z" fill="#FFB200" />
      <path d="M14.3503 15.0469L14.0703 16.6416L15.4003 18.0284L15.8203 16.503L14.3503 15.0469Z" fill="#FA0F00" />
      <path d="M17.0796 22.8125L16.3096 23.7832L17.1496 25.7247L17.9196 24.8926L17.0796 22.8125Z" fill="#FFA300" />
      <path d="M14.8396 22.8809L14.5596 23.5049L15.3996 24.961L15.7496 24.4756L14.8396 22.8809Z" fill="#FF9F00" />
      <path d="M16.6603 19.7617L16.0303 21.0098L17.0103 22.8126L17.7103 21.7032L16.6603 19.7617Z" fill="#FF6800" />
      <path d="M9.23992 17.6113L9.16992 18.7901L10.3599 18.9981L10.5699 17.8193L9.23992 17.6113Z" fill="#FF3000" />
      <path d="M10.3604 18.998L10.4304 19.9688L11.5504 20.4541V19.4834L10.3604 18.998Z" fill="#FF4A00" />
      <path d="M9.45023 16.2949L9.24023 17.6123L10.5702 17.8203L10.8502 16.5029L9.45023 16.2949Z" fill="#FF1A00" />
      <path d="M13.8604 21.4941L13.6504 22.2569L14.5604 23.5049L14.8404 22.8809L13.8604 21.4941Z" fill="#FF7E00" />
      <path d="M11.1296 14.9766L10.8496 16.502L12.2496 17.126L12.5996 15.6006L11.1296 14.9766Z" fill="#ED0400" />
      <path d="M11.5498 19.4844V20.3858L12.5998 21.2178L12.7398 20.3164L11.5498 19.4844Z" fill="#FF5500" />
      <path d="M15.3998 22.0488L14.8398 22.8809L15.7498 24.4756L16.3098 23.7823L15.3998 22.0488Z" fill="#FF8D00" />
      <path d="M12.7396 20.3164L12.5996 21.2178L13.6496 22.2579L13.8596 21.4951L12.7396 20.3164Z" fill="#FF6800" />
      <path d="M16.0304 21.0098L15.4004 22.0498L16.3104 23.7833L17.0804 22.8125L16.0304 21.0098Z" fill="#FF7A00" />
      <path d="M15.3998 18.0273L14.8398 19.3448L16.0298 21.0089L16.6598 19.7608L15.3998 18.0273Z" fill="#FF4300" />
      <path d="M10.5704 17.8203L10.3604 18.999L11.5504 19.4844L11.8304 18.4443L10.5704 17.8203Z" fill="#FF3400" />
      <path d="M12.6 15.6016L12.25 17.127L13.58 18.0977L14.07 16.6416L12.6 15.6016Z" fill="#FA0F00" />
      <path d="M10.8503 16.502L10.5703 17.8194L11.8303 18.4434L12.2503 17.126L10.8503 16.502Z" fill="#FF1A00" />
      <path d="M14.0701 16.6406L13.5801 18.0967L14.8401 19.3448L15.4001 18.0274L14.0701 16.6406Z" fill="#FF2200" />
      <path d="M14.2804 20.5234L13.8604 21.4942L14.8404 22.8809L15.4004 22.0489L14.2804 20.5234Z" fill="#FF6800" />
      <path d="M14.8403 19.3457L14.2803 20.5244L15.4003 22.0499L16.0303 21.0098L14.8403 19.3457Z" fill="#FF5500" />
      <path d="M11.8298 18.4434L11.5498 19.4834L12.7398 20.3155L13.0898 19.3447L11.8298 18.4434Z" fill="#FF3B00" />
      <path d="M13.0902 19.3457L12.7402 20.3164L13.8602 21.4952L14.2802 20.5244L13.0902 19.3457Z" fill="#FF4E00" />
      <path d="M12.2501 17.127L11.8301 18.375L13.0901 19.2764L13.5801 18.0977L12.2501 17.127Z" fill="#FF2500" />
      <path d="M13.5798 18.0977L13.0898 19.3457L14.2798 20.5245L14.8398 19.4151L13.5798 18.0977Z" fill="#FF3800" />
      <path
        d="M75.1096 35.8473C73.7796 35.8473 72.6596 35.6393 71.6096 35.2926C70.6296 34.9459 69.7196 34.3912 68.9496 33.6978C68.1796 32.9351 67.6196 32.1031 67.2696 31.1323C66.8496 30.0229 66.6396 28.9135 66.7096 27.7348V16.0168C66.7096 14.838 66.9196 13.7286 67.2696 12.6192C67.6196 11.6485 68.1796 10.7471 68.9496 10.0538C69.7196 9.36038 70.6296 8.80568 71.6096 8.459C72.6596 8.11231 73.7796 7.9043 75.1096 7.9043C77.4896 7.9043 79.4496 8.52833 80.9196 9.84575C82.3896 11.1632 83.2296 12.9659 83.5096 15.2541H80.0796C79.9396 13.8673 79.3796 12.8273 78.5396 12.0645C77.6296 11.3018 76.5096 10.8858 75.1096 10.8858C73.5696 10.8858 72.3796 11.3712 71.4696 12.2726C70.5596 13.1739 70.1396 14.422 70.1396 16.0168V27.7348C70.1396 29.3296 70.5596 30.5776 71.4696 31.479C72.3796 32.3804 73.5696 32.8658 75.1096 32.8658C76.5096 32.8658 77.6296 32.4498 78.5396 31.687C79.4496 30.9243 79.9396 29.8843 80.0796 28.4975H83.5096C83.2296 30.7857 82.3896 32.6578 80.9196 33.9058C79.4496 35.2233 77.5596 35.8473 75.1096 35.8473Z"
        fill="white"
      />
      <path
        d="M93.2396 14.6309V28.0823C93.2396 31.2718 94.7796 32.7973 97.7896 32.7973C100.87 32.7973 102.41 31.2025 102.41 28.0823V14.6309H105.84V28.0823C105.84 30.5785 105.14 32.4506 103.74 33.768C102.34 35.0854 100.38 35.7788 97.7896 35.7788C95.2696 35.7788 93.2396 35.0854 91.9096 33.768C90.5096 32.4506 89.8096 30.5091 89.8096 28.0823V14.6309H93.2396Z"
        fill="white"
      />
      <path
        d="M116.83 14.6299V18.2354H117.11C117.39 17.0567 118.16 16.0166 119.21 15.3233C120.26 14.6299 121.52 14.2832 123.06 14.2832C125.3 14.2832 127.05 14.9766 128.31 16.3633C129.57 17.7501 130.2 19.6222 130.2 22.049V23.2277H126.77V22.465C126.77 20.8009 126.35 19.5528 125.51 18.6515C124.67 17.7501 123.48 17.3341 122.01 17.3341C120.54 17.3341 119.28 17.8194 118.44 18.7208C117.53 19.6222 117.11 20.8703 117.11 22.465V35.5005H113.68V14.6992H116.9L116.83 14.6299Z"
        fill="white"
      />
      <path
        d="M134.119 14.6309H137.759L142.869 29.3304C143.079 29.8158 143.219 30.3705 143.359 30.8558C143.429 31.2718 143.569 31.6879 143.569 31.9652C143.639 32.3119 143.639 32.5893 143.639 32.9359H143.989C143.989 32.5893 143.989 32.3119 144.059 31.9652C144.129 31.6879 144.199 31.2718 144.269 30.8558C144.339 30.3011 144.479 29.8158 144.689 29.3304L149.519 14.6309H153.299L145.739 35.4321H141.819L134.119 14.6309Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.439 16.3643C158.969 17.7511 158.199 19.6925 158.199 22.1886V27.8743C158.199 30.3705 158.969 32.3119 160.439 33.6987C161.909 35.0854 164.009 35.8481 166.599 35.8481C168.699 35.8481 170.519 35.3628 171.989 34.4614C173.459 33.56 174.439 32.3119 174.859 30.7172H171.429C171.009 31.4105 170.379 31.8959 169.539 32.2426C168.699 32.5893 167.719 32.7973 166.529 32.7973C164.989 32.7973 163.729 32.3119 162.889 31.4105C161.979 30.5091 161.559 29.2611 161.559 27.6663V26.0022H174.929V22.1886C174.929 19.6925 174.159 17.7511 172.689 16.3643C171.219 14.9776 169.119 14.2148 166.529 14.2148C163.939 14.2148 161.839 14.9082 160.369 16.3643H160.439ZM171.569 23.1594H161.629V22.1886C161.629 20.5939 162.049 19.4152 162.959 18.5831C163.799 17.7511 165.059 17.2657 166.599 17.2657C168.209 17.2657 169.399 17.6817 170.239 18.5831C171.079 19.4152 171.569 20.6632 171.569 22.1886V23.1594Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_73250">
        <rect width="175" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
