import { colors } from "@/components/Providers/ThemeRegistry/theme"
import { useMediaQuery, useTheme } from "@mui/material"
import Box from "@/components/Box"
import Stack from "@mui/material/Stack/Stack"
import Typography from "@mui/material/Typography/Typography"

const EcosystemBox: React.FC<{
  title: string
  description: string
  logo: React.ReactNode
  imageSrc: string
  hasBorder?: boolean
}> = ({ title, description, logo, imageSrc, hasBorder = false }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const borderLeft = !isMobile && hasBorder ? `1px solid ${colors.base.greyDark}` : "none"
  const borderTop = isMobile && hasBorder ? `1px solid ${colors.base.greyDark}` : "none"
  return (
    <Box
      flex={1}
      paddingTop="40px"
      paddingLeft="40px"
      borderLeft={borderLeft}
      borderTop={borderTop}
      position="relative"
      overflow="hidden"
    >
      <Stack gap="8px" maxWidth="520px" sx={{ marginBottom: { xs: "80px", lg: "78px" } }}>
        <Typography variant="body1" color={colors.base.greyMid}>
          {title}
        </Typography>
        <Typography variant="h5" color={colors.base.white} height="100px">
          {description}
        </Typography>
      </Stack>
      <Box position="absolute" top={0} right={0} margin="20px">
        {logo}
      </Box>
      <Box
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          aspectRatio: "680/350",
        }}
      />
    </Box>
  )
}

export default EcosystemBox
