import Box from "@/components/Box"
import Container from "@mui/material/Container/Container"
import { SxProps, Theme } from "@mui/material/styles"

import React from "react"

const MaxWidthContainer: React.FC<{
  children: React.ReactNode

  sx?: SxProps<Theme>
  backgroundSx?: SxProps<Theme>
  bgColor?: string
}> = ({ children, bgColor, backgroundSx = {}, sx = {} }) => (
  <Box bgcolor={bgColor} sx={backgroundSx}>
    <Container
      sx={sx}
      maxWidth="xl"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {children}
    </Container>
  </Box>
)

export default MaxWidthContainer
