"use client";

import AboveTheFold from "@/components/LandingPage/AboveTheFold";
import Community from "@/components/LandingPage/CommunitySection/Community";
import Ecosystem from "@/components/LandingPage/EcosystemSection/Ecosystem";
import Governance from "@/components/LandingPage/Governance";
import Insights from "@/components/LandingPage/InsightsSection/Insights";

import React from "react";

export default function Home() {
  return (
    <>
      <AboveTheFold />
      <Ecosystem />
      <Governance />
      <Insights />
      <Community />
    </>
  );
}
