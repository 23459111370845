import Box from "@/components/Box"
import Grid from "@mui/material/Grid/Grid"
import useTheme from "@mui/material/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery"
import Image from "next/image"
import { WallStreetJournalLogo, CoinGecko, Bloomberg, Bullish } from "../svgs/Svgs"
import { Stack } from "@mui/material"

const AsFeaturedInGrid = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Grid container spacing={1} padding={0} sx={{ maxWidth: 936 }}>
      <GridItem columns={isMobile ? 4 : 3}>
        <WallStreetJournalLogo width={200} height={50} />
      </GridItem>
      <GridItem columns={isMobile ? 4 : 3}>
        <CoinGecko />
      </GridItem>
      <GridItem columns={isMobile ? 4 : 3}>
        <Bloomberg height={56} width={180} />
      </GridItem>
      <GridItem columns={isMobile ? 4 : 3}>
        <Bullish />
      </GridItem>
      <GridItem columns={isMobile ? 4 : 3}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="100%">
          <Box minWidth={0}>
            <Image
              alt="federal reserve logo"
              src="/images/federal-reserve-logo.svg"
              height={56}
              width={56}
              style={{ width: "100%", height: "auto", maxHeight: isMobile ? "40px" : "50px", objectFit: "contain" }}
            />
          </Box>
        </Stack>
      </GridItem>
      <GridItem columns={4}>
        <Image
          alt="bank of america logo"
          src="/images/bank-of-america-logo.svg"
          height={56}
          width={285}
          style={{ width: "100%", height: "auto", maxHeight: "56px" }}
        />
      </GridItem>
      {isMobile && (
        <GridItem columns={3}>
          <div></div>
        </GridItem>
      )}
      <GridItem columns={isMobile ? 6 : 4}>
        <Image
          alt="flow traders logo"
          src="/images/flowtraders.svg"
          height={56}
          width={287}
          style={{
            width: "287px",
            height: "100%",
            maxWidth: "100%",
          }}
        />
      </GridItem>
      <GridItem columns={isMobile ? 3 : 1}>
        <div></div>
      </GridItem>
    </Grid>
  )
}

const GridItem: React.FC<{ columns?: number; children: React.ReactNode }> = ({ columns = 3, children }) => (
  <Grid
    item
    xs={columns}
    width="100%"
    sx={{
      height: { xs: "50px", md: "60px" },
      width: "auto",
      marginTop: { xs: "5px", md: "10px" },
    }}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxHeight: "100%",
      }}
    >
      {children}
    </Box>
  </Grid>
)

export default AsFeaturedInGrid
