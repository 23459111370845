import QuestionAccordion from "@/components/QuestionAccordian"
import Box from "@/components/Box"
import Divider from "@mui/material/Divider"

import React from "react"

const FAQ = () => (
  <Box>
    <QuestionAccordion
      question="What is the Ondo Foundation?"
      answer={
        <>
          The Ondo Foundation (formerly known as the Neptune Foundation) was founded in 2022 as an independently
          governed and nonprofit Cayman Islands Foundation Company with no beneficial owners. The Ondo Foundation
          assisted in the launch of the Ondo DAO, which deployed and currently governs Flux Finance, the first lending
          protocol supporting tokenized securities as collateral.
        </>
      }
    />
    <QuestionAccordion
      question="What is the Foundation's mission?"
      answer={
        <>
          The Ondo Foundation has a mission of democratizing access to institutional-grade finance. We are focused on
          supporting decentralized protocols like Flux, participating in the Ondo DAO community and, more recently,
          supporting tokenized real world assets like USDY. <br />
          <br /> Notably, the Ondo Foundation, through its subsidiaries, owns 99% of the common equity in Ondo USDY LLC,
          the issuer of USDY. The Ondo Foundation therefore benefits from the growth of USDY and uses proceeds from this
          growth in furtherance of its mission.
        </>
      }
    />
    <QuestionAccordion
      question="On which key areas does the Foundation focus?"
      answer={
        <>
          Ondo Foundation’s mandate is focused on several key areas:
          <ul>
            <li>
              <strong>Supporting Innovation:</strong> Financing and endorsing projects that responsibly advance the
              adoption of tokenized real world assets and their use in onchain financial protocols.
            </li>
            <li>
              <strong>Educating and Promoting Awareness:</strong> Increasing public understanding of our ecosystem and
              the benefits of asset tokenization.
            </li>
            <li>
              <strong>Treasury Management:</strong> Overseeing a treasury to facilitate strategic ecosystem growth.
            </li>
            <li>
              <strong>Contract Deployment:</strong> Issuing governance tokens and deploying protocol smart contracts.
            </li>
            <li>
              <strong>Web Application Management:</strong> Hosting web applications for certain platforms in the
              ecosystem.
            </li>
            <li>
              <strong>Strategic Partnerships and Engagement:</strong> Collaborating with businesses, regulators, and
              other stakeholders.
            </li>
            <li>
              <strong>Intellectual Property Management:</strong> Acquiring and handling IP rights.
            </li>
            <li>
              <strong>Community Events and Engagement:</strong> Organizing events to strengthen community ties.
            </li>
          </ul>
          <p>
            The Ondo Foundation also works with Ondo Finance as a commercial partner that is focused on the provision of
            certain technology and business development services and the management of certain tokenized real world
            assets like USDY.
          </p>
        </>
      }
    />
    {/* <QuestionAccordion
      question="How do I engage with Ondo?"
      answer="TODO"
    /> */}
    <QuestionAccordion
      question="What is the ONDO Token?"
      answer={
        <>
          In mid-2022, Ondo Foundation, through its subsidiaries, sold ONDO tokens to over 18,000 individuals through
          CoinList. ONDO token holders are entrusted with shaping the future of the Ondo DAO so that it continues to
          pursue its mission to democratize access to institutional-grade finance. The Ondo DAO gives ONDO holders
          specific rights pertaining to Flux Finance, which is currently governed by the Ondo DAO.
        </>
      }
    />
    <QuestionAccordion
      question="What is the Ondo Ecosystem?"
      answer={
        <>
          The Ondo Ecosystem encompasses the full set of partners working with or affiliated with the Ondo Foundation
          that are aligned with the mission of bringing institutional-grade financial products and services to everyone.
          Contributors to the Ondo Ecosystem include developers, educators, researchers, partners, and strategic
          contributors.
        </>
      }
    />

    <Divider color="primary" sx={{ mb: 0 }} />
  </Box>
)

export default FAQ
