import { colors } from "../../Providers/ThemeRegistry/theme"
import Box from "@/components/Box"

import Stack from "@mui/material/Stack/Stack"

import { FluxLogo, OndoLogo } from "../../svgs/Svgs"

import SectionTitle from "../SectionTitle"
import MaxWidthContainer from "../MaxWidthContainer"
import PartnersGrid from "./PartnersGrid"
import EcosystemBox from "./EcosystemBox"
import Link from "next/link"
import { styled, useMediaQuery, useTheme } from "@mui/material"

const Ecosystem = () => {
  const theme = useTheme()
  return (
    <MaxWidthContainer
      bgColor={colors.base.black}
      sx={{ padding: { xs: "96px 16px 120px 16px", lg: "160px 16px 200px 16px" } }}
    >
      <SectionTitle title="Ecosystem">
        Explore the robust ecosystem of the future of finance.{" "}
        <strong style={{ color: colors.base.greyMid }}>Unleash financial inclusivity and efficiency onchain.</strong>
      </SectionTitle>

      <Stack
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          margin: { xs: "48px 0 32px 0", lg: "64px 0 80px 0" },
          border: `1px solid ${colors.base.greyDark}`,
        }}
        width="100%"
      >
        <StyledLink href="https://ondo.finance" target="_blank">
          <EcosystemBox
            title="Ondo Finance"
            description="The market leader in the tokenization of real world assets (RWA)"
            logo={<OndoLogo />}
            imageSrc="/images/ondo_finance.png"
          />
        </StyledLink>
        <StyledLink href="https://fluxfinance.com/" target="_blank">
          <EcosystemBox
            hasBorder
            title="Flux Finance"
            description="The leading DeFi protocol supporting tokenized RWAs, governed by Ondo DAO"
            logo={<FluxLogo />}
            imageSrc="/images/flux_finance.png"
          />
        </StyledLink>
      </Stack>
      <Box
        paddingTop="80px"
        maxWidth="1080px"
        width="100%"
        sx={{ padding: { xs: "52px 0 48px 0", lg: "80px 0 64px 0" } }}
      >
        <SectionTitle variant="h3">
          <strong>The Ondo Ecosystem is supported by a diverse range of </strong>
          institutional and crypto-native partners and service providers
        </SectionTitle>
      </Box>
      <PartnersGrid />
    </MaxWidthContainer>
  )
}

const StyledLink = styled(Link)`
  transition: background 0.3s ease-in-out;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: ${colors.base.almostBlack};
  }
`
export default Ecosystem
