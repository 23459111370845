import { Button, useMediaQuery, useTheme } from "@mui/material"
import { colors } from "../Providers/ThemeRegistry/theme"
import SectionTitle from "./SectionTitle"
import Image from "next/image"
import Link from "next/link"
import MaxWidthContainer from "./MaxWidthContainer"

const Governance = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <MaxWidthContainer bgColor={colors.base.blue} sx={{ padding: { xs: "96px 16px 0", lg: "120px 40px 0" } }}>
      <SectionTitle title="Governance">
        <>
          Empower community-led governance.{" "}
          <strong style={{ color: "#B399FF" }}>
            Vote on the future of the Ondo Ecosystem. Earn rewards for participation.
          </strong>
        </>
      </SectionTitle>
      <Button
        LinkComponent={Link}
        href="https://www.tally.xyz/gov/ondo-dao"
        size="medium"
        variant="contained"
        style={{
          background: colors.base.white,
          color: colors.base.blue,
          marginTop: "36px",
        }}
      >
        Vote on Tally
      </Button>
      {isMobile && (
        <Image
          src="/images/governance-mobile.svg"
          height={618}
          width={331}
          alt="governance"
          style={{ marginTop: "64px", maxWidth: "100%", height: "auto" }}
        />
      )}
      {!isMobile && (
        <Image
          src="/images/governance.svg"
          height={656}
          width={1440}
          alt="governance"
          style={{ marginTop: "64px", width: "100%", height: "auto" }}
        />
      )}
    </MaxWidthContainer>
  )
}
export default Governance
