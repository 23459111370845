import Box from "@/components/Box"
import MaxWidthContainer from "../MaxWidthContainer"
import SectionTitle from "../SectionTitle"

import SocialsGrid from "./SocialsGrid"
import { colors } from "@/components/Providers/ThemeRegistry/theme"
import TwitterFeed from "./TwitterFeed"

const Community = () => {
  return (
    <>
      <MaxWidthContainer
        bgColor={colors.base.offWhite}
        sx={{
          padding: { xs: "96px 16px 0px 16px", md: "120px 16px 0px 16px" },
        }}
      >
        <SectionTitle title="Community" mode="light">
          <strong>Get involved with the Community.</strong> Become a part of our journey to reshape the financial
          landscape.
        </SectionTitle>
        <Box sx={{ marginTop: { xs: "64px", md: "80px" } }} width="100%">
          <SocialsGrid />
        </Box>
      </MaxWidthContainer>
      <MaxWidthContainer
        bgColor={colors.base.offWhite}
        sx={{
          padding: { xs: "0px 0px 120px 0px", md: "0px 16px 160px 16px" },
        }}
      >
        <TwitterFeed />
      </MaxWidthContainer>
    </>
  )
}

export default Community
