import Button from "@mui/material/Button/Button"
import MaxWidthContainer from "../MaxWidthContainer"
import SectionTitle from "../SectionTitle"
// import InsightsGrid from "./InsightsGrid"
import FAQ from "./FAQ"
import Box from "@/components/Box"

const Insights = () => {
  return (
    <MaxWidthContainer
      sx={{
        padding: {
          xs: "96px 16px 120px 16px",
          lg: "160px 40px 200px 40px",
        },
      }}
    >
      <SectionTitle title="Insights" mode="light">
        <strong>Stay informed on Ondo updates.</strong> Get insightful analysis and a complete overview of our ongoing
        journey.
      </SectionTitle>
      <Button sx={{ margin: "24px 0 8px 0" }} variant="contained" size="medium" href="https://blog.ondo.foundation">
        Read The blog
      </Button>
      {/* <InsightsGrid /> commmenting out until we have blog posts to show*/}
      <Box
        sx={{
          marginTop: {
            xs: "96px",
            // md: "160px" commmenting out until we have blog posts to show
          },
          width: "100%",
        }}
      >
        <FAQ />
      </Box>
    </MaxWidthContainer>
  )
}

export default Insights
