import Box from "@/components/Box"
import Typography from "@mui/material/Typography/Typography"
import { colors } from "../Providers/ThemeRegistry/theme"
import { styled } from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography"

const SectionTitle: React.FC<{
  title?: string
  variant?: Variant
  mode?: "light" | "dark"
  children: React.ReactNode
}> = ({ title, variant = "h3", mode = "dark", children }) => {
  return (
    <>
      {title && (
        <Typography
          color={mode === "dark" ? colors.base.white : colors.base.greyDark}
          variant="body1"
          paddingBottom="16px"
        >
          {title}
        </Typography>
      )}
      <Box maxWidth="1080px" width="100%">
        <Subtitle mode={mode} variant={variant} textAlign="center">
          {children}
        </Subtitle>
      </Box>
    </>
  )
}

const Subtitle = styled(Typography)<{ mode: "light" | "dark" }>`
  font-weight: 600;
  strong {
    font-weight: 600;
  }
  color: ${(props) => (props.mode === "light" ? colors.base.greyMid2 : colors.base.white)};
  strong {
    color: ${(props) => (props.mode === "light" ? colors.base.black : colors.base.greyMid)};
  }
`

export default SectionTitle
