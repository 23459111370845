import { colors } from "@/components/Providers/ThemeRegistry/theme"
import Box from "@/components/Box"
import Grid from "@mui/material/Grid/Grid"
import {
  Anchorage,
  AnkuraLogo,
  BlackRockLogo,
  Coinbase,
  Injective,
  MantleWordmark,
  MorganStanleyLogo,
  Polygon,
  YearnFinance,
} from "../../svgs/Svgs"
import { Curve } from "@/components/svgs/Curve"
import { Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import Link from "next/link"

const PartnersGrid = () => {
  return (
    <Grid container sx={{ borderTop: `1px solid ${colors.base.greyDark}` }}>
      <PartnerGridItem href="https://www.blackrock.com">
        <BlackRockLogo height="100%" width="100%" />
      </PartnerGridItem>

      <PartnerGridItem href="https://ankuratrust.com">
        <AnkuraLogo height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://www.morganstanley.com">
        <MorganStanleyLogo height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://mantle.xyz">
        <MantleWordmark height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://polygon.technology">
        <Polygon height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://coinbase.com">
        <Coinbase height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://curve.fi">
        <Curve height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://injective.com">
        <Injective height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://yearn.fi">
        <YearnFinance height="100%" width="100%" />
      </PartnerGridItem>
      <PartnerGridItem href="https://www.anchorage.com">
        <Anchorage height="100%" width="100%" />
      </PartnerGridItem>
    </Grid>
  )
}

const PartnerGridItem: React.FC<{ href: string; children: React.ReactNode }> = ({ href, children }) => {
  const theme = useTheme()

  const oneCol = useMediaQuery(theme.breakpoints.down("sm"))
  const twoCol = useMediaQuery(theme.breakpoints.down("md"))
  const fourCol = useMediaQuery(theme.breakpoints.down("xl"))
  const nChild = oneCol ? "1n" : twoCol ? "2n" : fourCol ? "4n" : "5n"
  return (
    <StyledGridItem
      $nChild={nChild}
      className={"grid-item"}
      item
      xs={12}
      sm={6}
      md={3}
      xl={2.4}
      sx={{
        height: { xs: "156px", md: "264px" },
        minWidth: { xs: "171px" },
      }}
      width="100%"
      paddingBottom="75%%"
    >
      <Link
        href={href}
        target="_blank"
        style={{
          textDecoration: "none",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            padding: "0 24px",
            position: "relative",
          }}
        >
          <div
            className="box"
            style={{
              maxWidth: "170px",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {children}
          </div>
          <Box className="link" width="100%" position="absolute" bottom="25%">
            <Typography variant="body1" color={colors.base.greyMid} textAlign="center">
              {href.replace("https://", "").replace("www.", "")}
            </Typography>
          </Box>
        </Box>
      </Link>
    </StyledGridItem>
  )
}

const StyledGridItem = styled(Grid, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $nChild: string }>`
  border-left: 1px solid ${colors.base.greyDark};
  border-bottom: 1px solid ${colors.base.greyDark};

  &:last-child,
  &:nth-child(${(props) => props.$nChild}) {
    border-right: 1px solid ${colors.base.greyDark};
  }

  cursor: pointer;

  .link {
    opacity: 0;
    transform: translateY(0px);
  }

  .box,
  .link {
    transform: translateY(0px);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background: rgba(22, 23, 26, 0.5);

    .link {
      opacity: 1;

      transform: translateY(5px);
    }
    .box {
      transform: translateY(-10px);
    }
  }
`
export default PartnersGrid
