"use client"

import { colors } from "@/components/Providers/ThemeRegistry/theme"
import { Divider, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material"
import { ReactNode } from "react"
import AddIcon from "@mui/icons-material/Add"

const QuestionAccordion: React.FC<{ question: string; answer: ReactNode }> = ({ question, answer }) => (
  <>
    <Divider color="primary" sx={{ mb: 0 }} />
    <Accordion disableGutters style={{ borderRadius: 0, boxShadow: "unset", background: "transparent" }}>
      <AccordionSummary
        expandIcon={<AddIcon sx={{ color: colors.base.black, height: 24, width: 24 }} />}
        sx={{
          px: 0,
          py: 0.4,
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(45deg)",
          },
        }}
      >
        <Typography fontWeight={500} variant="h6">
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, py: 1.6 }}>
        <Typography variant="body1" color={colors.base.greyMid2}>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  </>
)

export default QuestionAccordion
